import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  detailInfoLabel: {
    color: '#60017F',
  },
  detailInfoValue: {
    color: '#2A2A2A',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.fn.smallerThan('sm')]: {
      justifyContent: 'flex-start',
    },
  },
}));

export default useStyles;
