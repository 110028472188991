import { Grid, Title, Flex, Text, Image } from '@mantine/core';
import { formatCurrency } from '../../../../utils/currency';
import { DownloadBtn } from '../DashboardListModal';
import AtomFlagWallet from '../../../atoms/flag-wallet/FlagWallet';
import AtomGridDetails from '../../../atoms/grid-details/GridDetails';
import pesoNetLogo from '../../../../assets/images/pesonet-logo.svg';
import phFlag from '../../../../assets/images/ph-flag.svg';
import useStyles from '../../../pages/dashboard/DashboardPage.styles';

const BankTransferContent = ({ transaction }) => {
  const { classes } = useStyles();

  return (
    <Flex direction='column' align='center' w='100%'>
      <Grid justify='space-between' w='100%'>
        <Grid.Col md={6}>
          <Title order={3} className={classes.modalContentTitle}>
            Bank Account Details
          </Title>
          <Flex
            direction='column'
            justify='space-between'
            className='purple-wrapper'
          >
            <AtomGridDetails
              details={[
                {
                  label: 'Bank Account',
                  value: transaction.bank,
                },
                {
                  label: 'Account Number',
                  value: transaction.accountNo,
                },
                {
                  label: 'Account Name',
                  value: transaction.accountName,
                },
              ]}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col md={6}>
          <Title order={3} className={classes.modalContentTitle}>
            Transaction Wallet
          </Title>
          <Flex justify='space-between' className='purple-wrapper'>
            <AtomFlagWallet
              label='PHP Wallet'
              value={formatCurrency('PHP', transaction.wallet)}
              flagImg={phFlag}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col span={12}>
          <Title order={3} className={classes.modalContentTitle}>
            Transaction Amount
          </Title>
          <Flex
            align='center'
            justify='space-between'
            className='purple-wrapper'
            sx={{ padding: '0 !important' }}
          >
            <Flex direction='column' p={16}>
              <Text>Total</Text>
            </Flex>
            <Title
              order={4}
              className='page-h4-title'
              sx={{
                backgroundColor: '#D3CAE2',
                borderRadius: '8px',
                lineHeight: '2.6rem',
                padding: '1rem',
              }}
            >
              {formatCurrency('PHP', transaction.transferAmount)}
            </Title>
          </Flex>
        </Grid.Col>
        <Grid.Col
          sm={6}
          mt={16}
          display='flex'
          sx={{ justifyContent: 'flex-start' }}
        >
          <Flex align='center'>
            <Text mr={10}>Sent via</Text>
            <Image src={pesoNetLogo} width={104} height={27} />
          </Flex>
        </Grid.Col>
        <Grid.Col
          sm={6}
          mt={16}
          display='flex'
          sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <DownloadBtn />
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

export default BankTransferContent;
