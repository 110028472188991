import { useState } from 'react';
import { Flex } from '@mantine/core';
import { initialSendMoneyIntlFormData } from '../../../constants/send-money';
import Banner from '../../atoms/banner/Banner';
import SendMoneyIntlStepper from '../../organisms/send-money-intl/SendMoneyIntlStepper';
import SendMoneyDetails from '../../organisms/send-money-intl/SendMoneyDetails';
import SendMoneyTransAmount from '../../organisms/send-money-intl/SendMoneyTransAmount';
import SendMoneyConfirm from '../../organisms/send-money-intl/SendMoneyConfirm';

const SendMoneyIntlPage = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [formData, setFormData] = useState(initialSendMoneyIntlFormData);
  const [confirmedSendMoneyIntl, setConfirmedSendMoneyIntl] = useState(false);

  const handleNextStep = (currFormData) => {
    setFormData({ ...formData, ...currFormData });
    setActiveStep(activeStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleConfirmSendMoney = () => {
    setConfirmedSendMoneyIntl(true);
  };

  const handleCloseConfirmedModal = () => {
    setFormData(initialSendMoneyIntlFormData);
    setActiveStep(1);
    setConfirmedSendMoneyIntl(false);
  };

  const sendIntlContentMapper = [
    {
      step: 1,
      component: (
        <SendMoneyDetails formData={formData} onNextStep={handleNextStep} />
      ),
    },
    {
      step: 2,
      component: (
        <SendMoneyTransAmount
          formData={formData}
          onNextStep={handleNextStep}
          onPrevStep={handlePrevStep}
        />
      ),
    },
    {
      step: 3,
      component: (
        <SendMoneyConfirm
          formData={formData}
          onPrevStep={handlePrevStep}
          onConfirm={handleConfirmSendMoney}
        />
      ),
    },
  ];
  const findCurrIntlContent = sendIntlContentMapper.find(
    ({ step }) => step === activeStep
  );
  const activeIntlComponent = findCurrIntlContent
    ? findCurrIntlContent.component
    : null;

  return (
    <Flex direction='column' gap='md'>
      <SendMoneyIntlStepper activeStep={activeStep} />
      {activeIntlComponent}
      <Banner
        visible={confirmedSendMoneyIntl}
        onClose={handleCloseConfirmedModal}
        title='Transaction Complete!'
        message='Your International Send Money transaction XXXXXX
        has been successfully completed.'
      />
    </Flex>
  );
};

export default SendMoneyIntlPage;
