import { Link, useLocation } from 'react-router-dom';
import { NavLink } from '@mantine/core';
import useStyles from './PrivateLayout.styles';

const RenderMainLink = ({
  icon,
  label,
  to,
  children,
  onHideNavbar,
  hasChildLinks,
  isActive,
  activeChildRoute,
}) => {
  const { classes } = useStyles();
  const noOnClickEvent = () => {};
  const linkProps = !hasChildLinks ? { component: Link, to } : {};

  return (
    <NavLink
      className={isActive ? classes.navbarLinkActive : classes.navbarLink}
      label={label}
      icon={icon}
      onClick={hasChildLinks ? noOnClickEvent : onHideNavbar}
      {...linkProps}
      defaultOpened={!!activeChildRoute}
    >
      {hasChildLinks &&
        children.map((childNavLink, childNavLinkIndex) => {
          const isActiveChildRoute = activeChildRoute
            ? activeChildRoute.to === childNavLink.to
            : false;
          return (
            <NavLink
              key={`child-navlink-${label}-${childNavLinkIndex}`}
              className={
                isActiveChildRoute
                  ? classes.navbarLinkActive
                  : classes.navbarLink
              }
              label={childNavLink.label}
              icon={childNavLink.icon}
              component={Link}
              to={childNavLink.to}
              onClick={onHideNavbar}
            />
          );
        })}
    </NavLink>
  );
};

const NavbarLinks = ({ dataLinks, onHideNavbar }) => {
  const links = dataLinks.map((link) => {
    const { pathname } = useLocation();
    const hasChildLinks = !!(link.children && link.children.length);
    const isActiveRoute = !hasChildLinks ? pathname === link.to : false;
    const activeChildRoute = hasChildLinks
      ? link.children.find((childLink) => pathname === childLink.to)
      : false;

    return (
      <RenderMainLink
        {...link}
        key={link.label}
        onHideNavbar={onHideNavbar}
        hasChildLinks={hasChildLinks}
        isActive={isActiveRoute}
        activeChildRoute={activeChildRoute}
      />
    );
  });
  return <div>{links}</div>;
};

export default NavbarLinks;
