import { useEffect } from 'react';
import { IconCheck, IconExclamationMark, IconBug } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

const Toast = ({ message, mode, onClose }) => {
  const toastModeProps = () => {
    let toastProps = {
      autoClose: 5000,
      onClose,
      withCloseButton: true,
    };

    switch (mode) {
      case 'success':
        toastProps = {
          ...toastProps,
          color: 'teal',
          icon: <IconCheck />,
          message,
          title: 'Success',
        };
        break;
      case 'warning':
        toastProps = {
          ...toastProps,
          color: 'orange',
          icon: <IconExclamationMark />,
          message,
          title: 'Warning',
        };
        break;
      case 'error':
        toastProps = {
          ...toastProps,
          color: 'red',
          icon: <IconBug />,
          message,
          title: 'Error',
        };
        break;
      default:
        break;
    }

    return toastProps;
  };

  useEffect(() => {
    if (message) {
      notifications.show({ ...toastModeProps() });
    }
  }, [message]);
};

export default Toast;
