import { createStyles, rem } from '@mantine/core';

const useStyles = createStyles(() => ({
  stepper: {
    '.mantine-Stepper-stepBody': {
      display: 'none',
    },
    '.mantine-Stepper-step': {
      padding: 0,
    },
    '.mantine-Stepper-separator': {
      marginLeft: rem(-2),
      marginRight: rem(-2),
      height: rem(2),
    },
  },
}));

export default useStyles;
