import { useState, useEffect } from 'react';
import { Flex } from '@mantine/core';
import { mockPaymentData } from '../../../mocks/payment';
import PaymentCards from '../../organisms/payment/PaymentCards';
import PaymentsTableFilter from '../../organisms/payment/PaymentsTableFilter';
import PaymentsTable from '../../organisms/payment/PaymentsTable';
import CreatePayment from '../../organisms/payment/CreatePayment';

const PaymentPage = () => {
  const [paymentData, setPaymentData] = useState(mockPaymentData);
  const [createPaymentVisible, setCreatePaymentVisibility] = useState(false);
  const [paymentFilters, setPaymentFilters] = useState({
    search: '',
    status: 'All',
  });

  const handleSetFilters = (filters) => {
    setPaymentFilters(filters);
  };

  const handleFilterPaymentsData = () => {
    const { search, status } = paymentFilters;
    let filteredPaymentData = mockPaymentData;

    if (search) {
      filteredPaymentData = mockPaymentData.filter(
        (payment) =>
          payment.reference_no === search ||
          payment.first_name === search ||
          payment.middle_name === search ||
          payment.last_name === search
      );
    }
    if (status && status !== 'All') {
      filteredPaymentData = filteredPaymentData.filter(
        (payment) => payment.status === status
      );
    }

    setPaymentData(filteredPaymentData);
  };

  useEffect(() => {
    handleFilterPaymentsData();
  }, [paymentFilters]);

  return (
    <Flex direction='column' gap='md'>
      {createPaymentVisible ? (
        <CreatePayment onClose={() => setCreatePaymentVisibility(false)} />
      ) : (
        <>
          <PaymentCards paymentData={mockPaymentData} />
          <PaymentsTableFilter
            filters={paymentFilters}
            onSetFilters={handleSetFilters}
            onCreatePayment={() => setCreatePaymentVisibility(true)}
          />
          <PaymentsTable paymentData={paymentData} />
        </>
      )}
    </Flex>
  );
};

export default PaymentPage;
