import {
  Grid,
  Flex,
  Box,
  Text,
  Divider,
  Tabs,
  Input,
  Button,
  ActionIcon,
  Select,
  Modal,
  Center,
  LoadingOverlay,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import {
  IconPencil,
  IconCalendar,
  IconChevronDown,
  IconCircleCheck,
  IconUser,
  IconBriefcase,
} from '@tabler/icons-react';
import { useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import useSWR from 'swr';

import { IntProfileLabel } from '../../atoms/profile/inputs';
import userService from '../../../api/user';
import { mockLocationData } from '../../../mocks/locations';
import useStyles from './ProfileOrg.styles';
import { customLoader } from '../../atoms/loader/loader';
import mapperAPI from '../../../mapper';
import mapperType from '../../../utils/mapperType';

const fetcher = (url) =>
  userService
    .getUser(url, { info: { email: 'jerald@clearmindai.com' } })
    .then((res) => res.data);

const filterProvinces = () => {
  return (
    mockLocationData.map((el) => ({
      value: el.province,
      label: el.province,
    })) || []
  );
};

const UserFormSection = () => {
  const { classes } = useStyles();

  const [isEditUser, setEditUser] = useState(false);
  const [isEditBuss, setEditBuss] = useState(false);
  const [provList, setProvList] = useState('');
  const [cityList, setCityList] = useState([]);
  const [brgyList, setBrgyList] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);
  const { data, isLoading, error } = useSWR('/user/get', fetcher);

  if (error) return <h1>Failed to load...</h1>;
  if (isLoading) {
    return (
      <LoadingOverlay
        loader={customLoader}
        overlayOpacity={0.3}
        overlayColor='#c5c5c5'
        visible
      />
    );
  }

  const mapAPI = mapperAPI(data, mapperType.profileMapper);

  const {
    firstName,
    middleName,
    lastName,
    birthday,
    gender,
    mobileNo,
    email,
    userProvince,
    userBarangay,
    userCity,
    userZipCode,
    userAddress,
    personal,
    tin,
    website,
    physicalStore,
    businessName,
    businessClass,
    businessType,
    businessAddress,
    businessBarangay,
    businessCity,
    businessProvince,
    businessZipcode,
  } = mapAPI || {};

  const provinces = filterProvinces();

  const changeCity = (eProv) => {
    if (!eProv) return;
    setProvList(eProv);
    const provSelected = mockLocationData.find((el) => el.province === eProv);
    const citySelected =
      provSelected.cities.map((el) => ({
        value: el.name,
        label: el.name,
      })) || [];
    setCityList(citySelected);
  };

  const changeBrgy = (eCity) => {
    if (!eCity) return;
    setCityList([eCity]);
    const provSelected = mockLocationData.find(
      (el) => el.province === provList
    );
    const citySelected = provSelected.cities.find((el) => el.name === eCity);
    setBrgyList(citySelected.barangays);
  };

  return (
    <Tabs defaultValue='userInfoTab' px={16}>
      <Modal
        radius='xl'
        opened={opened}
        onClose={() => {
          if (isEditUser) {
            setEditUser(!isEditUser);
          }
          if (isEditBuss) {
            setEditBuss(!isEditBuss);
          }
          close(true);
        }}
        centered
      >
        <Center>
          <Flex
            justify='center'
            align='center'
            direction='column'
            gap='xl'
            pb='1rem'
          >
            <Text c='teal.4'>
              <IconCircleCheck size='7rem' />
            </Text>
            <Text c='teal.4' fz='1.5rem' fw={700}>
              Information Save
            </Text>
            <Text fz='sm'>Your informaition has been successfully updated</Text>
            <Button
              className='yellow-btn'
              onClick={() => {
                if (isEditUser) {
                  setEditUser(!isEditUser);
                }
                if (isEditBuss) {
                  setEditBuss(!isEditBuss);
                }
                close(true);
              }}
              radius='sm'
              fw='normal'
            >
              Okay
            </Button>
          </Flex>
        </Center>
      </Modal>
      <Tabs.List>
        <Tabs.Tab icon={<IconUser size='1.5rem' />} value='userInfoTab'>
          User Information
        </Tabs.Tab>
        <Tabs.Tab icon={<IconBriefcase size='1.5rem' />} value='bussInfoTab'>
          Business Information
        </Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value='userInfoTab' pt='xs'>
        <Flex p='lg' className={classes.profileFormContainer}>
          {isEditUser ? (
            <Grid>
              <Grid.Col span={12}>
                <Text fz='sm' fw={600}>
                  Personal Details
                </Text>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper withAsterisk label='First Name'>
                  <Input defaultValue={firstName} placeholder='First Name' />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper label='Middle Name'>
                  <Input defaultValue={middleName} placeholder='Middle Name' />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper withAsterisk label='Last Name'>
                  <Input defaultValue={lastName} placeholder='Last Name' />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <DateInput
                  placeholder='Date of Birth'
                  label='Date of Birth'
                  withAsterisk
                  defaultValue={new Date(birthday)}
                  icon={<IconCalendar />}
                />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper withAsterisk label='Gender'>
                  <Input
                    component='select'
                    rightSection={<IconChevronDown size={14} stroke={1.5} />}
                  >
                    <option value='M'>Male</option>
                    <option value='F'>Female</option>
                  </Input>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper withAsterisk label='Mobile Number'>
                  <Input defaultValue={mobileNo} placeholder='Mobile Number' />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper withAsterisk label='Email Address'>
                  <Input defaultValue={email} placeholder='Mobile Number' />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider my='xs' />
              </Grid.Col>
              <Grid.Col span={12}>
                <Text size='sm' fw={600}>
                  Address Details
                </Text>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Select
                  defaultValue={userProvince}
                  searchable
                  clearable
                  withAsterisk
                  label='Province'
                  nothingFound='No options'
                  placeholder='Province'
                  onChange={(e) => changeCity(e)}
                  data={provinces}
                />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Select
                  defaultValue={userCity}
                  searchable
                  clearable
                  withAsterisk
                  label='City'
                  nothingFound='No options'
                  placeholder='City'
                  onChange={(e) => changeBrgy(e)}
                  data={cityList}
                />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Select
                  defaultValue={userBarangay}
                  searchable
                  withAsterisk
                  clearable
                  label='Barangay'
                  nothingFound='No options'
                  placeholder='Barangay'
                  data={brgyList}
                />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper withAsterisk label='Zip Code'>
                  <Input defaultValue={userZipCode} placeholder='Zip Code' />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper
                  withAsterisk
                  label='House No. and Street address'
                >
                  <Input
                    defaultValue={userAddress}
                    placeholder='House No. and Street address'
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={12} pt='2rem'>
                <Flex
                  justify='flex-end'
                  align='center'
                  direction='row'
                  gap='xs'
                >
                  <Box w={140}>
                    <Button
                      onClick={() => setEditUser(!isEditUser)}
                      fullWidth
                      color='ubsBtnExitColor.3'
                      c='black'
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Box w={140}>
                    <Button className='yellow-btn' onClick={open} fullWidth>
                      Save
                    </Button>
                  </Box>
                </Flex>
              </Grid.Col>
            </Grid>
          ) : (
            <Grid>
              <Grid.Col span={10}>
                <Text size='sm' fw={600}>
                  Personal Details
                </Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Flex
                  justify='flex-end'
                  align='flex-start'
                  direction='row'
                  wrap='wrap'
                >
                  <ActionIcon
                    onClick={() => {
                      setEditUser(!isEditUser);
                      setCityList([{ label: userCity, value: userCity }]);
                      setBrgyList([{ label: userBarangay, value: userBarangay }]);
                    }}
                    variant='transparent'
                  >
                    <IconPencil />
                  </ActionIcon>
                </Flex>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='First Name' value={firstName} />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='Middle Name' value={middleName} />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='Last Name' value={lastName} />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='Date of Birth' value={birthday} />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='Gender' value={gender} />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='Mobile Number' value={mobileNo} />
              </Grid.Col>
              <Grid.Col span={12}>
                <IntProfileLabel name='Email Address' value={email} />
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider my='xs' />
              </Grid.Col>
              <Grid.Col span={12}>
                <Text size='sm' fw={600}>
                  Address Details
                </Text>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='Province' value={userProvince} />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='City' value={userCity} />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='Barangay' value={userBarangay} />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='Zip Code' value={userZipCode} />
              </Grid.Col>
              <Grid.Col xs={12} md={5}>
                <IntProfileLabel
                  name='House No. and Street address'
                  value={userAddress}
                />
              </Grid.Col>
            </Grid>
          )}
        </Flex>
      </Tabs.Panel>
      <Tabs.Panel value='bussInfoTab' pt='xs'>
        <Flex p='lg' className={classes.profileFormContainer}>
          {isEditBuss ? (
            <Grid>
              <Grid.Col span={12}>
                <Text size='sm' fw={600}>
                  Business Details
                </Text>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper withAsterisk label='Business Name'>
                  <Input
                    defaultValue={businessName}
                    placeholder='Business Name'
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper withAsterisk label='Business Classification'>
                  <Input
                    defaultValue={businessClass}
                    placeholder='Business Classification'
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper withAsterisk label='Business Type'>
                  <Input
                    defaultValue={businessType}
                    placeholder='Business Type'
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper withAsterisk label='Physical Store'>
                  <Input
                    component='select'
                    defaultValue={physicalStore}
                    rightSection={<IconChevronDown size={14} stroke={1.5} />}
                  >
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                  </Input>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper withAsterisk label='Personal Business'>
                  <Input
                    component='select'
                    defaultValue={personal}
                    rightSection={<IconChevronDown size={14} stroke={1.5} />}
                  >
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                  </Input>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper withAsterisk label='TIN No.'>
                  <Input defaultValue={tin} placeholder='TIN No.' />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper withAsterisk label='Website'>
                  <Input defaultValue={website} placeholder='Website' />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider my='xs' />
              </Grid.Col>
              <Grid.Col span={12}>
                <Text size='sm' fw={600}>
                  Address Details
                </Text>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Select
                  defaultValue={businessProvince}
                  searchable
                  clearable
                  withAsterisk
                  label='Province'
                  nothingFound='No options'
                  placeholder='Province'
                  onChange={(e) => changeCity(e)}
                  data={provinces}
                />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Select
                  defaultValue={businessCity}
                  searchable
                  clearable
                  withAsterisk
                  label='City'
                  nothingFound='No options'
                  placeholder='City'
                  onChange={(e) => changeBrgy(e)}
                  data={cityList}
                />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Select
                  defaultValue={businessBarangay}
                  searchable
                  withAsterisk
                  clearable
                  label='Barangay'
                  nothingFound='No options'
                  placeholder='Barangay'
                  data={brgyList}
                />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper withAsterisk label='Zip Code'>
                  <Input
                    defaultValue={businessZipcode}
                    placeholder='Zip Code'
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Input.Wrapper
                  withAsterisk
                  label='House No. and Street address'
                >
                  <Input
                    defaultValue={businessAddress}
                    placeholder='House No. and Street address'
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={12} pt='2rem'>
                <Flex
                  justify='flex-end'
                  align='center'
                  direction='row'
                  gap='xs'
                >
                  <Box w={140}>
                    <Button
                      onClick={() => setEditBuss(!isEditBuss)}
                      fullWidth
                      color='ubsBtnExitColor.3'
                      c='black'
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Box w={140}>
                    <Button className='yellow-btn' onClick={open} fullWidth>
                      Save
                    </Button>
                  </Box>
                </Flex>
              </Grid.Col>
            </Grid>
          ) : (
            <Grid>
              <Grid.Col span={10}>
                <Text size='sm' fw={600}>
                  Business Details
                </Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Flex
                  justify='flex-end'
                  align='flex-start'
                  direction='row'
                  wrap='wrap'
                >
                  <ActionIcon
                    onClick={() => {
                      setEditBuss(!isEditBuss);
                      setCityList([
                        { label: businessCity, value: businessCity },
                      ]);
                      setBrgyList([
                        { label: businessBarangay, value: businessBarangay },
                      ]);
                    }}
                    variant='transparent'
                  >
                    <IconPencil />
                  </ActionIcon>
                </Flex>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='Business Name' value={businessName} />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel
                  name='Business Classification'
                  value={businessClass}
                />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='Business Type' value={businessType} />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='Physical Store' value={physicalStore} />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='Personal Business' value={personal} />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='TIN No.' value={tin} />
              </Grid.Col>
              <Grid.Col span={12}>
                <IntProfileLabel name='Website' value={website} />
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider my='xs' />
              </Grid.Col>
              <Grid.Col span={12}>
                <Text size='sm' fw={600}>
                  Address Details
                </Text>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='Province' value={businessProvince} />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='City' value={businessCity} />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='Barangay' value={businessBarangay} />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <IntProfileLabel name='Zip Code' value={businessZipcode} />
              </Grid.Col>
              <Grid.Col xs={12} md={5}>
                <IntProfileLabel
                  name='House No. and Street address'
                  value={businessAddress}
                />
              </Grid.Col>
            </Grid>
          )}
        </Flex>
      </Tabs.Panel>
    </Tabs>
  );
};

export default UserFormSection;
