import { Flex, Stepper, Grid, Text } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import useStyles from './Stepper.styles';

const AtomStepper = ({ steps, hasWrapper = true, activeStep = 1 }) => {
  const { classes } = useStyles();

  return (
    <Flex
      direction='column'
      className={hasWrapper ? 'white-wrapper' : ''}
      sx={{ marginTop: '0 !important', width: '100%' }}
    >
      <Stepper
        color='yellow'
        size='md'
        active={activeStep}
        className={classes.stepper}
      >
        {steps.map((stepLabel) => (
          <Stepper.Step
            key={stepLabel}
            label={stepLabel}
            completedIcon={<IconCheck />}
          />
        ))}
      </Stepper>
      <Grid w='100%' mt={16}>
        {steps.map((stepLabel, stepIndex) => {
          const isFirstStep = stepIndex === 0;
          const isLastStep = stepIndex === steps.length - 1;
          let textAlign = '';
          if (isFirstStep) textAlign = 'start';
          else if (isLastStep) textAlign = 'end';
          else textAlign = 'center';

          return (
            <Grid.Col key={stepLabel} span={12 / steps.length}>
              <Text ta={textAlign}>{stepLabel}</Text>
            </Grid.Col>
          );
        })}
      </Grid>
    </Flex>
  );
};

export default AtomStepper;
