import {
  IconHome2,
  IconCoins,
  IconCreditCard,
  IconSend,
  IconReport,
  IconCash,
  IconUsersGroup,
  IconUpload,
  IconWorld,
  IconMapPin,
} from '@tabler/icons-react';
import { RoutePaths } from '../constants/routes';

export const privateMenu = [
  {
    icon: <IconHome2 />,
    label: 'Dashboard',
    to: RoutePaths.Dashboard,
  },
  {
    icon: <IconCoins />,
    label: 'Payments',
    to: RoutePaths.Payment,
  },
  {
    icon: <IconCreditCard />,
    label: 'My Bank Accounts',
    to: RoutePaths.BankAccountMain,
  },
  {
    icon: <IconSend />,
    label: 'Send Money',
    children: [
      {
        icon: <IconWorld />,
        label: 'International',
        to: RoutePaths.SendMoneyIntl,
      },
      {
        icon: <IconMapPin />,
        label: 'Domestic',
        to: RoutePaths.SendMoneyDomestic,
      },
    ],
  },
  {
    icon: <IconReport />,
    label: 'Reports',
    to: RoutePaths.Report,
  },
  {
    icon: <IconCash />,
    label: 'Payroll',
    children: [
      {
        icon: <IconUsersGroup />,
        label: 'Employee',
        to: RoutePaths.EmployeeList,
      },
      {
        icon: <IconUpload />,
        label: 'Upload Payroll',
        to: RoutePaths.UploadPayroll,
      },
    ],
  },
  {
    icon: <IconCash size='1.5rem' />,
    label: 'Cash Disbursement',
    to: RoutePaths.Disbursement,
  },
];
