import BDOLogo from '../assets/images/bdo-logo.svg';
import BPILogo from '../assets/images/bpi-logo.svg';

export const accountCards = [
  {
    fullName: ' John Doe',
    bankLogo: BDOLogo,
    bankName: 'My BDO',
    bankNum: '8849968847588584',
    wallet: '78,900.00'
  },
  {
    fullName: ' James Jowell',
    bankLogo: BPILogo,
    bankName: 'My BPI',
    bankNum: '9948588588377488',
    wallet: '30,780.00'
  }
];

export const accountTrans = [
  {
    type: 'Bank Transfer',
    referenceNo: '20231728MIP8',
    bankName: 'My BDO',
    amount: '- ₱ 1,000.00',
    TransDate: '2023-10-12 10:45 AM'
  },
  {
    type: 'Bank Transfer',
    referenceNo: '20231728UII9',
    bankName: 'My BPI',
    amount: '- ₱ 9,000.00',
    TransDate: '2023-10-12 10:45 AM'
  },
  {
    type: 'Bank Transfer',
    referenceNo: '20221728UII0',
    bankName: 'My BPI',
    amount: '- ₱ 11,000.00',
    TransDate: '2023-10-12 10:45 AM'
  },
  {
    type: 'Bank Transfer',
    referenceNo: '20221728RRT4',
    bankName: 'My BDO',
    amount: '- ₱ 12,000.00',
    TransDate: '2023-10-12 10:45 AM'
  },
  {
    type: 'Bank Transfer',
    referenceNo: '2022172YTR6',
    bankName: 'My BDO',
    amount: '- ₱ 14,000.00',
    TransDate: '2023-10-12 10:45 AM'
  },
  {
    type: 'Bank Transfer',
    referenceNo: '20231728UII9',
    bankName: 'My BPI',
    amount: '- ₱ 9,000.00',
    TransDate: '2023-10-12 10:45 AM'
  },
  {
    type: 'Bank Transfer',
    referenceNo: '20221728UII0',
    bankName: 'My BPI',
    amount: '- ₱ 11,000.00',
    TransDate: '2023-10-12 10:45 AM'
  },
  {
    type: 'Bank Transfer',
    referenceNo: '20221728RRT4',
    bankName: 'My BDO',
    amount: '- ₱ 12,000.00',
    TransDate: '2023-10-12 10:45 AM'
  },
  {
    type: 'Bank Transfer',
    referenceNo: '2022172YTR6',
    bankName: 'My BDO',
    amount: '- ₱ 14,000.00',
    TransDate: '2023-10-12 10:45 AM'
  },
  {
    type: 'Bank Transfer',
    referenceNo: '20231728UII9',
    bankName: 'My BPI',
    amount: '- ₱ 9,000.00',
    TransDate: '2023-10-12 10:45 AM'
  },
  {
    type: 'Bank Transfer',
    referenceNo: '20221728UII0',
    bankName: 'My BPI',
    amount: '- ₱ 11,000.00',
    TransDate: '2023-10-12 10:45 AM'
  },
  {
    type: 'Bank Transfer',
    referenceNo: '20221728RRT4',
    bankName: 'My BDO',
    amount: '- ₱ 12,000.00',
    TransDate: '2023-10-12 10:45 AM'
  },
  {
    type: 'Bank Transfer',
    referenceNo: '2022172YTR6',
    bankName: 'My BDO',
    amount: '- ₱ 14,000.00',
    TransDate: '2023-10-12 10:45 AM'
  },

];

export const bankAvailable = ['BDO', 'BPI', 'Metro Bank', 'Union Bank', 'MayBank'];
