import AtomStepper from '../../atoms/stepper/Stepper';

const SendMoneyIntlStepper = ({ activeStep }) => {
  return (
    <AtomStepper
      steps={['Send Money Details', 'Transaction Amount', 'Confirm Details']}
      activeStep={activeStep}
    />
  );
};

export default SendMoneyIntlStepper;
