import {
  Grid,
  Select,
  Radio,
  TextInput,
  Checkbox,
  Button,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import {
  signupProvinces,
  signupCities,
  signupBrgys,
} from '../../../constants/sign-up';
import { SignupBusinessFormSchema } from '../../../schema/sign-up';
import useStyles from '../../pages/login/LoginPage.styles';

const SignUpBusinessDetails = ({ formData, onPrevStep, onSignup }) => {
  const { classes } = useStyles();
  const signupBusinessForm = useForm({
    validate: yupResolver(SignupBusinessFormSchema),
    initialValues: formData,
  });
  const { onSubmit, getInputProps, setFieldValue } = signupBusinessForm;

  const handleSubmit = (data) => {
    onSignup({ ...formData, ...data });
  };

  const handleTogglePersonalAddr = (isPersonalAddr) => {
    let businessAddress = {
      businessProvince: '',
      businessCity: '',
      businessBrgy: '',
      businessZipCode: '',
      businessAddress: '',
    };
    if (isPersonalAddr) {
      businessAddress = {
        businessProvince: formData.province,
        businessCity: formData.city,
        businessBrgy: formData.barangay,
        businessZipCode: formData.zipCode,
        businessAddress: formData.address,
      };
    }
    setFieldValue('businessProvince', businessAddress.businessProvince);
    setFieldValue('businessCity', businessAddress.businessCity);
    setFieldValue('businessBrgy', businessAddress.businessBrgy);
    setFieldValue('businessZipCode', businessAddress.businessZipCode);
    setFieldValue('businessAddress', businessAddress.businessAddress);
    setFieldValue('isPersonalAddress', isPersonalAddr);
  };

  return (
    <form style={{ width: '100%' }} onSubmit={onSubmit(handleSubmit)}>
      <Grid w='100%' mt={32}>
        <Grid.Col sm={8}>
          <Select
            label='Business Clasification'
            data={signupProvinces}
            variant='unstyled'
            className={classes.loginInput}
            clearable
            {...getInputProps('businessClass')}
          />
        </Grid.Col>
        <Grid.Col sm={8}>
          <Select
            label='Business Type'
            data={signupProvinces}
            variant='unstyled'
            className={classes.loginInput}
            clearable
            {...getInputProps('businessType')}
          />
        </Grid.Col>
        <Grid.Col sm={4} display='flex' sx={{ alignItems: 'center' }}>
          <Radio.Group
            {...getInputProps('isPhysicalStore')}
            label='Physical store?'
          >
            <Grid align='center' justify='space-between' w='100%'>
              <Grid.Col sm={6} xs={12}>
                <Radio size='sm' value='true' label='Yes' />
              </Grid.Col>
              <Grid.Col sm={6} xs={12}>
                <Radio size='sm' value='false' label='No' />
              </Grid.Col>
            </Grid>
          </Radio.Group>
        </Grid.Col>
        <Grid.Col sm={8}>
          <TextInput
            label='Business Name'
            placeholder='Business Name'
            variant='unstyled'
            className={classes.loginInput}
            {...getInputProps('businessName')}
          />
        </Grid.Col>
        <Grid.Col sm={4}>
          <span />
        </Grid.Col>
        <Grid.Col sm={4}>
          <Select
            label='Province'
            data={signupProvinces}
            variant='unstyled'
            className={classes.loginInput}
            clearable
            {...getInputProps('businessProvince')}
          />
        </Grid.Col>
        <Grid.Col sm={4}>
          <Select
            label='City'
            data={signupCities}
            variant='unstyled'
            className={classes.loginInput}
            clearable
            {...getInputProps('businessCity')}
          />
        </Grid.Col>
        <Grid.Col sm={4}>
          <Select
            label='Barangay'
            data={signupBrgys}
            variant='unstyled'
            className={classes.loginInput}
            clearable
            {...getInputProps('businessBrgy')}
          />
        </Grid.Col>
        <Grid.Col sm={4}>
          <TextInput
            label='ZIP Code'
            placeholder='ZIP Code'
            variant='unstyled'
            className={classes.loginInput}
            {...getInputProps('businessZipCode')}
          />
        </Grid.Col>
        <Grid.Col sm={8}>
          <TextInput
            label='House no. and Street address'
            placeholder='House no. and Street address'
            variant='unstyled'
            className={classes.loginInput}
            {...getInputProps('businessAddress')}
          />
        </Grid.Col>
        <Grid.Col>
          <Checkbox
            label='Same as Personal Address'
            {...getInputProps('isPersonalAddress')}
            onChange={(e) => handleTogglePersonalAddr(e.currentTarget.checked)}
          />
        </Grid.Col>
        <Grid.Col sm={6}>
          <TextInput
            label='Website / Facebook Page'
            placeholder='https://www.website.com'
            variant='unstyled'
            className={classes.loginInput}
            {...getInputProps('socmedLink')}
          />
        </Grid.Col>
        <Grid.Col sm={6}>
          <TextInput
            label='TIN (Tax Identification No.)'
            placeholder='0000-0000'
            variant='unstyled'
            className={classes.loginInput}
            {...getInputProps('businessTIN')}
          />
        </Grid.Col>
        <Grid.Col mt={32} display='flex' sx={{ gap: 10 }}>
          <Button
            className='defaultBtn'
            w={160}
            type='button'
            variant='outline'
            onClick={onPrevStep}
          >
            Back
          </Button>
          <Button className='defaultBtn' w={160} type='submit'>
            Sign Up
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

export default SignUpBusinessDetails;
