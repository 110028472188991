import { DateTime } from 'luxon';

export const convertAbbrevFullDt = (date) => {
  const parseDate = new Date(date).toISOString();
  const abbrevFullDate = DateTime.fromISO(parseDate).toLocaleString(
    DateTime.DATE_MED
  );
  return abbrevFullDate;
};

export const getTime = (date) => {
  const parseDate = new Date(date).toISOString();
  const dateTime = DateTime.fromISO(parseDate).toLocaleString(
    DateTime.TIME_SIMPLE
  );
  return dateTime;
};

export const getDateTime = (date, format) => {
  const parseDate = new Date(date).toISOString();
  return DateTime.fromISO(parseDate).toFormat(format);
};
