import { Grid, Image, Text, Title } from '@mantine/core';

const AtomFlagWallet = ({ label, value, flagImg }) => {
  return (
    <Grid w='100%'>
      <Grid.Col
        md={6}
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: 16,
          [theme.fn.smallerThan('md')]: {
            alignItems: 'center',
            justifyContent: 'center',
          },
        })}
      >
        <Image src={flagImg} width={50} height={50} />
        <Text>{label}</Text>
      </Grid.Col>
      <Grid.Col
        md={6}
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          [theme.fn.smallerThan('md')]: {
            alignItems: 'center',
            justifyContent: 'center',
          },
        })}
      >
        <Title order={4} className='page-h4-title'>
          {value}
        </Title>
        <span className='span-sub-text'>Total Balance</span>
      </Grid.Col>
    </Grid>
  );
};

export default AtomFlagWallet;
