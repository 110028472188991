import { Image, MediaQuery, Burger, Flex } from '@mantine/core';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../../constants/routes';
import ubsColoredLogo from '../../../assets/images/ubs-colored-logo.svg';
import useStyles from './PrivateLayout.styles';

const NavbarLogo = ({ onHideNavbar }) => {
  const { classes } = useStyles();

  return (
    <Flex align='center' justify='space-between' mt={16}>
      <Image
        className={classes.navBarLogo}
        src={ubsColoredLogo}
        fit='contain'
        alt='UBS colored logo'
        component={Link}
        to={RoutePaths.Dashboard}
      />
      <MediaQuery largerThan='xs' styles={{ display: 'none' }}>
        <Burger
          opened
          onClick={onHideNavbar}
          size='sm'
          className={classes.privateIcon}
        />
      </MediaQuery>
    </Flex>
  );
};

export default NavbarLogo;
