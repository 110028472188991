import { Grid, Select, Radio, Flex, Text, Button } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IconFileSpreadsheet, IconPdf } from '@tabler/icons-react';
import { useForm, yupResolver } from '@mantine/form';
import { GenerateReportSchema } from '../../../schema/report';
import useStyles from '../../pages/report/ReportPage.styles';

const reportTypeOptions = [
  { value: 'paid-transaction', label: 'Paid Transaction' },
];

const GenerateReportForm = ({ onGenerate }) => {
  const { classes } = useStyles();
  const generateReportForm = useForm({
    validate: yupResolver(GenerateReportSchema),
    initialValues: {
      reportType: '',
      dateFrom: '',
      dateTo: '',
      reportFileType: '',
    },
  });
  const { onSubmit, getInputProps, values } = generateReportForm;

  // Add form data in method parameters
  const handleGenerateReport = () => {
    onGenerate();
  };

  return (
    <form
      className={classes.generateReportForm}
      onSubmit={onSubmit(handleGenerateReport)}
    >
      <Grid align='center' justify='space-between' w='100%'>
        <Grid.Col>
          <Select
            label='Report Type'
            data={reportTypeOptions}
            clearable
            {...getInputProps('reportType')}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <DateInput
            label='Date From'
            maxDate={values.dateTo ?? undefined}
            clearable
            {...getInputProps('dateFrom')}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <DateInput
            label='Date To'
            minDate={values.dateFrom ?? undefined}
            clearable
            {...getInputProps('dateTo')}
          />
        </Grid.Col>
        <Grid.Col>
          <Radio.Group
            label='Export report as:'
            {...getInputProps('reportFileType')}
          >
            <Grid align='center' justify='space-between' w='100%'>
              <Grid.Col sm={6} xs={12}>
                <Radio
                  className={classes.reportFileType}
                  size='md'
                  value='xls'
                  label={
                    <Flex align='center' justify='flex-start'>
                      <IconFileSpreadsheet /> <Text ml={6}>Excel (.xls)</Text>
                    </Flex>
                  }
                />
              </Grid.Col>
              <Grid.Col sm={6} xs={12}>
                <Radio
                  className={classes.reportFileType}
                  size='md'
                  value='pdf'
                  label={
                    <Flex align='center' justify='flex-start'>
                      <IconPdf /> <Text ml={6}>PDF (.pdf)</Text>
                    </Flex>
                  }
                />
              </Grid.Col>
            </Grid>
          </Radio.Group>
        </Grid.Col>
        <Grid.Col sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type='submit'>Generate</Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

export default GenerateReportForm;
