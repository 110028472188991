import { createStyles, rem } from '@mantine/core';

const yellowColor = 'linear-gradient(298.06deg, #E8BF46 1.58%, #E6CC7F 99.48%)';

const useStyles = createStyles((theme) => ({
  dashboardCardGrid: {
    width: '100%',
  },
  dashboardCardColored: {
    background: yellowColor,
  },
  dashboardSwitch: {
    '.mantine-Switch-track': {
      backgroundColor: 'white !important',
      borderColor: 'white !important',
      color: 'black !important',
      fontSize: rem(16),
      '.mantine-Switch-thumb': {
        backgroundColor: theme.colors.ubsPrimary[6],
      },
    },
  },
  dashboardHistoryBtn: {
    padding: 0,
    ':hover': {
      background: 'none',
      color: theme.colors.ubsDashboard[6],
      textDecoration: 'underline',
    },
  },
  modalHeaderTxtWrapper: {
    h4: {
      color: '#FFFFFF',
    },
    h5: {
      color: '#FFFFFF',
      fontWeight: 400,
    },
    span: {
      color: '#FFFFFF',
      fontSize: rem(12),
      fontWeight: 400,
    },
  },
  modalContentTitle: {
    color: '#2A2A2A',
    fontSize: rem(16),
    fontWeight: 750,
  },
  modalContentInfoLabel: {
    color: '#60017F',
  },
  modalContentInfoValue: {
    color: '#2A2A2A',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.fn.smallerThan('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  dashboardSendMoneyBtn: {
    height: '70px',
    backgroundColor: theme.colors.ubsDashboard[1],
    ':hover': {
      backgroundColor: theme.colors.ubsDashboard[2],
    },
    '.mantine-Button-inner': {
      display: 'flex',
      justifyContent: 'flex-start',
      fontSize: '18px',
      color: theme.colors.ubsDashboard[9],
      padding: '0 1rem',
    },
  },
}));

export default useStyles;
