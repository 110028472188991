import { RoutePaths } from '../constants/routes';
import PublicLayout from '../components/layouts/public/PublicLayout';
import PrivateLayout from '../components/layouts/private/PrivateLayout';
import ErrorPage from '../components/pages/error/ErrorPage';
import LandingPage from '../components/pages/landing/LandingPage';
import LoginPage from '../components/pages/login/LoginPage';
import RegistrationPage from '../components/pages/registration/RegistrationPage';
import ContactPage from '../components/pages/contact/ContactPage';
import DashboardPage from '../components/pages/dashboard/DashboardPage';
import PaymentPage from '../components/pages/payment/PaymentPage';
import {
  BankAccountMainPage,
  BankAccountAddPage,
  BankAccountTransPage,
} from '../components/pages/bank-account/BankAccountPage';
import SendMoneyIntlPage from '../components/pages/send-money-intl/SendMoneyIntlPage';
import SendMoneyDomesticPage from '../components/pages/send-money-domestic/SendMoneyDomesticPage';
import ReportPage from '../components/pages/report/ReportPage';
import {
  EmployeeListPage,
  EmployeeInfoPage,
} from '../components/pages/employee/EmployeePage';
import UploadPayrollPage from '../components/pages/upload-payroll/UploadPayrollPage';
import ProfilePage from '../components/pages/profile/ProfilePage';
import CashDisbursementPage from '../components/pages/cash-disbursement/CashDisbursementPage';
import GenerateQRPage from '../components/pages/generate-qr/GenerateQRPage';
import SettingsPage from '../components/pages/settings/SettingsPage';
import AddFundPage from '../components/pages/add-fund/AddFundPage';
import BuyForexPage from '../components/pages/buy-forex/BuyForexPage';
import SellForexPage from '../components/pages/sell-forex/SellForexPage';
import SignUpPage from '../components/pages/sign-up/SignUpPage';

const routes = [
  {
    element: <PublicLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePaths.Root,
        element: <LandingPage />,
      },
      {
        path: RoutePaths.Login,
        element: <LoginPage />,
      },
      {
        path: RoutePaths.Register,
        element: <RegistrationPage />,
      },
      {
        path: RoutePaths.Contact,
        element: <ContactPage />,
      },
      {
        path: RoutePaths.SignUp,
        element: <SignUpPage />,
      },
    ],
  },
  {
    element: <PrivateLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: RoutePaths.Dashboard,
        element: <DashboardPage />,
      },
      {
        path: RoutePaths.Payment,
        element: <PaymentPage />,
      },
      {
        path: RoutePaths.BankAccountMain,
        element: <BankAccountMainPage />,
      },
      {
        path: RoutePaths.BankAccountAdd,
        element: <BankAccountAddPage />,
      },
      {
        path: RoutePaths.BankAccountTrans,
        element: <BankAccountTransPage />,
      },
      {
        path: RoutePaths.SendMoneyIntl,
        element: <SendMoneyIntlPage />,
      },
      {
        path: RoutePaths.SendMoneyDomestic,
        element: <SendMoneyDomesticPage />,
      },
      {
        path: RoutePaths.Report,
        element: <ReportPage />,
      },
      {
        path: RoutePaths.EmployeeList,
        element: <EmployeeListPage />,
      },
      {
        path: RoutePaths.EmployeeInfo,
        element: <EmployeeInfoPage />,
      },
      {
        path: RoutePaths.Profile,
        element: <ProfilePage />,
      },
      {
        path: RoutePaths.Disbursement,
        element: <CashDisbursementPage />,
      },
      {
        path: RoutePaths.UploadPayroll,
        element: <UploadPayrollPage />,
      },
      {
        path: RoutePaths.GenerateQR,
        element: <GenerateQRPage />,
      },
      {
        path: RoutePaths.Settings,
        element: <SettingsPage />,
      },
      {
        path: RoutePaths.AddFund,
        element: <AddFundPage />,
      },
      {
        path: RoutePaths.BuyForex,
        element: <BuyForexPage />,
      },
      {
        path: RoutePaths.SellForex,
        element: <SellForexPage />,
      },
    ],
  },
];

export default routes;
