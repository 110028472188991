import { useState } from 'react';
import {
  Grid,
  TextInput,
  Radio,
  Select,
  Checkbox,
  Button,
  Title,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm, yupResolver } from '@mantine/form';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../../constants/routes';
import {
  signupProvinces,
  signupCities,
  signupBrgys,
} from '../../../constants/sign-up';
import { SignupPersonalFormSchema } from '../../../schema/sign-up';
import SignUpTermsConditions from './SignUpTermsConditions';
import useStyles from '../../pages/login/LoginPage.styles';

const SignUpPersonalDetails = ({ formData, onNextStep }) => {
  const [showTermsCons, setTermsConVisibility] = useState(false);
  const [confirmTermsCons, setConfirmTermsCons] = useState(false);
  const { classes } = useStyles();
  const signupPersonalForm = useForm({
    validate: yupResolver(SignupPersonalFormSchema),
    initialValues: formData,
  });
  const { onSubmit, getInputProps } = signupPersonalForm;

  const handleSubmit = (data) => {
    onNextStep(data);
  };

  return (
    <>
      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid w='100%' mt={32}>
          <Grid.Col sm={4}>
            <TextInput
              label='First Name'
              placeholder='First Name'
              variant='unstyled'
              className={classes.loginInput}
              {...getInputProps('firstName')}
            />
          </Grid.Col>
          <Grid.Col sm={4}>
            <TextInput
              label='Middle Name'
              placeholder='Middle Name'
              variant='unstyled'
              className={classes.loginInput}
              {...getInputProps('middleName')}
            />
          </Grid.Col>
          <Grid.Col sm={4}>
            <TextInput
              label='Last Name'
              placeholder='Last Name'
              variant='unstyled'
              className={classes.loginInput}
              {...getInputProps('lastName')}
            />
          </Grid.Col>
          <Grid.Col sm={4}>
            <DateInput
              label='Date of birth'
              variant='unstyled'
              className={classes.loginInput}
              {...getInputProps('birthDate')}
            />
          </Grid.Col>
          <Grid.Col sm={8} display='flex' sx={{ alignItems: 'center' }}>
            <Radio.Group {...getInputProps('gender')}>
              <Grid align='center' justify='space-between' w='100%'>
                <Grid.Col sm={6} xs={12}>
                  <Radio size='sm' value='Male' label='Male' />
                </Grid.Col>
                <Grid.Col sm={6} xs={12}>
                  <Radio size='sm' value='Female' label='Female' />
                </Grid.Col>
              </Grid>
            </Radio.Group>
          </Grid.Col>
          <Grid.Col sm={4}>
            <Select
              label='Province'
              data={signupProvinces}
              variant='unstyled'
              className={classes.loginInput}
              clearable
              {...getInputProps('province')}
            />
          </Grid.Col>
          <Grid.Col sm={4}>
            <Select
              label='City'
              data={signupCities}
              variant='unstyled'
              className={classes.loginInput}
              clearable
              {...getInputProps('city')}
            />
          </Grid.Col>
          <Grid.Col sm={4}>
            <Select
              label='Barangay'
              data={signupBrgys}
              variant='unstyled'
              className={classes.loginInput}
              clearable
              {...getInputProps('barangay')}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <TextInput
              label='ZIP Code'
              placeholder='ZIP Code'
              variant='unstyled'
              className={classes.loginInput}
              {...getInputProps('zipCode')}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <TextInput
              label='House no. and Street address'
              placeholder='House no. and Street address'
              variant='unstyled'
              className={classes.loginInput}
              {...getInputProps('address')}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <TextInput
              label='Mobile Number'
              placeholder='000-0000-000'
              variant='unstyled'
              className={classes.loginInput}
              {...getInputProps('mobileNo')}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <TextInput
              label='Email'
              placeholder='Email'
              variant='unstyled'
              className={classes.loginInput}
              {...getInputProps('email')}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <TextInput
              label='Password'
              placeholder='Password'
              type='password'
              variant='unstyled'
              className={classes.loginInput}
              {...getInputProps('newPassword')}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <TextInput
              label='Confirm Password'
              placeholder='Confirm Password'
              type='password'
              variant='unstyled'
              className={classes.loginInput}
              {...getInputProps('confirmPassword')}
            />
          </Grid.Col>
          <Grid.Col
            display='flex'
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              gap: 5,
              marginTop: 20,
            }}
          >
            <Checkbox
              label='I confirm that I agree to U Business Solutions'
              onChange={(e) => setConfirmTermsCons(e.currentTarget.checked)}
            />
            <span
              style={{
                fontSize: 14,
                fontWeight: 500,
                cursor: 'pointer',
                color: '#fab007',
              }}
              onClick={() => setTermsConVisibility(true)}
            >
              Terms and Conditions
            </span>
          </Grid.Col>
          <Grid.Col
            display='flex'
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 20,
            }}
          >
            <Button
              className='defaultBtn'
              w={160}
              type='submit'
              disabled={!confirmTermsCons}
            >
              Next
            </Button>
          </Grid.Col>
          <Grid.Col
            display='flex'
            sx={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <Title order={6} sx={{ color: 'black', fontWeight: 500 }}>
              Already have an account? <Link to={RoutePaths.Login}>Log In</Link>
            </Title>
          </Grid.Col>
        </Grid>
      </form>
      <SignUpTermsConditions
        visible={showTermsCons}
        onClose={() => setTermsConVisibility(false)}
      />
    </>
  );
};

export default SignUpPersonalDetails;
