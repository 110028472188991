import AtomStepper from '../../atoms/stepper/Stepper';

const SellForexStepper = ({ activeStep }) => {
  return (
    <AtomStepper
      steps={['Sell Forex Details', 'Confirm Details']}
      activeStep={activeStep}
    />
  );
};

export default SellForexStepper;
