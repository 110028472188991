import { createStyles, rem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  bankInfoWrapper: {
    width: '100%',
    backgroundColor: theme.colors.ubsDashboard[0],
    borderRadius: rem(8),
  },
  atomTableWrapper: {
    display: 'block',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },
  atomTable: {
    [theme.fn.smallerThan('lg')]: {
      width: '1000px',
    },
    thead: {
      backgroundColor: '#F4F5F9',
      display: 'table',
      tableLayout: 'fixed',
      width: '100%',
      th: {
        color: '#2A2A2A',
      },
    },
    tbody: {
      display: 'block',
      height: '570px',
      overflowY: 'scroll',
      overflowX: 'hidden',
      tr: {
        display: 'table',
        tableLayout: 'fixed',
        width: '100%',
      },
    },
  },
  tableWrapper: {
    background: '#F4F5F9',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    border: '0.0625rem solid #dee2e6',
    borderRadius: '15px',
    maxWidth: 'none',
    paddingTop: rem(16),
    width: '100%',
  },
}));

export default useStyles;
