import {
  Flex,
  Button,
  Text,
  Modal,
  Center,
  Input,
  Paper,
  MediaQuery,
} from '@mantine/core';
import { IconCertificate } from '@tabler/icons-react';
import { useState } from 'react';

import Banner from '../banner/Banner';

const MobileVerify = ({ visible, onClose }) => {
  const [showProcessedModal, setProcessedModalvisibility] = useState(false);
  return (
    <>
      <Banner
        visible={showProcessedModal}
        onClose={() => {
          setProcessedModalvisibility(false);
        }}
        title='Information Save'
        message='Employee settings has been successfully saved.'
      />
      <Modal
        radius='xl'
        opened={visible}
        onClose={onClose}
        withCloseButton={false}
        centered
        size='lg'
      >
        <Center>
          <Flex justify='center' align='center' direction='column' gap='sm'>
            <Flex
              justify='center'
              direction='column'
              align='center'
              rowGap='sm'
            >
              <Text c='teal.4'>
                <IconCertificate size='5rem' />
              </Text>
              <Text c='gray.7' fz='1.2rem' fw={600}>
                Check your SMS
              </Text>
            </Flex>
            <Flex justify='center' direction='column' align='center' rowGap={0}>
              <Text c='gray.7' fz='0.7rem'>
                A 6-digit code was sent to your mobile number
              </Text>
              <Text fw={600} c='gray.7' fz='0  .9rem'>
                {'+639171835090'.replace(
                  '+639171835090'.substring(6, 11),
                  '******'
                )}
              </Text>
            </Flex>
            <MediaQuery largerThan='md' styles={{ width: '70%' }}>
              <Paper radius={10} bg='gray.2' pl='xl' pr='xl' pt='md' pb='md'>
                <Flex
                  direction='column'
                  justify='flex-start'
                  align='center'
                  rowGap='sm'
                >
                  <Text c='gray.10' fz='0.7rem'>
                    Please enter the 6 digit code
                  </Text>
                  <Flex gap='md'>
                    <Input maxLength={1} ta='center' size='lg' />
                    <Input maxLength={1} ta='center' size='lg' />
                    <Input maxLength={1} ta='center' size='lg' />
                    <Input maxLength={1} ta='center' size='lg' />
                    <Input maxLength={1} ta='center' size='lg' />
                    <Input maxLength={1} ta='center' size='lg' />
                  </Flex>
                </Flex>
              </Paper>
            </MediaQuery>

            <Flex justify='center' align='center' direction='column' gap='lg'>
              <Button
                pl='2rem'
                pr='2rem'
                onClick={onClose}
                radius='sm'
                fw='normal'
                variant='unstyled'
              >
                <Text fw={500} c='gray.7' td='underline'>
                  Resend Code
                </Text>
              </Button>
              <Button
                className='yellow-btn'
                onClick={() => {
                  onClose();
                  setProcessedModalvisibility(true);
                }}
              >
                Okay
              </Button>
            </Flex>
          </Flex>
        </Center>
      </Modal>
    </>
  );
};

export default MobileVerify;
