import { Flex } from '@mantine/core';
import MerchantServices from '../../organisms/landing/MerchantServices';
import ChooseUbs from '../../organisms/landing/ChooseUbs';
import ManageFunds from '../../organisms/landing/ManageFunds';
import FAQ from '../../organisms/landing/FAQ';
import Footer from '../../organisms/landing/Footer';
// import useSWR from 'swr';
// import useSWRMutation from 'swr/mutation';
// import Toast from '../../atoms/toast/Toast';
// import authService from '../../../api/auth';

const LandingPage = () => {
  // Sample usage of get and post API request (with error message mapping)
  // For mutation, call trigger in a user event. Example button onclick
  // const { trigger, errorMsg: loginErrMsg } = useSWRMutation(
  //   '/user/login',
  //   authService.login
  // );
  // const { errorMsg } = useSWR('/api/here', authService.sampleGetApi);

  return (
    <Flex direction='column' align='center' justify='center' h='100%' w='100%'>
      <MerchantServices />
      <ChooseUbs />
      <ManageFunds />
      <FAQ />
      <Footer />
      {/* <Toast message={loginErrMsg} /> */}
      {/* <Toast message={errorMsg} /> */}
    </Flex>
  );
};

export default LandingPage;
