import { createStyles, rem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  signupFormWrapper: {
    padding: rem(40),
    width: '100%',
    [theme.fn.smallerThan('xl')]: {
      paddingLeft: rem(80),
      paddingRight: rem(80),
    },
    [theme.fn.smallerThan('md')]: {
      backgroundColor: 'white',
      border: '1px solid #e8e7e7',
      borderRadius: '5px',
      margin: rem(16),
      maxWidth: '500px',
      padding: rem(32),
      position: 'relative',
      overflowY: 'auto',
      width: '100%',
      zIndex: 2,
    },
  },
}));

export default useStyles;
