import { createStyles, rem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  profileButtonContainer: {
    marginTop: rem(75),
    padding: rem(17),
    width: '100%',
    backgroundColor: theme.colors.ubsDashboard[0],
    borderRadius: rem(8)
  },
  profileFormContainer: {
    backgroundColor: theme.colors.ubsDashboard[0],
    borderRadius: rem(8)
  },
}));

export default useStyles;
