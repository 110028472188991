import { Fragment } from 'react';
import { Grid, Text, Title } from '@mantine/core';
import useStyles from './GridDetails.styles';

const AtomGridDetails = ({ details }) => {
  const { classes } = useStyles();

  return (
    <Grid w='100%'>
      {details.map(({ label, value, isDark, isBold }, detailIndex) => (
        <Fragment key={`content-detail-grid-${detailIndex}`}>
          <Grid.Col sm={6} className={classes.detailInfoLabel}>
            <Text
              color={isDark ? 'dark' : ''}
              fw={isBold ? 700 : 'normal'}
              truncate
            >
              {label}
            </Text>
          </Grid.Col>
          <Grid.Col sm={6} className={classes.detailInfoValue}>
            {isBold ? (
              <Title order={4} truncate className='page-h4-title'>
                {value}
              </Title>
            ) : (
              <Text truncate>{value}</Text>
            )}
          </Grid.Col>
        </Fragment>
      ))}
    </Grid>
  );
};

export default AtomGridDetails;
