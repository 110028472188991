import { Flex, Title, Text, Group } from '@mantine/core';
import AddFunds from '../../organisms/upload-payroll/AddFunds';
import UploadPayrollDropzone from '../../organisms/upload-payroll/UploadPayrollDropzone';

const UploadPayrollPage = () => {
  return (
    <Flex direction='column' gap='md'>
      <Group
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '10px',
        }}
      >
        <Title order={4} className='page-h4-title'>
          Payroll Wallet
        </Title>
        <Text className='span-sub-text'>
          Employee salaries will be deducted from this wallet
        </Text>
      </Group>
      <AddFunds />
      <UploadPayrollDropzone />
    </Flex>
  );
};

export default UploadPayrollPage;
