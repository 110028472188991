import { Flex, Image, Title, Text, Button } from '@mantine/core';
import chooseUbsBanner from '../../../assets/images/why-choose-banner.svg';
import useStyles from '../../pages/landing/LandingPage.styles';

const ChooseUbs = () => {
  const { classes } = useStyles();

  return (
    <Flex className={classes.chooseFlexWrapper}>
      <Image
        className={classes.chooseUbsBanner}
        src={chooseUbsBanner}
        alt='Why Choose UBS'
      />
      <Flex direction='column'>
        <Title order={3} className={classes.merchantH3} mb={16}>
          WHY CHOOSE UBS MERCHANT SERVICES?
        </Title>
        <div className={classes.chooseUbsTxtWrapper}>
          <Text className={classes.chooseUbsDescMainTxt}>
            Accessible payments
          </Text>
          <Text className={classes.merchantText}>
            Customers can now pay through the U nationwide store network of more
            than 800 U Stores operating 7 days a week past banking hours
          </Text>
        </div>
        <div className={classes.chooseUbsTxtWrapper}>
          <Text className={classes.chooseUbsDescMainTxt}>
            Fast transactions
          </Text>
          <Text className={classes.merchantText}>
            Shorter queues and faster transaction times compared to banks
          </Text>
        </div>
        <div className={classes.chooseUbsTxtWrapper}>
          <Text className={classes.chooseUbsDescMainTxt}>
            Convenient management
          </Text>
          <Text className={classes.merchantText}>
            Schedule bank transfers for your funds and access real time reports
            online
          </Text>
        </div>
        <div className={classes.chooseUbsTxtWrapper}>
          <Text className={classes.chooseUbsDescMainTxt}>Easy refunds</Text>
          <Text className={classes.merchantText}>
            Refund payments to customers for returns and cancellations
            regardless of where they are in the country
          </Text>
        </div>
        <div className={classes.chooseUbsTxtWrapper}>
          <Text className={classes.chooseUbsDescMainTxt}>
            Cost-efficient setup
          </Text>
          <Text className={classes.merchantText}>
            Enjoy no setup fees and a low flat-rate transaction fee - plus free
            advertisement in U Stores for more visibility
          </Text>
        </div>
        <Flex mt={16}>
          <Button className='red-btn'>
            BECOME A <span className={classes.chooseUbsBtnSubTxt}>U</span>{' '}
            MERCHANT TODAY
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ChooseUbs;
