import { useState } from 'react';
import {
  Modal,
  Card,
  Title,
  Flex,
  Grid,
  Button,
  Text,
  Image,
} from '@mantine/core';
import { MIME_TYPES, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { bytesToSize } from '../../../../utils/file';
import { SendMoneyUploadedFile } from '../../send-money-intl/SendMoneyDetails';
import AtomDropzone from '../../../atoms/dropzone/Dropzone';
import signupUpload from '../../../../assets/images/signup-uploading.jpg';

const SignupFileUpload = ({ visible, onClose, onNextStep }) => {
  const [extractedData, setExtractedData] = useState([]);
  const [showErr, setErrVisibility] = useState(false);

  const handleOnDropFile = (files) => {
    const dropzoneFileData = [];
    let fileId = extractedData.length;
    if (files.length) {
      files.forEach((file) => {
        fileId += 1;
        const fileName = file.path;
        const fileSize = bytesToSize(file.size);
        dropzoneFileData.push({ fileId, fileName, fileSize, data: [] });
      });
    }
    setExtractedData([...extractedData, ...dropzoneFileData]);
    setErrVisibility(false);
  };

  const handleOnRejectDropFile = () => {
    setErrVisibility(true);
  };

  const handleRemoveFile = (fileId) => {
    const remainingFiles = extractedData.filter(
      (file) => file.fileId !== fileId
    );
    setExtractedData(remainingFiles);
  };

  return (
    <Modal
      opened={visible}
      onClose={onClose}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      padding={0}
      size='lg'
    >
      <Card>
        <Card.Section>
          <Image src={signupUpload} height={200} alt='Signup OTP banner' />
          <div className='img-yellow-ribbon' />
        </Card.Section>
        <Flex direction='column' align='center' justify='center' my={16}>
          <Title order={3} color='black' mb={16}>
            Upload Requirements
          </Title>
          <Grid w='100%'>
            <Grid.Col>
              <AtomDropzone
                onDrop={handleOnDropFile}
                onReject={handleOnRejectDropFile}
                fileTypes={[
                  MIME_TYPES.csv,
                  MIME_TYPES.doc,
                  MIME_TYPES.docx,
                  MIME_TYPES.pdf,
                  MIME_TYPES.ppt,
                  MIME_TYPES.pptx,
                  MIME_TYPES.xls,
                  MIME_TYPES.xlsx,
                  ...IMAGE_MIME_TYPE,
                ]}
                fileDescription='Select files to upload'
                hasDroppedFile={false}
                minHeight={0}
                onDropContent={null}
              />
              {showErr && (
                <Text
                  className='span-sub-text'
                  mt={10}
                  sx={{ color: 'red !important' }}
                >
                  Selected file is not valid.
                </Text>
              )}
            </Grid.Col>
            <Grid.Col>
              <Flex
                direction='column'
                gap={5}
                sx={{ height: '100%', maxHeight: '200px', overflowY: 'auto' }}
              >
                {extractedData.map((fileData) => (
                  <Flex
                    key={`signup-file-${fileData.fileId}`}
                    align='center'
                    justify='space-between'
                    className='purple-wrapper'
                    sx={{ marginTop: '0 !important' }}
                  >
                    <SendMoneyUploadedFile
                      fileName={fileData.fileName}
                      fileSize={fileData.fileSize}
                      onDelete={() => handleRemoveFile(fileData.fileId)}
                    />
                  </Flex>
                ))}
              </Flex>
            </Grid.Col>
            <Grid.Col
              mt={16}
              display='flex'
              sx={{ alignItems: 'center', justifyContent: 'center' }}
            >
              <Button
                className='defaultBtn'
                w={100}
                disabled={extractedData.length === 0}
                onClick={onNextStep}
              >
                Next
              </Button>
            </Grid.Col>
          </Grid>
        </Flex>
      </Card>
    </Modal>
  );
};

export default SignupFileUpload;
