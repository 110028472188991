import { useState } from 'react';
import { Flex } from '@mantine/core';
import { initialCreatePaymentFormData } from '../../../constants/payment';
import Banner from '../../atoms/banner/Banner';
import CreatePaymentStepper from './CreatePaymentStepper';
import CustomerDetails from './create/CustomerDetails';
import PaymentAmount from './create/PaymentAmount';
import ConfirmCreatePayment from './create/ConfirmCreatePayment';

const CreatePayment = ({ onClose }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [formData, setFormData] = useState(initialCreatePaymentFormData);
  const [confirmedCreatePayment, setConfirmedCreatePayment] = useState(false);

  const handleNextStep = (currFormData) => {
    setFormData({ ...formData, ...currFormData });
    setActiveStep(activeStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleConfirmCreatePayment = () => {
    setConfirmedCreatePayment(true);
  };

  const handleCloseConfirmedModal = () => {
    setFormData(initialCreatePaymentFormData);
    setActiveStep(1);
    setConfirmedCreatePayment(false);
    onClose();
  };

  const createPaymentContentMapper = [
    {
      step: 1,
      component: (
        <CustomerDetails
          formData={formData}
          onNextStep={handleNextStep}
          onCancel={handleCloseConfirmedModal}
        />
      ),
    },
    {
      step: 2,
      component: (
        <PaymentAmount
          formData={formData}
          onNextStep={handleNextStep}
          onPrevStep={handlePrevStep}
        />
      ),
    },
    {
      step: 3,
      component: (
        <ConfirmCreatePayment
          formData={formData}
          onPrevStep={handlePrevStep}
          onConfirm={handleConfirmCreatePayment}
        />
      ),
    },
  ];
  const findCurrCreatePaymentContent = createPaymentContentMapper.find(
    ({ step }) => step === activeStep
  );
  const activeCreatePaymentComponent = findCurrCreatePaymentContent
    ? findCurrCreatePaymentContent.component
    : null;

  return (
    <Flex direction='column' gap='md'>
      <CreatePaymentStepper activeStep={activeStep} />
      {activeCreatePaymentComponent}
      <Banner
        visible={confirmedCreatePayment}
        onClose={handleCloseConfirmedModal}
        title='Transaction Complete!'
        message='Payment with reference number XXXXXX has been successfully sent'
      />
    </Flex>
  );
};

export default CreatePayment;
