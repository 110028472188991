import AtomStepper from '../../atoms/stepper/Stepper';

const SendMoneyDomesticStepper = ({ activeStep }) => {
  return (
    <AtomStepper
      steps={['Send Money Details', 'Confirm Details']}
      activeStep={activeStep}
    />
  );
};

export default SendMoneyDomesticStepper;
