import {
  IconCreditCard,
  IconClockHour8,
  IconArrowsExchange,
  IconArrowBack,
  IconX,
} from '@tabler/icons-react';

export const PaymentIconMapper = (size) => {
  return {
    Paid: { icon: <IconCreditCard size={size} />, color: 'green' },
    Pending: { icon: <IconClockHour8 size={size} />, color: 'orange' },
    'For Refund': { icon: <IconArrowsExchange size={size} />, color: 'yellow' },
    Refunded: { icon: <IconArrowBack size={size} />, color: 'blue' },
    Cancelled: { icon: <IconX size={size} />, color: 'red' },
  };
};

export const PaymentStatuses = [
  { value: 'All', label: 'All' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Paid', label: 'Paid' },
  { value: 'Cancelled', label: 'Cancelled' },
  { value: 'For Refund', label: 'For Refund' },
  { value: 'Refunded', label: 'Refunded' },
];

export const initialCreatePaymentFormData = {
  firstName: '',
  middleName: '',
  lastName: '',
  mobileNo: '',
  email: '',
  orderNo: '',
  amount: 0,
  remarks: '',
};
