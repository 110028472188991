import * as yup from 'yup';

export const ChangePasswordSchema = yup.object().shape({
  oldPswd: yup.string().required('Old password is required'),
  newPswd: yup.string().required('New password is required'),
  conPswd: yup.string().oneOf([yup.ref('newPswd'), null], 'Passwords must match')
});

export const ProfileDetailsSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  middleName: yup.string().optional(),
  lastName: yup.string().required('Last name is required'),
  birtdate: yup.date().required('Birthdate is required'),
  gender: yup.string().required('Gender is required'),
  mobileNo: yup.string().required('Mobile number is required'),
  email: yup.string().required('Email address is required'),
});
