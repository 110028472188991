import { useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Flex } from '@mantine/core';
import { mockTransactionList } from '../../../mocks/transaction';
import DashboardCards from '../../organisms/dashboard/DashboardCards';
import DashboardList from '../../organisms/dashboard/DashboardList';
import DashboardListModal from '../../organisms/dashboard/DashboardListModal';

const DashboardPage = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [activeTransaction, setActiveTransaction] = useState(null);

  const handleSetActiveTransaction = (transaction) => {
    setActiveTransaction(transaction);
    open();
  };

  const handleCloseTransactionModal = () => {
    setActiveTransaction(null);
    close();
  };

  return (
    <Flex direction='column' gap='md'>
      <DashboardCards />
      <DashboardList
        transactions={mockTransactionList}
        setTransaction={handleSetActiveTransaction}
      />
      {activeTransaction && opened && (
        <DashboardListModal
          visible={opened}
          transaction={activeTransaction}
          onClose={handleCloseTransactionModal}
        />
      )}
    </Flex>
  );
};

export default DashboardPage;
