import { useState } from 'react';
import { Flex, Grid, Title, Image, Button, Text, rem } from '@mantine/core';
import { IconQrcode } from '@tabler/icons-react';
import bpiLogo from '../../../assets/images/bpi-logo.svg';
import chinaBankLogo from '../../../assets/images/chinabank-logo.svg';
import bdoLogo from '../../../assets/images/bdo-logo.svg';
import metroBankLogo from '../../../assets/images/metrobank-logo.svg';
import landbankLogo from '../../../assets/images/landbank-logo.svg';
import EnrollBankAccountModal from './EnrollBankAccountModal';
import GenerateQRModal from './GenerateQRModal';
import useStyles from '../../pages/add-fund/AddFund.styles';

const AddFundBanks = () => {
  const [enrollModalVisible, setEnrollModalVisibility] = useState(false);
  const [generateQRVisible, setGenerateQRVisibility] = useState(false);
  const { classes } = useStyles();

  return (
    <>
      <Grid w='100%' px={10}>
        <Grid.Col sm={6} sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Title order={4} className='page-h4-title'>
            Enrolled Bank Accounts
          </Title>
        </Grid.Col>
        <Grid.Col sm={6} className={classes.addFundActionsWrapper}>
          <Button
            leftIcon={<IconQrcode />}
            className='yellow-btn'
            onClick={() => setGenerateQRVisibility(true)}
          >
            Generate QR
          </Button>
        </Grid.Col>
        <Grid.Col className='white-wrapper'>
          <Flex direction='column' align='center' w='fit-content'>
            <Button
              component={Image}
              src={bpiLogo}
              width={60}
              height={60}
              alt='BPI bank'
              className={classes.addFundBankBtn}
            />
            <Text mt={10}>BPI</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col mt={16}>
          <Title order={4} className='page-h4-title'>
            Other Banks
          </Title>
        </Grid.Col>
        <Grid.Col
          className='white-wrapper'
          sx={{ display: 'flex', flexWrap: 'wrap', gap: rem(16), marginTop: 0 }}
        >
          <Flex direction='column' align='center' w='fit-content'>
            <Button
              component={Image}
              src={chinaBankLogo}
              width={60}
              height={60}
              alt='China Bank'
              className={classes.addFundBankBtn}
              onClick={() => setEnrollModalVisibility(true)}
            />
            <Text mt={10}>Chinabank</Text>
          </Flex>
          <Flex direction='column' align='center' w='fit-content'>
            <Button
              component={Image}
              src={bdoLogo}
              width={60}
              height={60}
              alt='BDO bank'
              className={classes.addFundBankBtn}
              onClick={() => setEnrollModalVisibility(true)}
            />
            <Text mt={10}>BDO</Text>
          </Flex>
          <Flex direction='column' align='center' w='fit-content'>
            <Button
              component={Image}
              src={metroBankLogo}
              width={60}
              height={60}
              alt='Metro Bank'
              className={classes.addFundBankBtn}
              onClick={() => setEnrollModalVisibility(true)}
            />
            <Text mt={10}>Metrobank</Text>
          </Flex>
          <Flex direction='column' align='center' w='fit-content'>
            <Button
              component={Image}
              src={landbankLogo}
              width={60}
              height={60}
              alt='Land Bank'
              className={classes.addFundBankBtn}
              onClick={() => setEnrollModalVisibility(true)}
            />
            <Text mt={10}>Landbank</Text>
          </Flex>
        </Grid.Col>
      </Grid>
      <EnrollBankAccountModal
        visible={enrollModalVisible}
        onClose={() => setEnrollModalVisibility(false)}
      />
      <GenerateQRModal
        visible={generateQRVisible}
        onClose={() => setGenerateQRVisibility(false)}
      />
    </>
  );
};

export default AddFundBanks;
