import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  addFundActionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.fn.smallerThan('sm')]: {
      justifyContent: 'center',
      button: {
        width: '100%',
      },
    },
  },
  addFundBankBtn: {
    background: 'none',
    height: '60px',
    padding: 0,
    width: 'unset',
    ':active': {
      background: 'none',
    },
    ':hover': {
      background: 'none',
    },
  },
}));

export default useStyles;
