import { useState } from 'react';
import { Flex } from '@mantine/core';
import { initialSendMoneyDomFormData } from '../../../constants/send-money';
import Banner from '../../atoms/banner/Banner';
import SendMoneyDomStepper from '../../organisms/send-domestic/SendMoneyDomStepper';
import SendMoneyDomDetails from '../../organisms/send-domestic/SendMoneyDomDetails';
import SendMoneyDomConfirm from '../../organisms/send-domestic/SendMoneyDomConfirm';

const SendMoneyDomesticPage = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [formData, setFormData] = useState(initialSendMoneyDomFormData);
  const [confirmedSendMoneyDom, setConfirmedSendMoneyDom] = useState(false);

  const handleNextStep = (currFormData) => {
    setFormData({ ...formData, ...currFormData });
    setActiveStep(activeStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleConfirmSendMoney = () => {
    setConfirmedSendMoneyDom(true);
  };

  const handleCloseConfirmedModal = () => {
    setFormData(initialSendMoneyDomFormData);
    setActiveStep(1);
    setConfirmedSendMoneyDom(false);
  };

  const sendDomContentMapper = [
    {
      step: 1,
      component: (
        <SendMoneyDomDetails formData={formData} onNextStep={handleNextStep} />
      ),
    },
    {
      step: 2,
      component: (
        <SendMoneyDomConfirm
          formData={formData}
          onPrevStep={handlePrevStep}
          onConfirm={handleConfirmSendMoney}
        />
      ),
    },
  ];

  const findCurrDomContent = sendDomContentMapper.find(
    ({ step }) => step === activeStep
  );
  const activeDomComponent = findCurrDomContent
    ? findCurrDomContent.component
    : null;

  return (
    <Flex direction='column' gap='md'>
      <SendMoneyDomStepper activeStep={activeStep} />
      {activeDomComponent}
      <Banner
        visible={confirmedSendMoneyDom}
        onClose={handleCloseConfirmedModal}
        title='Transaction Complete!'
        message='Your Domestic Send Money transaction XXXXXX
        has been successfully completed.'
      />
    </Flex>
  );
};

export default SendMoneyDomesticPage;
