import AtomStepper from '../../atoms/stepper/Stepper';

const CreatePaymentStepper = ({ activeStep }) => {
  return (
    <AtomStepper
      steps={['Customer Details', 'Payment Amount', 'Confirm Details']}
      activeStep={activeStep}
    />
  );
};

export default CreatePaymentStepper;
