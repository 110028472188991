import { createStyles, rem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  sendMoneyIntlFavBtn: {
    color: '#000000',
    padding: 0,
    ':hover': {
      background: 'none',
    },
  },
  sendMoneyIntlWallet: {
    backgroundColor: theme.colors.ubsPrimary[6],
    borderRadius: rem(8),
    marginTop: rem(16),
    padding: rem(16),
  },
}));

export default useStyles;
