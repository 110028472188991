import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  MediaQuery,
  Grid,
  Button,
  Card,
  Flex,
  Title,
  Image,
  Switch,
} from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { formatCurrency } from '../../../utils/currency';
import { getDateTime } from '../../../utils/date';
import { RoutePaths } from '../../../constants/routes';
import { mockWallet } from '../../../mocks/wallet';
import DashboardSendMoneyModal from './DashboardSendMoneyModal';
import phFlag from '../../../assets/images/ph-flag.svg';
import usFlag from '../../../assets/images/us-flag.svg';
import useStyles from '../../pages/dashboard/DashboardPage.styles';

const DashboardCard = ({
  title,
  titleSubTxt,
  isImgWidget,
  widget,
  subTitle,
  actions,
}) => {
  const { classes } = useStyles();

  return (
    <Card
      shadow='sm'
      padding='lg'
      radius='md'
      w={{ base: 300, md: '100%' }}
      withBorder
      className={!isImgWidget ? classes.dashboardCardColored : ''}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Flex
        direction='column'
        align='flex-start'
        justify='space-between'
        w='100%'
      >
        <Flex align='flex-start' justify='space-between' w='100%' h='50px'>
          <Flex direction='column'>
            <Title order={3}>{title}</Title>
            {titleSubTxt && (
              <span className='span-sub-text'>{titleSubTxt}</span>
            )}
          </Flex>
          {isImgWidget ? <Image src={widget} width={50} height={50} /> : widget}
        </Flex>
        <Flex direction='column' justify='flex-end' w='100%' h='70px'>
          {subTitle}
        </Flex>
        <Grid gutter={4} mt={16} w='100%'>
          {actions.map((action, actionIndex) => {
            const extraBtnProps = action.to
              ? { component: Link, to: action.to }
              : { onClick: action.onClick };
            return (
              <Grid.Col
                key={`dashboard-card-${action.label}-${actionIndex}`}
                span={action.width}
              >
                <Button tt='uppercase' fullWidth {...extraBtnProps}>
                  {action.label}
                </Button>
              </Grid.Col>
            );
          })}
        </Grid>
      </Flex>
    </Card>
  );
};

const dashboardColList = ({ showSendMoneyModal }) => {
  const { classes } = useStyles();
  const [isBuyToggle, setSwitchToggle] = useState(true);

  return [
    <DashboardCard
      title='PHP Wallet'
      titleSubTxt='Total Balance'
      isImgWidget
      widget={phFlag}
      subTitle={
        <Title order={4} className='page-h4-title'>
          {formatCurrency('PHP', mockWallet.available_balance)}
        </Title>
      }
      actions={[
        { label: 'Add Funds', to: RoutePaths.AddFund, width: 6 },
        {
          label: 'Send',
          onClick: showSendMoneyModal,
          width: 6,
        },
      ]}
    />,
    <DashboardCard
      title='USD Wallet'
      titleSubTxt='Total Balance'
      isImgWidget
      widget={usFlag}
      subTitle={
        <Title order={4} className='page-h4-title'>
          {formatCurrency('USD', mockWallet.available_balance)}
        </Title>
      }
      actions={[{ label: 'Send', to: RoutePaths.SendMoneyIntl, width: 6 }]}
    />,
    <DashboardCard
      title='Buy/Sell Forex'
      widget={
        <Switch
          checked={isBuyToggle}
          radius='sm'
          size='xl'
          onLabel='Buy'
          offLabel='Sell'
          className={classes.dashboardSwitch}
          onChange={(e) => setSwitchToggle(e.target.checked)}
        />
      }
      subTitle={
        <>
          <span className='span-sub-text'>
            {isBuyToggle ? 'Buying' : 'Selling'} Rate
          </span>
          <Title order={4} className='page-h4-title'>
            {isBuyToggle ? 'USD 1.00 = PHP 53.60' : 'PHP 56.60 = USD 1.00'}
          </Title>
          <span className='span-sub-text'>
            {getDateTime(mockWallet.create_date, 'LLL dd, yyyy - t')}
          </span>
        </>
      }
      actions={[
        {
          label: isBuyToggle ? 'Buy' : 'Sell',
          to: isBuyToggle ? RoutePaths.BuyForex : RoutePaths.SellForex,
          width: 12,
        },
      ]}
    />,
  ];
};

const DashboardCardGrid = (sendMoneyModalProps) => {
  const { classes } = useStyles();

  return (
    <MediaQuery smallerThan='lg' styles={{ display: 'none' }}>
      <Grid gutter={16} justify='center' className={classes.dashboardCardGrid}>
        {dashboardColList(sendMoneyModalProps).map((col, colIndex) => (
          <Grid.Col key={colIndex} md={4} sm={8}>
            {col}
          </Grid.Col>
        ))}
      </Grid>
    </MediaQuery>
  );
};

const DashboardCardCarousel = (sendMoneyModalProps) => {
  return (
    <MediaQuery largerThan='lg' styles={{ display: 'none' }}>
      <Carousel height={250} slideSize='50%' align='start' slideGap='md'>
        {dashboardColList(sendMoneyModalProps).map(
          (dashboardCol, dashboardColIndex) => (
            <Carousel.Slide key={dashboardColIndex}>
              {dashboardCol}
            </Carousel.Slide>
          )
        )}
      </Carousel>
    </MediaQuery>
  );
};

const DashboardCards = () => {
  const [showSendMoneyModal, setSendMoneyModalVisibility] = useState(false);

  return (
    <>
      <DashboardCardGrid
        showSendMoneyModal={() => setSendMoneyModalVisibility(true)}
      />
      <DashboardCardCarousel
        showSendMoneyModal={() => setSendMoneyModalVisibility(true)}
      />
      <DashboardSendMoneyModal
        visible={showSendMoneyModal}
        onClose={() => setSendMoneyModalVisibility(false)}
      />
    </>
  );
};

export default DashboardCards;
