export const mockLocationData = [
  {
    province: 'Metro Manila',
    cities: [
      {
        name: 'City Of Manila',
        zipCode: 2000,
        barangays: [
          'Barangay 523',
          'Barangay 524',
          'Barangay 525',
          'Barangay 526'
        ]
      }
    ]
  },
  {
    province: 'Zambales',
    cities: [
      {
        name: 'Cabangan',
        zipCode: 2203,
        barangays: [
          'Sta. Rita',
          'San Isidro',
          'San Rafael',
          'Dolores'
        ]
      }
    ]
  },
  {
    province: 'Pangasinan',
    cities: [
      {
        name: 'San Carlos',
        zipCode: 2420,
        barangays: [
          'Abanon',
          'Agdao',
          'Balaya',
          'Bacnar'
        ]
      }
    ]
  }
];
