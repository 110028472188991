import * as yup from 'yup';

export const GenerateReportSchema = yup.object().shape({
  reportType: yup.string().required('Report type is required'),
  dateFrom: yup.string().required('Date From is required'),
  dateTo: yup.string().required('Date To is required'),
  reportFileType: yup
    .string()
    .required('Please select either .xls or .pdf as file extension'),
});
