export const mockWallet = {
  id: '1',
  balance: '79063.60',
  hold: '15.00',
  limit: '0.00',
  total_debit: '6250.38',
  total_credit: '85313.98',
  last_debit_date: '2017-10-04',
  last_credit_date: '2017-10-02',
  create_date: '2017-10-02 15:45:48',
  update_date: '2017-10-04 11:35:23',
  lock_flag: '0',
  available_balance: 79048.6,
};
