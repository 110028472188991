import { useState } from 'react';
import {
  Flex,
  Container,
  ActionIcon,
  Button,
  Badge,
  Text,
} from '@mantine/core';
import {
  IconDotsVertical,
  IconEye,
  IconArrowsExchange,
} from '@tabler/icons-react';
import { getDateTime } from '../../../utils/date';
import { formatCurrency } from '../../../utils/currency';
import { PaymentIconMapper } from '../../../constants/payment';
import AtomTable from '../../atoms/table/Table';
import AtomPopover from '../../atoms/popover/Popover';
import RefundPayment from './modals/RefundPayment';

const PaymentsTable = ({ paymentData }) => {
  const [showRefund, setRefundVisibility] = useState(false);

  const tableRowData = paymentData.map((payment) => {
    const { icon, color } = PaymentIconMapper(16)[payment.status];
    return [
      { content: payment.reference_no },
      {
        content: (
          <Text sx={{ textWrap: 'wrap' }}>
            {`${payment.first_name} ${payment.middle_name} ${payment.last_name}`}
          </Text>
        ),
      },
      {
        content: formatCurrency('PHP', payment.amount),
        className: 'minusTd',
      },
      {
        content: (
          <Text sx={{ textWrap: 'wrap' }}>
            {getDateTime(payment.create_date, 'yyyy-LL-dd t')}
          </Text>
        ),
      },
      {
        content: (
          <Text sx={{ textWrap: 'wrap' }}>
            {getDateTime(payment.update_date, 'yyyy-LL-dd t')}
          </Text>
        ),
      },
      {
        content: (
          <Badge
            color={color}
            radius='sm'
            p={16}
            tt='capitalize'
            w={120}
            sx={{ fontSize: '13px', fontWeight: 500 }}
          >
            <Flex align='center' gap={5}>
              {icon} {payment.status}
            </Flex>
          </Badge>
        ),
      },
      {
        content: (
          <AtomPopover
            target={
              <ActionIcon color='dark' size='sm' variant='transparent'>
                <IconDotsVertical />
              </ActionIcon>
            }
            content={
              <Flex direction='column' align='flex-start'>
                <Button
                  leftIcon={<IconEye />}
                  size='xs'
                  color='dark'
                  variant='subtle'
                >
                  View
                </Button>
                <Button
                  leftIcon={<IconArrowsExchange />}
                  size='xs'
                  color='dark'
                  variant='subtle'
                  onClick={() => setRefundVisibility(true)}
                >
                  Refund
                </Button>
              </Flex>
            }
          />
        ),
      },
    ];
  });

  return (
    <>
      <Container className='page-wrapper'>
        <AtomTable
          headers={[
            'Reference No.',
            'Name',
            'Amount',
            'Date Created',
            'Date Updated',
            'Status',
            'Actions',
          ]}
          rowData={tableRowData}
        />
      </Container>
      <RefundPayment
        visible={showRefund}
        onClose={() => setRefundVisibility(false)}
      />
    </>
  );
};

export default PaymentsTable;
