import {
  Grid,
  Flex,
  Text,
  Button,
  Container,
  Select,
  TextInput,
  Avatar,
  Paper,
  Image,
} from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import { IMaskInput } from 'react-imask';
import { useForm, yupResolver } from '@mantine/form';
import { useEffect, useState } from 'react';
import { RoutePaths } from '../../../constants/routes';
import useStyles from '../../pages/bank-account/BankAccount.styles';
import { accountCards } from '../../../mocks/bank-account';
import { TransBankAccountSchema } from '../../../schema/mybank';
import Banner from '../../atoms/banner/Banner';
import AtomStepper from '../../atoms/stepper/Stepper';
import phFlagLogo from '../../../assets/images/ph-flag.svg';
import pesoNetLogo from '../../../assets/images/pesonet-logo.svg';

export default () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [showProcessedModal, setProcessedModalvisibility] = useState(false);
  const [active, setActive] = useState(1);
  const [bankAccount, setBankAccount] = useState({});

  const transferForm = useForm({
    validate: yupResolver(TransBankAccountSchema),
    initialValues: {
      bankAccount: '',
      amount: '',
    },
  });

  const nextStep = () =>
    setActive((current) => (current < 2 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const bankList = () =>
    accountCards.map((el) => ({ label: el.bankName, value: el.bankName }));

  const { onSubmit, getInputProps, values } = transferForm;

  useEffect(() => {
    if (values.bankAccount) {
      const findAccount = accountCards.find(
        (el) => el.bankName === values.bankAccount
      );
      setBankAccount({ ...findAccount, ...values });
    }
  }, [values]);

  const handleTransAccount = () => {
    nextStep();
  };

  return (
    <Flex direction='column' gap='sm'>
      <AtomStepper
        steps={['Transfer Details', 'Confirm Details']}
        activeStep={active}
      />
      <Container p='2rem' fluid className={classes.bankInfoWrapper}>
        <Banner
          visible={showProcessedModal}
          onClose={() => {
            setProcessedModalvisibility(false);
            navigate(`${RoutePaths.BankAccountMain}`);
          }}
          title='Success'
          message='Bank account has been successfully added.'
        />
        <form onSubmit={onSubmit(handleTransAccount)}>
          <Grid>
            <Grid.Col span={12}>
              <Flex justify='center' gap='0.1rem' align='center'>
                <Text c='gray.7' fw={600}>
                  Send Via
                </Text>
                <Image src={pesoNetLogo} width={100} />
              </Flex>
            </Grid.Col>
            <Grid.Col xs={12} md={6}>
              <Grid.Col span={12}>
                <Text fz='lg' fw={600}>
                  Bank Account
                </Text>
              </Grid.Col>
              {active === 1 ? (
                <>
                  <Grid.Col span={12}>
                    <Select
                      withAsterisk
                      clearable
                      label='Select Bank Account'
                      placeholder='Choose Bank'
                      searchable
                      nothingFound='No options'
                      data={bankList()}
                      {...getInputProps('bankAccount')}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <TextInput
                      label='Account Number'
                      placeholder='Account Number'
                      defaultValue={bankAccount.bankNum}
                      readOnly
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <TextInput
                      label='Account Name'
                      placeholder='Account Name'
                      defaultValue={bankAccount.fullName}
                      readOnly
                    />
                  </Grid.Col>
                </>
              ) : (
                <Paper radius={6} bg='ubsBtnExitColor.6' p='lg'>
                  <Flex gap='md' direction='column'>
                    <Flex justify='space-between'>
                      <Text fw={450}>Bank Account:</Text>
                      <Text fw={600}>{bankAccount.bankName}</Text>
                    </Flex>
                    <Flex justify='space-between'>
                      <Text fw={450}>Account Number:</Text>
                      <Text fw={600}>{bankAccount.bankNum}</Text>
                    </Flex>
                    <Flex justify='space-between'>
                      <Text fw={450}>Account Name:</Text>
                      <Text fw={600}>{bankAccount.fullName}</Text>
                    </Flex>
                  </Flex>
                </Paper>
              )}
            </Grid.Col>
            <Grid.Col xs={12} md={6}>
              <Grid.Col span={12}>
                <Text fz='lg' fw={600}>
                  Transaction Amount
                </Text>
              </Grid.Col>
              {active === 1 ? (
                <Grid.Col span={12}>
                  <TextInput
                    component={IMaskInput}
                    mask={Number}
                    radix='.'
                    withAsterisk
                    label='Amount (PHP)'
                    placeholder='Amount (PHP)'
                    {...getInputProps('amount')}
                  />
                </Grid.Col>
              ) : (
                <Paper radius={6} bg='ubsBtnExitColor.6' p='lg'>
                  <Flex justify='space-between' align='center'>
                    <Text fw={600} fz='sm'>
                      Total:
                    </Text>
                    <Text fw={600} fz='xl'>
                      PHP{' '}
                      {Number(bankAccount.amount)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </Text>
                  </Flex>
                </Paper>
              )}
              <Grid.Col span={12}>
                <Text fz='lg' fw={600}>
                  Transaction Wallet
                </Text>
              </Grid.Col>
              <Grid.Col span={12}>
                <Paper radius={6} bg='ubsBtnExitColor.6' p='lg'>
                  <Flex justify='space-between' align='center'>
                    <Flex justify='flex-start' align='center' gap='xs'>
                      <Avatar size='lg' radius='xl' src={phFlagLogo} alt='' />
                      <div>
                        <Text fz='sm'>PHP Wallet</Text>
                        <Text c='gray.7' fz='xs'>
                          Total Balance
                        </Text>
                      </div>
                    </Flex>
                    <Text fw={600}>PHP {bankAccount.wallet}</Text>
                  </Flex>
                </Paper>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              mt={16}
              display='flex'
              sx={(theme) => ({
                alignItems: 'center',
                gap: '5px',
                justifyContent: 'flex-end',
                [theme.fn.smallerThan('md')]: {
                  justifyContent: 'flex-start',
                },
              })}
            >
              {active === 1 ? (
                <>
                  <Button
                    color='ubsBtnExitColor.3'
                    c='black'
                    component={Link}
                    to={`${RoutePaths.BankAccountMain}`}
                  >
                    Cancel
                  </Button>
                  <Button className='yellow-btn' type='submit'>
                    Next
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => prevStep()}
                    color='ubsBtnExitColor.3'
                    c='black'
                  >
                    Back
                  </Button>
                  <Button
                    className='yellow-btn'
                    onClick={() => setProcessedModalvisibility(true)}
                  >
                    Submit
                  </Button>
                </>
              )}
            </Grid.Col>
          </Grid>
        </form>
      </Container>
    </Flex>
  );
};
