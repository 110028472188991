import {
  Modal,
  Card,
  Image,
  Flex,
  Title,
  Button,
  Text,
  Select,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { SignupDualAuthFormSchema } from '../../../../schema/sign-up';
import signupDualAuthBanner from '../../../../assets/images/signup-dual-auth-banner.svg';
import useStyles from '../../../pages/login/LoginPage.styles';

const dualAuthOptions = [
  { label: 'SMS', value: 'SMS' },
  { label: 'Authenticator', value: 'Authenticator' },
];

const SignUpDualAuth = ({ visible, onClose, onNextStep }) => {
  const authTypeForm = useForm({
    validate: yupResolver(SignupDualAuthFormSchema),
    initialValues: {
      authType: '',
    },
  });
  const { getInputProps, onSubmit } = authTypeForm;
  const { classes } = useStyles();

  return (
    <Modal
      opened={visible}
      onClose={onClose}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      padding={0}
      size='lg'
    >
      <Card>
        <Card.Section>
          <Image
            src={signupDualAuthBanner}
            height={200}
            alt='Signup dual authentication banner'
          />
          <div className='img-yellow-ribbon' />
        </Card.Section>
        <form onSubmit={onSubmit(onNextStep)}>
          <Flex direction='column' align='center' justify='center' my={16}>
            <Title order={2} color='black' ta='center'>
              Enable dual factory authentication
            </Title>
            <Text size='sm' color='ubsHighlight.9' fw={500} my={5}>
              What&apos;s This?
            </Text>
            <Select
              data={dualAuthOptions}
              variant='unstyled'
              w={300}
              placeholder='Check Authentication Type'
              className={classes.loginInput}
              clearable
              my={16}
              {...getInputProps('authType')}
            />
            <Button className='defaultBtn' w={100} mt={32} type='submit'>
              Send
            </Button>
          </Flex>
        </form>
      </Card>
    </Modal>
  );
};

export default SignUpDualAuth;
