import { Grid, Flex, Image, MediaQuery, BackgroundImage } from '@mantine/core';
import { RoutePaths } from '../../../constants/routes';
import authService from '../../../api/auth';
import LoginForm from '../../organisms/login/LoginForm';
import ubsLogoColumned from '../../../assets/images/ubs-logo-column.svg';
import loginBanner from '../../../assets/images/login-banner.svg';
import useStyles from './LoginPage.styles';

const LoginPage = () => {
  const { classes } = useStyles();

  const handleLogin = () => {
    authService.mockLogin();
    window.location.href = RoutePaths.Dashboard;
  };

  return (
    <Grid gutter={0} w='100%'>
      <Grid.Col xs={12} md={6}>
        <Flex
          direction='column'
          align='center'
          justify='center'
          className={classes.loginImgFormWrapper}
        >
          <MediaQuery largerThan='md' styles={{ display: 'none' }}>
            <BackgroundImage
              src={loginBanner}
              alt='UBS Login Banner'
              className={classes.loginMdFormBanner}
            />
          </MediaQuery>
          <Flex
            direction='column'
            align='center'
            justify='flex-start'
            h='100%'
            className={classes.loginFormWrapper}
          >
            <Image
              width={150}
              height={150}
              src={ubsLogoColumned}
              fit='contain'
              alt='UBS Logo Columned version'
            />
            <LoginForm onLogin={handleLogin} />
          </Flex>
        </Flex>
      </Grid.Col>
      <MediaQuery smallerThan='md' styles={{ display: 'none' }}>
        <Grid.Col xs={12} md={6}>
          <BackgroundImage
            src={loginBanner}
            alt='UBS Login Banner'
            sx={{ height: '100vh' }}
          />
        </Grid.Col>
      </MediaQuery>
    </Grid>
  );
};

export default LoginPage;
