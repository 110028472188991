import {
  MediaQuery,
  Burger,
  Flex,
  Text,
  Avatar,
  Group,
  Menu,
  Button,
  Indicator,
  Popover,
  Title,
  List,
  ThemeIcon,
  Grid,
} from '@mantine/core';
import {
  IconBell,
  IconUser,
  IconSettings,
  IconLogout,
  IconChevronDown,
  IconQrcode,
  IconSettingsCog,
} from '@tabler/icons-react';
import { useLocation, Link } from 'react-router-dom';
import { convertAbbrevFullDt, getTime } from '../../../utils/date';
import { RoutePaths } from '../../../constants/routes';
import { mockNotifications } from '../../../mocks/notification';
import useStyles from './PrivateLayout.styles';

const AppHeaderMenus = ({ onLogout }) => {
  const { classes } = useStyles();

  return (
    <Menu position='bottom-end' withArrow>
      <Menu.Target className={classes.menuTarget}>
        <Flex gap={8}>
          <Avatar radius='xl' color='ubsHighlight.9'>
            JD
          </Avatar>
          <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
            <Group>
              <Text>John Doe</Text>
              <IconChevronDown size='1.5em' />
            </Group>
          </MediaQuery>
        </Flex>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          className={classes.generateQrMenu}
          component={Link}
          icon={<IconQrcode size='1.5em' />}
          to={RoutePaths.GenerateQR}
        >
          Generate QR
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          icon={<IconUser size='1.5em' />}
          component={Link}
          to={RoutePaths.Profile}
        >
          My Account
        </Menu.Item>
        <Menu.Item
          component={Link}
          to={RoutePaths.Settings}
          icon={<IconSettings size='1.5em' />}
        >
          Settings
        </Menu.Item>
        <Menu.Item icon={<IconLogout size='1.5em' />} onClick={onLogout}>
          Sign Out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

const AppNotification = () => {
  const { classes } = useStyles();

  return (
    <Popover width={350} position='bottom-end' shadow='md'>
      <Popover.Target>
        <Indicator inline label={mockNotifications.length} size={16}>
          <IconBell className={classes.privateIcon} />
        </Indicator>
      </Popover.Target>
      <Popover.Dropdown>
        <Flex direction='column'>
          <Flex align='center' justify='space-between'>
            <Title order={5} fw={600} color='ubsDashboard.9'>
              Notifications
            </Title>
            <Button
              variant='subtle'
              radius='xs'
              size='xs'
              td='underline'
              className={classes.markAllReadBtn}
              compact
            >
              Mark all as read
            </Button>
          </Flex>
          <List
            mt={16}
            spacing='xs'
            center
            icon={
              <ThemeIcon size={50} color='ubsDashboard.2' radius='sm'>
                <IconSettingsCog size='1.5em' />
              </ThemeIcon>
            }
          >
            {mockNotifications.map((notif, notifIndex) => (
              <List.Item key={`notification-${notifIndex}`} fs={16}>
                <Grid>
                  <Grid.Col span={8}>
                    <Flex
                      direction='column'
                      align='flex-start'
                      justify='center'
                    >
                      <Title order={6} fw={500}>
                        System Advisory
                      </Title>
                      <Text sx={{ fontSize: 10 }}>{notif.message}</Text>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={4} ta='end'>
                    <Text sx={{ fontSize: 10 }}>
                      {convertAbbrevFullDt(notif.create_date)}
                    </Text>
                    <Text sx={{ fontSize: 10 }}>
                      {getTime(notif.create_date)}
                    </Text>
                  </Grid.Col>
                </Grid>
              </List.Item>
            ))}
          </List>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

const AppHeader = ({ onShowNavbar, onLogout }) => {
  const { classes } = useStyles();

  const fetchRouteName = () => {
    let routeName = '';
    const { pathname } = useLocation();
    const routeNames = pathname.replace(/^\/|-/g, ' ').split('/');
    if (routeNames.length === 2) {
      routeName = (
        <>
          <span className={classes.appbarRootLinkTxt}>{routeNames[0]}</span> /{' '}
          <span>{routeNames[1]}</span>
        </>
      );
    } else {
      routeName = routeNames[0];
    }

    return routeName;
  };

  return (
    <Flex align='center' justify='space-between'>
      <MediaQuery largerThan='xs' styles={{ display: 'none' }}>
        <Burger opened={false} onClick={onShowNavbar} size='sm' mr='xl' />
      </MediaQuery>
      <Grid align='center' justify='space-between' w='100%'>
        <Grid.Col span={7}>
          <Text
            truncate
            sx={{
              fontSize: 18,
              fontWeight: 400,
              color: '#2A2A2A',
              textTransform: 'capitalize',
            }}
          >
            {fetchRouteName()}
          </Text>
        </Grid.Col>
        <Grid.Col span={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Group>
            <AppNotification />
            <AppHeaderMenus onLogout={onLogout} />
          </Group>
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

export default AppHeader;
