import {
  Modal,
  Text,
  Grid,
  Flex,
  TextInput,
  Radio,
  Textarea,
  Button,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { CreateRefundFormSchema } from '../../../../schema/payment';

const RefundPayment = ({ visible, onClose }) => {
  const refundForm = useForm({
    validate: yupResolver(CreateRefundFormSchema),
    initialValues: {
      refundAmount: 0,
      chargeTo: '',
      remarks: '',
    },
  });
  const { getInputProps, reset, onSubmit } = refundForm;

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSubmit = () => {
    handleClose();
  };

  return (
    <Modal
      opened={visible}
      centered
      size='lg'
      onClose={handleClose}
      title={
        <Text
          component='span'
          className='page-h4-title'
          sx={{ color: '#FFFFFF !important' }}
        >
          Create Refund
        </Text>
      }
      className='purple-modal'
      styles={{
        header: { padding: '1rem !important' },
        content: { borderRadius: 10 },
        body: {
          paddingTop: '0.5rem !important',
        },
      }}
    >
      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid w='100%' sx={{ margin: 0 }}>
          <Grid.Col sm={6}>
            <Radio.Group {...getInputProps('chargeTo')} label='Charge To'>
              <Flex align='center' justify='flex-start' mt={5} mb={15} gap={20}>
                <Radio size='sm' value='S' label='Seller' />
                <Radio size='sm' value='C' label='Customer' />
              </Flex>
            </Radio.Group>
          </Grid.Col>
          <Grid.Col sm={6}>
            <TextInput
              type='number'
              label='Refund Amount'
              placeholder='Refund Amount'
              {...getInputProps('refundAmount')}
            />
          </Grid.Col>
          <Grid.Col>
            <Textarea
              label='Remarks'
              autosize
              minRows={2}
              {...getInputProps('remarks')}
            />
          </Grid.Col>
          <Grid.Col sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type='submit' className='yellow-btn'>
              Create
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};

export default RefundPayment;
