import * as yup from 'yup';

export const CreateCustomerDetailSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  middleName: yup.string().required('Middle name is required'),
  lastName: yup.string().required('Last name is required'),
  mobileNo: yup.string().required('Mobile number is required'),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
});

export const CreatePaymentAmountSchema = yup.object().shape({
  orderNo: yup.string().required('Order number is required'),
  amount: yup
    .number()
    .required('Amount is required')
    .min(1, 'Amount should be minimum of 1'),
  remarks: yup.string(),
});

export const CreateRefundFormSchema = yup.object().shape({
  refundAmount: yup
    .number()
    .min(1, 'Refund amount should be a valid amount')
    .required('Refund amount is required'),
  chargeTo: yup.string().required('Please choose charge destination'),
  remarks: yup.string(),
});
