import { Flex, Grid, Title, Button, Divider } from '@mantine/core';
import { formatCurrency } from '../../../utils/currency';
import { exchangeRate } from '../../../mocks/send-money';
import AtomGridDetails from '../../atoms/grid-details/GridDetails';
import { SendMoneyUploadedFile } from './SendMoneyDetails';
import {
  SendMoneyFundWalletBtn,
  SendMoneyTransAmountBreakdown,
} from './SendMoneyTransAmount';
import phFlag from '../../../assets/images/ph-flag.svg';
import usFlag from '../../../assets/images/us-flag.svg';

const SendMoneyConfirm = ({ formData, onPrevStep, onConfirm }) => {
  let senderDetails = [
    {
      label: 'Company Name',
      value: formData.companyName,
    },
    {
      label: 'Mobile Number',
      value: formData.mobileNo,
    },
    {
      label: 'Full Name of Sender',
      value: formData.senderFullName,
    },
  ];
  if (formData.bankType === 'Individual') {
    senderDetails = [
      {
        label: 'First Name',
        value: formData.firstName,
      },
      {
        label: 'Middle Name',
        value: formData.middleName,
      },
      {
        label: 'Last Name',
        value: formData.lastName,
      },
      {
        label: 'Mobile Number',
        value: formData.mobileNo,
      },
      {
        label: 'Full Name of Sender',
        value: formData.senderFullName,
      },
    ];
  }

  return (
    <Flex direction='column' className='white-wrapper'>
      <Grid w='100%'>
        <Grid.Col sm={6}>
          <Title order={4} className='page-h4-title'>
            Beneficiary Details
          </Title>
          <Flex
            direction='column'
            justify='space-between'
            className='purple-wrapper'
          >
            <AtomGridDetails
              details={[
                {
                  label: 'Country of Origin',
                  value: formData.countryOrigin,
                },
                {
                  label: 'Country Code',
                  value: formData.countryCode,
                },
                {
                  label: 'Selected Bank',
                  value: formData.bank,
                },
                {
                  label: 'IBAN',
                  value: formData.iban,
                },
                {
                  label: 'Type',
                  value: formData.bankType,
                },
              ]}
            />
            <Divider my={32} />
            <AtomGridDetails details={senderDetails} />
            <Divider my={32} />
            <AtomGridDetails
              details={[
                {
                  label: 'Purpose of Sending',
                  value: formData.purpose,
                },
                {
                  label: 'Source of Funds',
                  value: formData.fundSource,
                },
                {
                  label: 'Relationship to Sender',
                  value: formData.relationship,
                },
              ]}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col sm={6}>
          <Grid w='100%'>
            <Grid.Col>
              <Title order={4} className='page-h4-title'>
                Uploaded File
              </Title>
              <Flex
                align='center'
                justify='space-between'
                className='purple-wrapper'
              >
                <SendMoneyUploadedFile
                  fileName={formData.uploadedFile.fileName}
                  fileSize={formData.uploadedFile.fileSize}
                />
              </Flex>
            </Grid.Col>
            <Grid.Col>
              <Title order={4} className='page-h4-title'>
                Transaction Wallet
              </Title>
              <SendMoneyFundWalletBtn
                label={`${formData.walletFund} Wallet`}
                value={formatCurrency(
                  formData.walletFund,
                  formData.currencyWalletFund
                )}
                flagImg={formData.walletFund === 'PHP' ? phFlag : usFlag}
                isSelected={false}
              />
            </Grid.Col>
            <Grid.Col>
              <Title order={4} className='page-h4-title'>
                Transaction Amount
              </Title>
              <SendMoneyTransAmountBreakdown
                currencyExchange={`${formData.walletFund} ${
                  exchangeRate[formData.walletFund]
                }`}
                currency={formData.walletFund}
                fee={formatCurrency(formData.walletFund, formData.currencyFee)}
                equivalent={formatCurrency(
                  formData.walletFund,
                  formData.equivalent
                )}
                total={formatCurrency(formData.walletFund, formData.total)}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col
          mt={16}
          display='flex'
          sx={(theme) => ({
            alignItems: 'center',
            gap: '5px',
            justifyContent: 'flex-end',
            [theme.fn.smallerThan('md')]: {
              justifyContent: 'flex-start',
            },
          })}
        >
          <Button color='ubsBtnExitColor.3' c='black' onClick={onPrevStep}>
            Back
          </Button>
          <Button onClick={onConfirm} className='yellow-btn'>
            Confirm
          </Button>
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

export default SendMoneyConfirm;
