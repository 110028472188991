import { useState, useEffect } from 'react';
import {
  Flex,
  Grid,
  Title,
  Text,
  Button,
  Select,
  TextInput,
  Radio,
  MediaQuery,
  Image,
  ActionIcon,
} from '@mantine/core';
import { IconHeart, IconSquareX } from '@tabler/icons-react';
import { useForm, yupResolver } from '@mantine/form';
import { IMAGE_MIME_TYPE } from '@mantine/dropzone';
import {
  SendMoneyIntlDetailsSchema,
  SendMoneyIntlIndiDetailsSchema,
} from '../../../schema/send-money';
import { bytesToSize } from '../../../utils/file';
import {
  bankOptions,
  sendPurposeOptions,
  sendRelationshipOptions,
  originCountryOptions,
} from '../../../constants/send-money';
import AtomDropzone from '../../atoms/dropzone/Dropzone';
import SendMoneyFavoritesModal from './SendMoneyFavoritesModal';
import uploadedCSVFile from '../../../assets/images/uploaded-csv.svg';
import useStyles from '../../pages/send-money-intl/SendMoneyIntlPage.styles';

export const SendMoneyUploadedFile = ({ fileName, fileSize, onDelete }) => {
  return (
    <Grid w='100%'>
      <Grid.Col span={onDelete ? 6 : 12}>
        <Flex gap='md'>
          <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
            <Image
              src={uploadedCSVFile}
              width={50}
              height={60}
              alt='Uploaded file'
            />
          </MediaQuery>
          <Grid w='100%' gutter={5}>
            <Grid.Col>
              <Text truncate>{fileName}</Text>
            </Grid.Col>
            <Grid.Col>
              <Text className='span-sub-text'>Completed - {fileSize}</Text>
            </Grid.Col>
          </Grid>
        </Flex>
      </Grid.Col>
      {onDelete && (
        <Grid.Col
          span={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <ActionIcon color='red' onClick={onDelete}>
            <IconSquareX />
          </ActionIcon>
        </Grid.Col>
      )}
    </Grid>
  );
};

const SendMoneyDetailsForm = ({ formData, handleSubmit }) => {
  const [extractedData, setExtractedData] = useState(
    formData.uploadedFile || []
  );
  const [formSchema, setFormSchema] = useState(SendMoneyIntlDetailsSchema);
  const sendMoneyIntlDetailsForm = useForm({
    validate: yupResolver(formSchema),
    initialValues: formData,
  });
  const { onSubmit, setErrors, setFieldValue, getInputProps, errors, values } =
    sendMoneyIntlDetailsForm;
  const hasDroppedFile = !!extractedData.length;
  const isBusinessBankType = values.bankType === 'Business';

  useEffect(() => {
    if (isBusinessBankType) {
      setFormSchema(SendMoneyIntlDetailsSchema);
    } else {
      setFormSchema(SendMoneyIntlIndiDetailsSchema);
    }
  }, [values.bankType]);

  const handleOnDropFile = (files) => {
    const dropzoneFileData = [];
    let fileId = extractedData.length;
    if (files.length) {
      files.forEach((file) => {
        fileId += 1;
        const fileName = file.path;
        const fileSize = bytesToSize(file.size);
        dropzoneFileData.push({ fileId, fileName, fileSize, data: [] });
      });
    }
    setExtractedData([...extractedData, ...dropzoneFileData]);
    setFieldValue('uploadedFile', dropzoneFileData);
  };

  const handleOnRejectDropFile = () => {
    setErrors({
      uploadedFile:
        'File selected is invalid! Please select a valid excel file.',
    });
  };

  const handleRemoveFile = (fileId) => {
    const remainingFiles = extractedData.filter(
      (file) => file.fileId !== fileId
    );
    setExtractedData(remainingFiles);
    setFieldValue('uploadedFile', remainingFiles);
  };

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <Grid w='100%'>
        <Grid.Col sm={6}>
          <Select
            label='Country of Origin'
            data={originCountryOptions}
            clearable
            {...getInputProps('countryOrigin')}
          />
        </Grid.Col>
        <Grid.Col sm={6}>
          <TextInput label='Country Code' {...getInputProps('countryCode')} />
        </Grid.Col>
        <Grid.Col sm={6}>
          <Select
            label='Select Bank'
            data={bankOptions}
            clearable
            {...getInputProps('bank')}
          />
        </Grid.Col>
        <Grid.Col sm={6}>
          <TextInput label='IBAN' {...getInputProps('iban')} />
        </Grid.Col>
        <Grid.Col span={6}>
          <Radio.Group {...getInputProps('bankType')}>
            <Grid align='center' justify='space-between' w='100%'>
              <Grid.Col sm={6} xs={12}>
                <Radio size='md' value='Individual' label='Individual' />
              </Grid.Col>
              <Grid.Col sm={6} xs={12}>
                <Radio size='md' value='Business' label='Business' />
              </Grid.Col>
            </Grid>
          </Radio.Group>
        </Grid.Col>
        <Grid.Col span={6}>
          <span />
        </Grid.Col>
        {isBusinessBankType ? (
          <>
            <Grid.Col sm={4}>
              <TextInput
                label='Company Name'
                {...getInputProps('companyName')}
              />
            </Grid.Col>
            <Grid.Col sm={4}>
              <TextInput label='Mobile Number' {...getInputProps('mobileNo')} />
            </Grid.Col>
            <Grid.Col sm={4}>
              <TextInput
                label='Sender Full Name'
                disabled
                {...getInputProps('senderFullName')}
              />
            </Grid.Col>
          </>
        ) : (
          <>
            <Grid.Col sm={4}>
              <TextInput label='First Name' {...getInputProps('firstName')} />
            </Grid.Col>
            <Grid.Col sm={4}>
              <TextInput label='Middle Name' {...getInputProps('middleName')} />
            </Grid.Col>
            <Grid.Col sm={4}>
              <TextInput label='Last Name' {...getInputProps('lastName')} />
            </Grid.Col>
            <Grid.Col sm={4}>
              <TextInput label='Mobile Number' {...getInputProps('mobileNo')} />
            </Grid.Col>
            <Grid.Col sm={4}>
              <TextInput
                label='Sender Full Name'
                disabled
                {...getInputProps('senderFullName')}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <span />
            </Grid.Col>
          </>
        )}
        <Grid.Col mt={16}>
          <Title order={4} className='page-h4-title'>
            International Transfer Information
          </Title>
        </Grid.Col>
        <Grid.Col sm={4}>
          <Select
            label='Purpose of Sending'
            data={sendPurposeOptions}
            clearable
            {...getInputProps('purpose')}
          />
        </Grid.Col>
        <Grid.Col sm={4}>
          <TextInput label='Source of Funds' {...getInputProps('fundSource')} />
        </Grid.Col>
        <Grid.Col sm={4}>
          <Select
            label='Relationship to Sender'
            data={sendRelationshipOptions}
            clearable
            {...getInputProps('relationship')}
          />
        </Grid.Col>
        <Grid.Col sm={6} mt={16}>
          <Grid w='100%'>
            <Grid.Col>
              <Title order={4} className='page-h4-title' truncate>
                International Transfer Information
              </Title>
            </Grid.Col>
            <Grid.Col>
              <AtomDropzone
                onDrop={handleOnDropFile}
                onReject={handleOnRejectDropFile}
                fileTypes={IMAGE_MIME_TYPE}
                fileDescription='Select image to upload'
                hasDroppedFile={false}
                minHeight={0}
                onDropContent={null}
              />
            </Grid.Col>
          </Grid>
          {errors.uploadedFile && (
            <Text
              className='span-sub-text'
              mt={5}
              sx={{ color: 'red !important' }}
            >
              {errors.uploadedFile}
            </Text>
          )}
        </Grid.Col>
        {hasDroppedFile && (
          <Grid.Col sm={6} mt={16}>
            <Grid w='100%'>
              <Grid.Col>
                <Title order={4} className='page-h4-title'>
                  Uploaded File
                </Title>
              </Grid.Col>
              <Grid.Col
                display='flex'
                sx={{ flexDirection: 'column', gap: 10 }}
              >
                {extractedData.map((fileData) => (
                  <Flex
                    key={`send-money-intl-file-${fileData.fileId}`}
                    align='center'
                    justify='space-between'
                    className='purple-wrapper'
                    sx={{ marginTop: '0 !important' }}
                  >
                    <SendMoneyUploadedFile
                      fileName={fileData.fileName}
                      fileSize={fileData.fileSize}
                      onDelete={() => handleRemoveFile(fileData.fileId)}
                    />
                  </Flex>
                ))}
              </Grid.Col>
            </Grid>
          </Grid.Col>
        )}
        <Grid.Col
          mt={16}
          display='flex'
          sx={(theme) => ({
            alignItems: 'center',
            justifyContent: 'flex-end',
            [theme.fn.smallerThan('md')]: {
              justifyContent: 'flex-start',
            },
          })}
        >
          <Button type='submit' className='yellow-btn'>
            Next
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

const SendMoneyDetails = ({ formData, onNextStep }) => {
  const [showFavorites, setFavoritesVisibility] = useState(false);
  const { classes } = useStyles();

  const handleSubmit = (data) => {
    onNextStep(data);
  };

  return (
    <Flex direction='column' className='white-wrapper'>
      <Grid w='100%' mb={16}>
        <Grid.Col
          sm={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '10px',
          }}
        >
          <Title order={4} className='page-h4-title'>
            Beneficiary Details
          </Title>
          <Text className='span-sub-text'>
            Employee salaries will be deducted from this wallet
          </Text>
        </Grid.Col>
        <Grid.Col
          sm={6}
          display='flex'
          sx={(theme) => ({
            alignItems: 'center',
            justifyContent: 'flex-end',
            [theme.fn.smallerThan('md')]: {
              justifyContent: 'flex-start',
            },
          })}
        >
          <Button
            leftIcon={<IconHeart color='red' />}
            variant='subtle'
            className={classes.sendMoneyIntlFavBtn}
            onClick={() => setFavoritesVisibility(true)}
          >
            Show Favorites
          </Button>
        </Grid.Col>
      </Grid>
      <SendMoneyDetailsForm formData={formData} handleSubmit={handleSubmit} />
      <SendMoneyFavoritesModal
        visible={showFavorites}
        onClose={() => setFavoritesVisibility(false)}
      />
    </Flex>
  );
};

export default SendMoneyDetails;
