import { Dropzone } from '@mantine/dropzone';
import { Group, Flex, Image, Text } from '@mantine/core';
import uploadFile from '../../../assets/images/upload-file.svg';

const AtomDropzone = ({
  onDrop,
  onReject,
  fileTypes,
  fileDescription,
  hasDroppedFile,
  minHeight,
  onDropContent,
}) => {
  return (
    <Dropzone
      onDrop={onDrop}
      onReject={onReject}
      accept={fileTypes}
      activateOnClick={!hasDroppedFile}
      styles={{
        root: {
          ':hover': {
            backgroundColor: '#FFFFFF',
          },
        },
        inner: { pointerEvents: 'all' },
      }}
    >
      <Group position='center' spacing='xl' style={{ minHeight }}>
        {hasDroppedFile ? (
          onDropContent
        ) : (
          <Flex direction='column' align='center' justify='center'>
            <Image src={uploadFile} width={50} height={60} alt='Upload file' />
            <Text size='lg' inline mt={20}>
              {fileDescription}
            </Text>
            <Text size='sm' color='dimmed' inline mt={10}>
              Drag and drop file or click to Browse
            </Text>
          </Flex>
        )}
      </Group>
    </Dropzone>
  );
};

export default AtomDropzone;
