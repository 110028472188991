import { useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { AppShell, Navbar, Header, ScrollArea } from '@mantine/core';
import authService from '../../../api/auth';
import { verifyAuth } from '../../../utils/auth';
import { RoutePaths } from '../../../constants/routes';
import { privateMenu } from '../../../mocks/menu';
import NavbarLogo from './navbarLogo';
import NavbarLinks from './navbarLinks';
import NavbarContact from './navbarContact';
import AppHeader from './appHeader';
import useStyles from './PrivateLayout.styles';

export const PrivateLayout = () => {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles();
  const isAuth = verifyAuth();

  const handleLogout = () => {
    authService.mockLogout();
    window.location.href = RoutePaths.Root;
  };

  const layout = (
    <AppShell
      navbarOffsetBreakpoint='xs'
      asideOffsetBreakpoint='xs'
      layout='alt'
      className={classes.privateAppShell}
      navbar={
        <Navbar
          p='lg'
          hiddenBreakpoint='xs'
          hidden={!opened}
          width={{ xs: 200, sm: 220, md: 300 }}
        >
          <Navbar.Section>
            <NavbarLogo onHideNavbar={() => setOpened(false)} />
          </Navbar.Section>
          <Navbar.Section
            grow
            component={ScrollArea}
            mt={32}
            sx={{ overflow: 'initial' }}
          >
            <NavbarLinks
              dataLinks={privateMenu}
              onHideNavbar={() => setOpened(false)}
            />
          </Navbar.Section>
          <Navbar.Section>
            <NavbarContact />
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={70} p='md'>
          <AppHeader
            onShowNavbar={() => setOpened(true)}
            onLogout={handleLogout}
          />
        </Header>
      }
    >
      <Outlet />
    </AppShell>
  );

  return isAuth ? layout : <Navigate to={RoutePaths.Root} />;
};

export default PrivateLayout;
