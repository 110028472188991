import { Modal, Flex, Text, List, Button } from '@mantine/core';

const EnrollBankAccountModal = ({ visible, onClose }) => {
  return (
    <Modal
      opened={visible}
      centered
      size={600}
      onClose={onClose}
      withCloseButton={false}
      title={
        <Text component='span' className='page-h4-title'>
          Through Online Banking
        </Text>
      }
    >
      <Flex direction='column' p={16}>
        <List type='ordered'>
          <List.Item>Login to your online banking app of choice.</List.Item>
          <List.Item>
            <>
              <Text>
                Click{' '}
                <Text component='span' fw={600}>
                  Send Money.
                </Text>
              </Text>
              <Text>
                Select To{' '}
                <Text component='span' fw={600}>
                  Another Local Bank.
                </Text>
              </Text>
            </>
          </List.Item>
          <List.Item>
            <Text>
              Select destination bank:{' '}
              <Text component='span' fw={600}>
                USSC Money Services
              </Text>
            </Text>
          </List.Item>
          <List.Item>
            <>
              <Text>Fill in all required details.</Text>
              <Text>
                For the Destination Account Number field, input the correct
              </Text>
              <Text component='span' fw={600}>
                U Card number or U Mobile App registered mobile number.
              </Text>
            </>
          </List.Item>
          <List.Item>
            <Text>
              Review all the details and ensure that the U Card number or U
              Mobile App registered mobile number is correct.
            </Text>
          </List.Item>
          <List.Item>
            <Text>
              Click{' '}
              <Text component='span' fw={600}>
                Confirm
              </Text>{' '}
              to proceed with the transaction.
            </Text>
          </List.Item>
        </List>
        <Text className='span-sub-text' my={16}>
          NOTE: Transfer fees may vary depending on the originating bank.
        </Text>
        <Button className='yellow-btn' mt={16} w={200} onClick={onClose}>
          Okay
        </Button>
      </Flex>
    </Modal>
  );
};

export default EnrollBankAccountModal;
