import { Box, Title, Text, Button, Dialog, Group, TextInput, ActionIcon } from '@mantine/core';
import { IconMessageDots, IconMoodSmile, IconPaperclip } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import useStyles from './PrivateLayout.styles';

const NavbarContact = () => {
  const { classes } = useStyles();
  const [opened, { toggle, close }] = useDisclosure(false);

  return (
    <Box className={classes.navbarContactWrapper}>
      <Title order={6} color='ubsDashboard.9'>
        Need Help?
      </Title>
      <Text sx={{ fontSize: 12 }} mt={16}>
        Contact customer service:
      </Text>
      <Text sx={{ fontSize: 12 }}>8928-USSC (8772)</Text>
      <Text sx={{ fontSize: 12 }} mt={16}>
        9:00 AM - 6:00 PM daily
      </Text>
      <Button onClick={toggle} leftIcon={<IconMessageDots />} my={32} fullWidth>
        Help Chat
      </Button>
      <Text sx={{ fontSize: 10 }}>
        All Rights Reserved © U Business Solutions 2023.
      </Text>
      <Dialog pl={0} pr={0} pb="1rem" pt="1rem" bg="ubsDashboard.1" opened={opened} withCloseButton onClose={close} size="lg" radius="md">
        <Box ml="1rem" mr="1rem">
          <Text size="md" mb="xs" weight={700}>
            Customer Service
          </Text>
          <Text size="xs" mb="xs" weight={500}>
            Typically replies wihtin 5 minutes
          </Text>
        </Box>

        <Box mt="10px" mb="10px" bg="white" w="full" h={300} />
        <Box ml="1rem" mr="1rem">
          <Group>
            <TextInput radius="lg" placeholder="type here" sx={{ flex: 1 }} />
            <ActionIcon>
              <IconMoodSmile size="1.125rem" />
            </ActionIcon>
            <ActionIcon>
              <IconPaperclip size="1.125rem" />
            </ActionIcon>
          </Group>
        </Box>
      </Dialog>
    </Box>
  );
};

export default NavbarContact;
