import { httpClient } from './base/httpClient';

const authService = {
  sampleGetApi: async (url) => {
    const response = await httpClient.get(url);
    return response;
  },
  login: async (url, { data }) => {
    const response = await httpClient.post(url, data);
    return response;
  },
  mockLogin: () => {
    localStorage.setItem('isAuth', '1');
  },
  mockLogout: () => {
    localStorage.clear();
  },
};

export default authService;
