import { Grid, Title, Flex, Text, Image } from '@mantine/core';
import { formatCurrency } from '../../../../utils/currency';
import { DownloadBtn } from '../DashboardListModal';
import AtomFlagWallet from '../../../atoms/flag-wallet/FlagWallet';
import AtomGridDetails from '../../../atoms/grid-details/GridDetails';
import phFlag from '../../../../assets/images/ph-flag.svg';
import instaPayLogo from '../../../../assets/images/instapay-logo.svg';
import useStyles from '../../../pages/dashboard/DashboardPage.styles';

const SendMoneyDomesticContent = ({ transaction }) => {
  const { classes } = useStyles();

  return (
    <Flex direction='column' align='center' w='100%'>
      <Grid justify='space-between' w='100%'>
        <Grid.Col md={6}>
          <Title order={3} className={classes.modalContentTitle}>
            Recipient Details
          </Title>
          <Flex
            direction='column'
            justify='space-between'
            h={{ md: '16.5rem', sm: 'auto' }}
            className='purple-wrapper'
          >
            <AtomGridDetails
              details={[
                { label: 'Bank Name', value: transaction.bank },
                { label: 'Account Number', value: transaction.accountNo },
                { label: 'Account Name', value: transaction.accountName },
              ]}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col md={6}>
          <Title order={3} className={classes.modalContentTitle}>
            Transaction Wallet
          </Title>
          <Flex justify='space-between' className='purple-wrapper'>
            <AtomFlagWallet
              label='PHP Wallet'
              value={formatCurrency('PHP', transaction.wallet)}
              flagImg={phFlag}
            />
          </Flex>
          <Title order={3} mt={16} className={classes.modalContentTitle}>
            Transaction Amount
          </Title>
          <Flex
            direction='column'
            justify='space-between'
            className='purple-wrapper'
          >
            <AtomGridDetails
              details={[
                {
                  label: 'Payment Sent',
                  value: formatCurrency('PHP', transaction.transferAmount),
                },
                {
                  label: 'Send Fee',
                  value: formatCurrency('PHP', transaction.transferFee),
                },
                {
                  label: 'Total',
                  value: formatCurrency('PHP', transaction.wallet),
                  isBold: true,
                },
              ]}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col
          sm={6}
          mt={16}
          display='flex'
          sx={{ justifyContent: 'flex-start' }}
        >
          <Flex align='center'>
            <Text mr={10}>Sent via</Text>
            <Image src={instaPayLogo} width={150} height={39} />
          </Flex>
        </Grid.Col>
        <Grid.Col
          sm={6}
          mt={16}
          display='flex'
          sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <DownloadBtn />
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

export default SendMoneyDomesticContent;
