import { useState } from 'react';
import { Flex } from '@mantine/core';
import { initialSellForexFormData } from '../../../constants/forex';
import Banner from '../../atoms/banner/Banner';
import SellForexStepper from '../../organisms/sell-forex/SellForexStepper';
import SellForexDetails from '../../organisms/sell-forex/SellForexDetails';
import SellForexConfirm from '../../organisms/sell-forex/SellForexConfirm';

const SellForexPage = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [formData, setFormData] = useState(initialSellForexFormData);
  const [confirmedSellForex, setConfirmedSellForex] = useState(false);

  const handleNextStep = (currFormData) => {
    setFormData({ ...formData, ...currFormData });
    setActiveStep(activeStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleConfirmSellForex = () => {
    setConfirmedSellForex(true);
  };

  const handleCloseConfirmedModal = () => {
    setFormData(initialSellForexFormData);
    setActiveStep(1);
    setConfirmedSellForex(false);
  };

  const sellForexContentMapper = [
    {
      step: 1,
      component: (
        <SellForexDetails formData={formData} onNextStep={handleNextStep} />
      ),
    },
    {
      step: 2,
      component: (
        <SellForexConfirm
          formData={formData}
          onPrevStep={handlePrevStep}
          onConfirm={handleConfirmSellForex}
        />
      ),
    },
  ];

  const findCurrSellForexContent = sellForexContentMapper.find(
    ({ step }) => step === activeStep
  );
  const activeSellForexComponent = findCurrSellForexContent
    ? findCurrSellForexContent.component
    : null;

  return (
    <Flex direction='column' gap='md'>
      <SellForexStepper activeStep={activeStep} />
      {activeSellForexComponent}
      <Banner
        visible={confirmedSellForex}
        onClose={handleCloseConfirmedModal}
        title='Transaction Complete!'
        message='Your Sell Forex transaction XXXXXX has been completed.
        Funds have been successfully sent to your PHP wallet.'
      />
    </Flex>
  );
};

export default SellForexPage;
