import { BackgroundImage, Title, Text, Button, Flex } from '@mantine/core';
import { RoutePaths } from '../../../constants/routes';
import merchantServiceBanner from '../../../assets/images/merchant-services-banner.svg';
import useStyles from '../../pages/landing/LandingPage.styles';

const MerchantServices = () => {
  const { classes } = useStyles();

  return (
    <BackgroundImage
      id={RoutePaths.RootHash.replace('/#', '')}
      className={classes.merchantServBanner}
      src={merchantServiceBanner}
    >
      <div className={classes.merchantMainWrapper}>
        <Flex className={classes.merchantFlexWrapper}>
          <Title order={5} className={classes.merchantH5}>
            Merchant Services
          </Title>
          <Title order={3} className={classes.merchantH3}>
            Manage local and international payments the easy way.
          </Title>
          <Text className={classes.merchantText}>
            Enjoy an efficient, safe, and convenient way to send and receive
            payments locally and internationally with U Business Solutions.
          </Text>
          <Flex direction='column' mt={16}>
            <Text className={classes.merchantTextBold}>
              • Pay international suppliers direct to bank or wallet
            </Text>
            <Text className={classes.merchantTextBold}>
              • Nationwide store collection network of more than 800 U Stores
            </Text>
            <Text className={classes.merchantTextBold}>
              • Easy fund transfers to your bank account
            </Text>
          </Flex>
          <Button className='red-btn'>LEARN MORE</Button>
        </Flex>
      </div>
    </BackgroundImage>
  );
};

export default MerchantServices;
