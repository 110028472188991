export const mockTransactionList = [
  {
    id: 1,
    referenceNo: '9C7FBAF0C44A4',
    type: 'Customer Payment',
    amount: 2525.0,
    currency: 'PHP',
    increment: true,
    create_date: '2017-09-26 10:44:22',
  },
  {
    id: 2,
    referenceNo: '9C7FBAF0C44A5',
    type: 'Send Money - Domestic',
    amount: 5525.0,
    currency: 'PHP',
    increment: false,
    create_date: '2017-09-26 10:44:22',
  },
  {
    id: 3,
    referenceNo: '9C7FBAF0C44A6',
    type: 'Buy Forex',
    amount: 10020.0,
    currency: 'PHP',
    increment: true,
    create_date: '2017-09-26 10:44:22',
  },
  {
    id: 4,
    referenceNo: '9C7FBAF0C44A7',
    type: 'Bank Transfer',
    amount: 5000.0,
    currency: 'PHP',
    increment: true,
    create_date: '2017-09-26 10:44:22',
  },
  {
    id: 5,
    referenceNo: '9C7FBAF0C44A8',
    type: 'Send Money - International',
    amount: 2525.0,
    currency: 'PHP',
    increment: false,
    create_date: '2017-09-26 10:44:22',
  },
  {
    id: 6,
    referenceNo: '9C7FBAF0C44A4',
    type: 'Customer Payment',
    amount: 2525.0,
    currency: 'PHP',
    increment: true,
    create_date: '2017-09-26 10:44:22',
  },
  {
    id: 7,
    referenceNo: '9C7FBAF0C44A5',
    type: 'Send Money - Domestic',
    amount: 5525.0,
    currency: 'PHP',
    increment: true,
    create_date: '2017-09-26 10:44:22',
  },
  {
    id: 8,
    referenceNo: '9C7FBAF0C44A6',
    type: 'Buy Forex',
    amount: 10020.0,
    currency: 'PHP',
    increment: true,
    create_date: '2017-09-26 10:44:22',
  },
  {
    id: 9,
    referenceNo: '9C7FBAF0C44A7',
    type: 'Bank Transfer',
    amount: 5000.0,
    currency: 'PHP',
    increment: false,
    create_date: '2017-09-26 10:44:22',
  },
  {
    id: 10,
    referenceNo: '9C7FBAF0C44A8',
    type: 'Send Money - International',
    amount: 2525.0,
    currency: 'PHP',
    increment: true,
    create_date: '2017-09-26 10:44:22',
  },
];

export const mockTransactionByType = {
  'Customer Payment': {
    id: '24',
    reference_no: '9C7FBAF0C44A4',
    seller_id: '119',
    buyer_id: '0',
    first_name: 'Carmelo',
    middle_name: 'Stephen',
    last_name: 'Anthony',
    amount: '23000.00',
    fee: '690.00',
    email: 'dunkindonato@ymail.com',
    mobile_no: '09275530354',
    sku: '0099887766554433',
    remarks: 'lebron james jersey',
    status: 'PE',
    refund_flag: '',
    payment_date: '0000-00-00 00:00:00',
    cancel_date: '0000-00-00 00:00:00',
    for_refund_date: '0000-00-00 00:00:00',
    refund_date: '0000-00-00 00:00:00',
    create_date: '2017-09-26 10:44:22',
    update_date: '2017-09-26 10:44:22',
  },
  'Send Money - Domestic': {
    id: 1,
    reference_no: '9C7FBAF0C44A4',
    bank: 'Banco De Oro (BDO)',
    accountNo: '0011223344556677',
    accountName: 'Jane Doe',
    wallet: 78578,
    transferAmount: 5000,
    transferFee: 25,
    create_date: '2017-09-26 10:44:22',
  },
  'Buy Forex': {
    id: 2,
    reference_no: '9C7FBAF0C44A4',
    currentWallet: 78587,
    domesticAmount: 10000,
    interAmount: 180,
    totalAmount: 10020,
    create_date: '2017-09-26 10:44:22',
  },
  'Bank Transfer': {
    id: 3,
    reference_no: '9C7FBAF0C44A4',
    bank: 'Banco De Oro (BDO)',
    accountNo: '0011223344556677',
    accountName: 'Jane Doe',
    wallet: 78578,
    transferAmount: 5000,
    transferFee: 25,
    create_date: '2017-09-26 10:44:22',
  },
  'Send Money - International': {
    id: 4,
    reference_no: '9C7FBAF0C44A4',
    countryOrigin: 'Singapore',
    countryCode: '+65',
    iban: 'GB14WXYZ2056232564897',
    bank: 'Bank of Singapore',
    bankType: 'Individual',
    beneficiaryFName: 'Jane',
    beneficiaryMName: 'Marie',
    beneficiaryLName: 'Doe',
    mobileNo: '09275530354',
    senderFullName: 'Jane Marie Doe',
    purpose: 'Fund Transfer',
    fundSource: 'Salary',
    relationship: 'Family',
    wallet: 78576,
    amountSent: 333,
    currency: 'SGD',
    fee: 1,
    serviceTime: '20 minutes',
    fileName: 'img.34234232.jpg',
    fileSize: '514KB',
    create_date: '2017-09-26 10:44:22',
  },
};
