import {
  Modal,
  Grid,
  Flex,
  Title,
  Text,
  Divider,
  Avatar,
  ActionIcon,
} from '@mantine/core';
import { IconTrash, IconPencil } from '@tabler/icons-react';
import AtomGridDetails from '../../atoms/grid-details/GridDetails';

const SendMoneyFavoritesContent = ({ initials, name, details }) => {
  return (
    <Flex direction='column' justify='space-between' className='purple-wrapper'>
      <Flex align='center' justify='space-between'>
        <Flex align='center' gap='5px'>
          <Avatar radius='xl' color='ubsHighlight.9'>
            {initials}
          </Avatar>
          <Text fw={500} truncate>
            {name}
          </Text>
        </Flex>
        <Flex gap='5px'>
          <ActionIcon variant='transparent' color='dark'>
            <IconPencil />
          </ActionIcon>
          <ActionIcon variant='transparent' color='red'>
            <IconTrash />
          </ActionIcon>
        </Flex>
      </Flex>
      <Divider my={16} />
      <AtomGridDetails details={details} />
    </Flex>
  );
};

const SendMoneyFavoritesModal = ({ visible, onClose }) => {
  const ModalHeader = (
    <Flex direction='column'>
      <Title order={4} color='white'>
        Favorites
      </Title>
      <Text className='span-sub-text' sx={{ color: 'white !important' }}>
        Send Money International to add favorite
      </Text>
    </Flex>
  );

  return (
    <Modal
      opened={visible}
      onClose={onClose}
      title={ModalHeader}
      centered
      size={900}
      lockScroll={false}
      className='purple-modal'
    >
      <Grid p={16}>
        <Grid.Col sm={6}>
          <SendMoneyFavoritesContent
            initials='FB'
            name='Fuel Business'
            details={[
              {
                label: 'Country of Origin',
                value: 'Singapore',
              },
              {
                label: 'Beneficiary Name',
                value: 'DA Fuel Subsidy',
              },
              {
                label: 'Beneficiary Type',
                value: 'Business',
              },
              {
                label: 'Transaction Wallet',
                value: 'PHP Wallet',
              },
            ]}
          />
        </Grid.Col>
        <Grid.Col sm={6}>
          <SendMoneyFavoritesContent
            initials='JD'
            name='Jane Doe'
            details={[
              {
                label: 'Country of Origin',
                value: 'Singapore',
              },
              {
                label: 'Beneficiary Name',
                value: 'Jane Doe',
              },
              {
                label: 'Beneficiary Type',
                value: 'Individual',
              },
              {
                label: 'Transaction Wallet',
                value: 'PHP Wallet',
              },
            ]}
          />
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default SendMoneyFavoritesModal;
