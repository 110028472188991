import { errorMsgMapper } from '../constants/errors';

export const httpErrorMsgHandler = (useSWRNext) => {
  return (key, fetcher, config) => {
    const swr = useSWRNext(key, fetcher, config);
    let errorMsg = '';

    if (swr.error) {
      if (swr.error.response && swr.error.response.status) {
        const errStatusCode = swr.error.response.status;
        errorMsg = errorMsgMapper[errStatusCode] ?? errorMsgMapper.default;
      } else {
        errorMsg = swr.error.message;
      }
    }

    return { ...swr, errorMsg };
  };
};
