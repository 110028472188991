export const profileMapper = {
  id: 'id',
  'user.first_name': 'firstName',
  'user.middle_name': 'middleName',
  'user.last_name': 'lastName',
  'user.birthday': 'birthday',
  'user.gender': 'gender',
  'user.mobile_no': 'mobileNo',
  'user.email': 'email',
  'user.province': 'userProvince',
  'user.barangay': 'userBarangay',
  'user.city': 'userCity',
  'user.zip_code': 'userZipCode',
  'user.address': 'userAddress',
  'detail.personal': 'personal',
  'detail.tin': 'tin',
  'detail.website': 'website',
  'detail.physical_store': 'physicalStore',
  'detail.business_name': 'businessName',
  'detail.business_class': 'businessClass',
  'detail.business_type': 'businessType',
  'detail.business_address': 'businessAddress',
  'detail.business_barangay': 'businessBarangay',
  'detail.business_city': 'businessCity',
  'detail.business_province': 'businessProvince',
  'detail.business_zip_code': 'businessZipcode',
};
