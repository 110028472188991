import {
  Flex,
  Title,
  Text,
  Grid,
  Button,
  Select,
  TextInput,
  Image,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { formatCurrency } from '../../../utils/currency';
import { getDateTime } from '../../../utils/date';
import { SendMoneyIntlTransAmountSchema } from '../../../schema/send-money';
import { currencyOptions } from '../../../constants/send-money';
import {
  exchangeRate,
  sendFee,
  currencyWalletFund,
} from '../../../mocks/send-money';
import AtomGridDetails from '../../atoms/grid-details/GridDetails';
import phFlag from '../../../assets/images/ph-flag.svg';
import usFlag from '../../../assets/images/us-flag.svg';
import useStyles from '../../pages/send-money-intl/SendMoneyIntlPage.styles';

export const SendMoneyFundWalletBtn = ({
  flagImg,
  label,
  value,
  isSelected,
}) => {
  const { classes } = useStyles();
  return (
    <Flex
      justify='space-between'
      className={isSelected ? classes.sendMoneyIntlWallet : 'purple-wrapper'}
    >
      <Grid w='100%'>
        <Grid.Col
          md={6}
          sx={(theme) => ({
            alignItems: 'center',
            display: 'flex',
            gap: 16,
            [theme.fn.smallerThan('md')]: {
              alignItems: 'center',
              justifyContent: 'center',
            },
          })}
        >
          <Image src={flagImg} width={50} height={50} />
          <Text color={isSelected ? '#FFFFFF' : 'dark'}>{label}</Text>
        </Grid.Col>
        <Grid.Col
          md={6}
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            [theme.fn.smallerThan('md')]: {
              alignItems: 'center',
              justifyContent: 'center',
            },
          })}
        >
          <Title
            order={4}
            className='page-h4-title'
            sx={{ color: isSelected ? '#FFFFFF !important' : 'dark' }}
          >
            {value}
          </Title>
          <span
            className='span-sub-text'
            style={{ color: isSelected ? '#FFFFFF' : '#000000' }}
          >
            Total Balance
          </span>
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

export const SendMoneyTransAmountBreakdown = ({
  currencyExchange,
  currency,
  fee,
  equivalent,
  total,
}) => {
  return (
    <>
      <Flex align='center' justify='space-between' className='purple-wrapper'>
        <Flex direction='column'>
          <Text>Exchange Rate</Text>
          <span className='span-sub-text'>
            {getDateTime('2017-09-26 10:44:22', 'LLL dd, yyyy - t')}
          </span>
        </Flex>
        <Text>SGD 1 = {currencyExchange}</Text>
      </Flex>
      <Flex
        align='center'
        justify='space-between'
        className='purple-wrapper'
        sx={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <AtomGridDetails
          details={[
            {
              label: 'Send Fee',
              value: fee,
            },
            {
              label: `${currency} Equivalent`,
              value: equivalent,
            },
            {
              label: 'Total Amount to Pay',
              value: total,
              isBold: true,
            },
          ]}
        />
      </Flex>
      <Flex
        align='center'
        justify='space-between'
        p={16}
        sx={(theme) => ({
          backgroundColor: theme.colors.ubsPrimary[6],
          color: '#FFFF',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
        })}
      >
        <Text>Estimated Service Time</Text>
        <Text>20 minutes</Text>
      </Flex>
    </>
  );
};

const SendMoneyTransAmount = ({ formData, onPrevStep, onNextStep }) => {
  const sendMoneyIntlTransAmountForm = useForm({
    validate: yupResolver(SendMoneyIntlTransAmountSchema),
    initialValues: formData,
  });
  const { onSubmit, getInputProps, setFieldValue, values, errors } =
    sendMoneyIntlTransAmountForm;

  const calculateTranFees = () => {
    const { sendMoney, walletFund } = values;
    let fees = {
      currencyWalletFund: 0,
      currencyFee: 0,
      equivalent: 0,
      total: 0,
    };
    if (walletFund) {
      const currencyFee = sendFee[walletFund];
      const equivalent = exchangeRate[walletFund] * sendMoney;
      fees = {
        currencyWalletFund: currencyWalletFund[walletFund],
        currencyFee,
        equivalent,
        total: currencyFee + equivalent,
      };
    }
    return fees;
  };

  const handleOnSelectWallet = (wallet) => {
    setFieldValue('walletFund', wallet);
  };

  const handleSubmit = (data) => {
    const calculatedFees = calculateTranFees();
    onNextStep({ ...data, ...calculatedFees });
  };

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <Flex direction='column' className='white-wrapper'>
        <Title order={4} className='page-h4-title'>
          Select Transaction Wallet
        </Title>
        <Text className='span-sub-text'>
          Funds for this transaction will be deducted from your selected wallet.
        </Text>
        <Grid w='100%'>
          <Grid.Col sm={6} onClick={() => handleOnSelectWallet('PHP')}>
            <SendMoneyFundWalletBtn
              label='PHP Wallet'
              value={formatCurrency('PHP', currencyWalletFund.PHP)}
              flagImg={phFlag}
              isSelected={values.walletFund === 'PHP'}
            />
          </Grid.Col>
          <Grid.Col sm={6} onClick={() => handleOnSelectWallet('USD')}>
            <SendMoneyFundWalletBtn
              label='USD Wallet'
              value={formatCurrency('USD', currencyWalletFund.USD)}
              flagImg={usFlag}
              isSelected={values.walletFund === 'USD'}
            />
          </Grid.Col>
          {errors.walletFund && (
            <Grid.Col>
              <Text className='span-sub-text' sx={{ color: 'red !important' }}>
                {errors.walletFund}
              </Text>
            </Grid.Col>
          )}
          <Grid.Col sm={6}>
            <Title order={4} className='page-h4-title'>
              Select Transaction Wallet
            </Title>
            <Select
              label='Currency'
              data={currencyOptions}
              clearable
              {...getInputProps('currency')}
            />
            <TextInput
              mt={16}
              label='Send Money (SGD)'
              {...getInputProps('sendMoney')}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <Title order={4} className='page-h4-title'>
              Summary
            </Title>
            <SendMoneyTransAmountBreakdown
              currencyExchange={
                values.walletFund
                  ? `${values.walletFund} ${exchangeRate[values.walletFund]}`
                  : 0
              }
              currency={values.walletFund}
              fee={formatCurrency(
                `${values.walletFund || 'PHP'}`,
                calculateTranFees().currencyFee
              )}
              equivalent={formatCurrency(
                `${values.walletFund || 'PHP'}`,
                calculateTranFees().equivalent
              )}
              total={formatCurrency(
                `${values.walletFund || 'PHP'}`,
                calculateTranFees().total
              )}
            />
          </Grid.Col>
          <Grid.Col
            mt={16}
            display='flex'
            sx={(theme) => ({
              alignItems: 'center',
              gap: '5px',
              justifyContent: 'flex-end',
              [theme.fn.smallerThan('md')]: {
                justifyContent: 'flex-start',
              },
            })}
          >
            <Button color='ubsBtnExitColor.3' c='black' onClick={onPrevStep}>
              Back
            </Button>
            <Button type='submit' className='yellow-btn'>
              Next
            </Button>
          </Grid.Col>
        </Grid>
      </Flex>
    </form>
  );
};

export default SendMoneyTransAmount;
