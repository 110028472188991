import { Modal, Flex, Text, Button } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';

const ProcessedPayrollModal = ({ visible, onClose }) => {
  return (
    <Modal
      opened={visible}
      centered
      size={600}
      onClose={onClose}
      withCloseButton={false}
    >
      <Flex direction='column' align='center' justify='center' p={16}>
        <IconCircleCheck color='green' size={80} />
        <Text color='green' fw={500} my={16} sx={{ fontSize: 20 }}>
          Success
        </Text>
        <Text ta='center' sx={{ fontSize: 14 }}>
          Your Payroll file has been successfully processed.
        </Text>
        <Button className='yellow-btn' mt={32} w={200} onClick={onClose}>
          Okay
        </Button>
      </Flex>
    </Modal>
  );
};

export default ProcessedPayrollModal;
