import { Flex, Text, Title, Button, Input, Image, Center } from '@mantine/core';
import { IconDownload, IconPlus } from '@tabler/icons-react';
import { useState } from 'react';

import qrCodeSample from '../../../assets/images/qr-code-sample.svg';
import useStyles from './GenerateQROrg.styles';

const GenerateQRModal = () => {
  const { classes } = useStyles();
  const [enterAmnt, setEnterAmnt] = useState(true);

  return (
    <Center className={classes.genQRInfoWrapper}>
      <Flex className={classes.genQRCodeWrapper} direction='column' align='center' justify='center' pt={50} pb={50} pr={140} pl={140}>
        { enterAmnt ? (<Button onClick={() => setEnterAmnt(false)} w={200} leftIcon={<IconPlus />}>Add Amount</Button>) : (
          <Input.Wrapper size='xs' label='Requested Amount' w='100%'>
            <Input />
          </Input.Wrapper>)}
        <Title order={2} mt={32}>
          Scan QR Code
        </Title>
        <Text className='span-sub-text'>
          Scan this QR code to receive money.
        </Text>
        <Image
          src={qrCodeSample}
          width={258}
          height={258}
          mt={10}
          alt='Sample QR code'
        />
        <Button
          className='yellow-btn'
          leftIcon={<IconDownload />}
          mt={32}
          w={200}
        >
          Download QR
        </Button>
      </Flex>
    </Center>

  );
};

export default GenerateQRModal;
