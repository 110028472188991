import { useEffect, useState, useRef } from 'react';
import { useForm, yupResolver } from '@mantine/form';
import { Card, Modal, Image, Text, Title, Button, Flex } from '@mantine/core';
import AuthCode from 'react-auth-code-input';
import { SignupActivateCodeFormSchema } from '../../../../schema/sign-up';
import signupActivateBanner from '../../../../assets/images/signup-activate-banner.svg';

const SignUpActivateCode = ({ visible, onClose, onNextStep }) => {
  const activateCodeInput = useRef(null);
  const [counter, setCounter] = useState(60);
  const codeForm = useForm({
    validate: yupResolver(SignupActivateCodeFormSchema),
    initialValues: {
      codes: '',
    },
  });
  const { setFieldValue, reset, onSubmit, errors } = codeForm;

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCount) => {
        const newCounter = prevCount - 1;
        return newCounter === 0 ? 60 : newCounter;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleResetActivation = () => {
    activateCodeInput.current.clear();
    reset();
    setCounter(60);
  };

  const handleOnChange = (code) => {
    setFieldValue('codes', code);
  };

  const handleSubmitActivationCode = () => {
    onNextStep();
  };

  return (
    <Modal
      opened={visible}
      onClose={onClose}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      padding={0}
      size='lg'
    >
      <Card>
        <Card.Section>
          <Image
            src={signupActivateBanner}
            height={200}
            alt='Signup activate banner'
          />
          <div className='img-yellow-ribbon' />
        </Card.Section>
        <form onSubmit={onSubmit(handleSubmitActivationCode)}>
          <Flex direction='column' align='center' justify='center' my={16}>
            <Title order={2} color='black'>
              Activate Code
            </Title>
            <Text size='sm' color='dimmed'>
              Please enter 8 digits activation code
            </Text>
            <AuthCode
              ref={activateCodeInput}
              length={8}
              allowedCharacters='alphanumeric'
              onChange={handleOnChange}
              containerClassName='codeInputWrapper'
              inputClassName='codeInput'
            />
            {errors.codes && (
              <p className='codeInputErr'>Please provide all activation code</p>
            )}
            <Button className='defaultBtn' w={100} type='submit' mt={32}>
              OK
            </Button>
            <Button
              variant='subtle'
              color='ubsHighlight.9'
              size='xs'
              mt={16}
              onClick={handleResetActivation}
            >
              Resend Code ({counter})
            </Button>
          </Flex>
        </form>
      </Card>
    </Modal>
  );
};

export default SignUpActivateCode;
