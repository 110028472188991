import { httpClient } from './base/httpClient';

const userService = {
  getEmpolyees: async (url, { data }) => {
    const response = await httpClient.post(url, data);
    return response;
  },
};

export default userService;
