import {
  Grid,
  Flex,
  Text,
  Avatar,
  Card,
  ActionIcon,
  Menu,
  rem,
} from '@mantine/core';
import {
  IconDotsVertical,
  IconEye,
  IconPencil,
  IconTrash,
  IconEyeOff,
} from '@tabler/icons-react';
import { maskCard } from 'maskdata';
import { useState } from 'react';

const maskCardOptions = {
  maskWith: '*',
  unmaskedStartDigits: 3,
  unmaskedEndDigits: 1,
};

export default ({ name, logo, bankNo, bankName }) => {
  const [show, setShow] = useState(false);
  const maskName = name.replace(/[a-z]/g, '*');
  return (
    <Card w="23rem" shadow="sm" padding="lg" radius="md" withBorder>
      <Grid>
        <Grid.Col span={12}>
          <Flex justify="flex-end">
            <Menu
              position="left-start"
              offset={6}
              withArrow
              arrowPosition="center"
            >
              <Menu.Target>
                <ActionIcon>
                  <IconDotsVertical color="black" />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item icon={<IconPencil size={rem(20)} />}>Edit</Menu.Item>
                <Menu.Item icon={<IconTrash color="red" size={rem(20)} />}>
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
          <Flex align="center" gap="md">
            <Avatar size="lg" radius="xl" src={logo} alt="" />
            <Text c="gray.8" fz="xl" fw={700}>
              {bankName}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={12}>
          <Flex align="center" justify="space-between" gap="sm">
            <Text fz="xl" c="gray.7" fw={600}>
              {show ? bankNo : maskCard(bankNo, maskCardOptions)}
            </Text>
            <ActionIcon onClick={() => setShow(!show)}>
              {show ? <IconEye color="black" /> : <IconEyeOff color="black" />}
            </ActionIcon>
          </Flex>
        </Grid.Col>
        <Grid.Col span={12}>
          <Flex align="center" gap="sm">
            <Text fz="md" c="gray.7" fw={600}>
              {show ? name : maskName}
            </Text>
          </Flex>
        </Grid.Col>
      </Grid>
    </Card>
  );
};
