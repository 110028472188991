import {
  Flex,
  Title,
  TextInput,
  PasswordInput,
  Button,
  Group,
  Box,
  Checkbox,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { IconUser, IconLock } from '@tabler/icons-react';
import { LoginSchema } from '../../../schema/login';
import useStyles from '../../pages/login/LoginPage.styles';

const LoginForm = ({ onLogin }) => {
  const { classes } = useStyles();
  const loginForm = useForm({
    validate: yupResolver(LoginSchema),
    initialValues: {
      userName: '',
      password: '',
    },
  });
  const { onSubmit, getInputProps } = loginForm;

  const handleUserLogin = () => {
    onLogin();
  };

  return (
    <Flex
      direction='column'
      align='center'
      justify='center'
      gap='sm'
      mt={48}
      w='100%'
    >
      <Title
        order={4}
        tt='uppercase'
        fw={400}
        lts={2.4}
        className={classes.loginWelcomeTitle}
      >
        Welcome!
      </Title>
      <Box mx='auto' w='100%'>
        <form
          className={classes.loginForm}
          onSubmit={onSubmit((values) => handleUserLogin(values))}
        >
          <TextInput
            placeholder='Username'
            variant='unstyled'
            icon={<IconUser />}
            className={classes.loginInput}
            {...getInputProps('userName')}
          />
          <PasswordInput
            id='login-password-input'
            placeholder='Password'
            variant='unstyled'
            icon={<IconLock />}
            className={classes.loginInput}
            {...getInputProps('password')}
          />
          <Flex justify='space-between'>
            <Checkbox label='Keep me logged in' />
            <span style={{ fontSize: 16, color: '#60007F' }}>
              Forgot Password
            </span>
          </Flex>
          <Group position='center' mt='xl'>
            <Button className='defaultBtn' w={160} type='submit'>
              Log In
            </Button>
          </Group>
        </form>
      </Box>
    </Flex>
  );
};

export default LoginForm;
