import AtomStepper from '../../atoms/stepper/Stepper';

const BuyForexStepper = ({ activeStep }) => {
  return (
    <AtomStepper
      steps={['Buy Forex Details', 'Confirm Details']}
      activeStep={activeStep}
    />
  );
};

export default BuyForexStepper;
