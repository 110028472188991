import { Card, Flex, Title, Avatar, MediaQuery } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { PaymentIconMapper } from '../../../constants/payment';

const PaymentCard = ({ count, label, icon, color }) => {
  return (
    <Card shadow='sm' padding='lg' radius='md' w={200} withBorder>
      <Flex align='center' justify='space-between' w='100%'>
        <Flex direction='column'>
          <Title
            order={4}
            className='page-h4-title'
            sx={{ fontSize: '2rem !important', fontWeight: '600 !important' }}
          >
            {count}
          </Title>
          <span
            className='span-sub-text'
            style={{ textTransform: 'uppercase', color: '#747474' }}
          >
            {label}
          </span>
        </Flex>
        <Avatar size='lg' radius='xl' color={color} variant='filled'>
          {icon}
        </Avatar>
      </Flex>
    </Card>
  );
};

const paymentCardColList = (data) => {
  const groupByPaymentStatus = data.reduce((group, payment) => {
    const cloneGroup = group;
    const { status } = payment;
    cloneGroup[status] = cloneGroup[status] ?? [];
    cloneGroup[status].push(payment);
    return cloneGroup;
  }, {});

  const paymentCardList = [];

  Object.keys(groupByPaymentStatus).forEach((statusKey) => {
    paymentCardList.push(
      <PaymentCard
        key={statusKey}
        count={groupByPaymentStatus[statusKey].length}
        label={statusKey}
        icon={PaymentIconMapper(32)[statusKey].icon}
        color={PaymentIconMapper(32)[statusKey].color}
      />
    );
  });

  return paymentCardList;
};

const PaymentCardsFlexed = ({ data }) => {
  return (
    <MediaQuery smallerThan='lg' styles={{ display: 'none' }}>
      <Flex gap={16} mb={16} sx={{ flexWrap: 'wrap' }}>
        {paymentCardColList(data).map((paymentCard) => paymentCard)}
      </Flex>
    </MediaQuery>
  );
};

const PaymentCardsCarousel = ({ data }) => {
  return (
    <MediaQuery largerThan='lg' styles={{ display: 'none' }}>
      <Carousel slideSize='30%' align='start' slideGap='xs' mb={16}>
        {paymentCardColList(data).map((paymentCard, paymentCardIndex) => (
          <Carousel.Slide key={paymentCardIndex}>{paymentCard}</Carousel.Slide>
        ))}
      </Carousel>
    </MediaQuery>
  );
};

const PaymentCards = ({ paymentData }) => {
  return (
    <>
      <PaymentCardsFlexed data={paymentData} />
      <PaymentCardsCarousel data={paymentData} />
    </>
  );
};

export default PaymentCards;
