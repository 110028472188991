import { useState } from 'react';
import { Flex } from '@mantine/core';
import { initialBuyForexFormData } from '../../../constants/forex';
import Banner from '../../atoms/banner/Banner';
import BuyForexStepper from '../../organisms/buy-forex/BuyForexStepper';
import BuyForexDetails from '../../organisms/buy-forex/BuyForexDetails';
import BuyForexConfirm from '../../organisms/buy-forex/BuyForexConfirm';

const BuyForexPage = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [formData, setFormData] = useState(initialBuyForexFormData);
  const [confirmedBuyForex, setConfirmedBuyForex] = useState(false);

  const handleNextStep = (currFormData) => {
    setFormData({ ...formData, ...currFormData });
    setActiveStep(activeStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleConfirmBuyForex = () => {
    setConfirmedBuyForex(true);
  };

  const handleCloseConfirmedModal = () => {
    setFormData(initialBuyForexFormData);
    setActiveStep(1);
    setConfirmedBuyForex(false);
  };

  const buyForexContentMapper = [
    {
      step: 1,
      component: (
        <BuyForexDetails formData={formData} onNextStep={handleNextStep} />
      ),
    },
    {
      step: 2,
      component: (
        <BuyForexConfirm
          formData={formData}
          onPrevStep={handlePrevStep}
          onConfirm={handleConfirmBuyForex}
        />
      ),
    },
  ];

  const findCurrBuyForexContent = buyForexContentMapper.find(
    ({ step }) => step === activeStep
  );
  const activeBuyForexComponent = findCurrBuyForexContent
    ? findCurrBuyForexContent.component
    : null;

  return (
    <Flex direction='column' gap='md'>
      <BuyForexStepper activeStep={activeStep} />
      {activeBuyForexComponent}
      <Banner
        visible={confirmedBuyForex}
        onClose={handleCloseConfirmedModal}
        title='Transaction Complete!'
        message='Your Buy Forex transaction XXXXXX has been completed.
        Funds have been successfully sent to your USD wallet.'
      />
    </Flex>
  );
};

export default BuyForexPage;
