import * as yup from 'yup';

export const BuyForexDetailsSchema = yup.object().shape({
  buyAmountPHP: yup
    .number()
    .required('Amount to Buy (PHP) is required')
    .min(1, 'Amount to Buy (PHP) should be minimum of 1'),
  receiveAmountUSD: yup
    .number()
    .required('Amount to Receive (USD) is required')
    .min(1, 'Amount to Receive (USD) should be minimum of 1'),
});

export const SellForexDetailsSchema = yup.object().shape({
  sellAmountUSD: yup
    .number()
    .required('Amount to Sell (USD) is required')
    .min(1, 'Amount to Sell (USD) should be minimum of 1'),
  receiveAmountPHP: yup
    .number()
    .required('Amount to Receive (PHP) is required')
    .min(1, 'Amount to Receive (PHP) should be minimum of 1'),
});
