export const mockNotifications = [
  {
    id: '132',
    user_id: '242',
    message: 'Balance was transferred out with reference no. 20171050008EA',
    url: 'user/wallet/view?ref=20171050008EA',
    status: '0',
    create_date: '2017-10-04 11:35:23',
    update_date: '2017-10-04 11:35:23',
  },
  {
    id: '112',
    user_id: '242',
    message: 'Balance was transferred out with reference no. 20171050007U0',
    url: 'user/wallet/view?ref=20171050007U0',
    status: '0',
    create_date: '2017-10-03 14:56:13',
    update_date: '2017-10-03 14:56:13',
  },
  {
    id: '86',
    user_id: '242',
    message: 'Balance was transferred out with reference no. 201710500073B',
    url: 'user/wallet/view?ref=201710500073B',
    status: '0',
    create_date: '2017-10-02 16:09:21',
    update_date: '2017-10-02 16:09:21',
  },
  {
    id: '84',
    user_id: '242',
    message: 'Balance was transferred out with reference no. 2017105000713',
    url: 'user/wallet/view?ref=2017105000713',
    status: '0',
    create_date: '2017-10-02 16:09:00',
    update_date: '2017-10-02 16:09:00',
  },
];
