import {
  Grid,
  Flex,
  Text,
  Tabs,
  Button,
  PasswordInput,
  Select,
} from '@mantine/core';
import { IconLock, IconDeviceMobile } from '@tabler/icons-react';
import { useForm, yupResolver } from '@mantine/form';
import { useState } from 'react';

import useStyles from './Setting.styles';
import { ChangePasswordSchema } from '../../../schema/profile';
import Banner from '../../atoms/banner/Banner';
import MobileVerify from '../../atoms/verify/MobileVerify';

const SettingSection = () => {
  const { classes } = useStyles();
  const [showProcessedModal, setProcessedModalvisibility] = useState(false);
  const [verifyModal, setVerifyModal] = useState(false);

  const changePswdForm = useForm({
    validate: yupResolver(ChangePasswordSchema),
    initialValues: {
      oldPswd: '',
      newPswd: '',
      conPswd: '',
    },
  });

  const { onSubmit, getInputProps } = changePswdForm;

  const handleChangePswd = () => {
    setProcessedModalvisibility(true);
  };

  return (
    <Tabs defaultValue='pwrdInfoTab' px={16}>
      <MobileVerify
        visible={verifyModal}
        onClose={() => {
          setVerifyModal(false);
        }}
        title='Information Save'
        message='Employee settings has been successfully saved.'
      />
      <Banner
        visible={showProcessedModal}
        onClose={() => {
          setProcessedModalvisibility(false);
        }}
        title='Information Save'
        message='Employee settings has been successfully saved.'
      />
      <Tabs.List>
        <Tabs.Tab icon={<IconLock size='1.5rem' />} value='pwrdInfoTab'>
          Change Password
        </Tabs.Tab>
        <Tabs.Tab icon={<IconDeviceMobile size='1.5rem' />} value='authInfoTab'>
          Dual Authentication
        </Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value='pwrdInfoTab' pt='xs'>
        <form onSubmit={onSubmit(handleChangePswd)}>
          <Flex p='lg' className={classes.settingFormContainer}>
            <Grid w='100%'>
              <Grid.Col span={12}>
                <Text fz='sm' fw={600}>
                  Change Password
                </Text>
              </Grid.Col>
              <Grid.Col span={12}>
                <PasswordInput
                  placeholder='Old Password'
                  label='Old Password'
                  withAsterisk
                  {...getInputProps('oldPswd')}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <PasswordInput
                  placeholder='New Password'
                  label='New Password'
                  withAsterisk
                  {...getInputProps('newPswd')}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <PasswordInput
                  placeholder='Confirm Password'
                  label='Confirm Password'
                  withAsterisk
                  {...getInputProps('conPswd')}
                />
              </Grid.Col>
              <Grid.Col span={12} pt='2rem'>
                <Flex
                  justify='flex-end'
                  align='center'
                  direction='row'
                  gap='xs'
                >
                  <Button className='yellow-btn' type='submit'>
                    Submit
                  </Button>
                </Flex>
              </Grid.Col>
            </Grid>
          </Flex>
        </form>
      </Tabs.Panel>
      <Tabs.Panel value='authInfoTab' pt='xs'>
        <Flex p='lg' className={classes.settingFormContainer}>
          <Grid w='100%'>
            <Grid.Col span={12}>
              <Text fz='sm' fw={600}>
                Dual Authentication
              </Text>
            </Grid.Col>
            <Grid.Col span={12}>
              <Text align='justify' fz='sm'>
                Two-Factor Authentication (2FA), also known as Two-Step
                Verification or Multifactor Authentication is an extra layer of
                security aside from your username and password. This “extra
                layer” should be information that only the user should know or
                have access to, such as a physical token or a One-Time Password
                (OTP). This makes it harder for potential intruders to gain
                access to the user’s account or steal that person’s identity and
                personal data.
              </Text>
            </Grid.Col>
            <Grid.Col span={12}>
              <Select
                defaultValue='Time-based OTP (One-Time Password)'
                data={['Time-based OTP (One-Time Password)']}
                placeholder='Choose one'
                label='Choose Authentication type'
                withAsterisk
              />
            </Grid.Col>
            <Grid.Col span={12} pt='2rem'>
              <Flex justify='flex-end' align='right' direction='row' gap='xs'>
                <Button
                  className='yellow-btn'
                  onClick={() => setVerifyModal(true)}
                >
                  Submit
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        </Flex>
      </Tabs.Panel>
    </Tabs>
  );
};

export default SettingSection;
