import { Flex, Title, Card, Image, Text, Grid, Button } from '@mantine/core';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../../constants/routes';
import manageDemandBanner from '../../../assets/images/manage-demand-banner.svg';
import manageScheduledBanner from '../../../assets/images/manage-scheduled-banner.svg';
import manageRegularBanner from '../../../assets/images/manage-regular-banner.svg';
import useStyles from '../../pages/landing/LandingPage.styles';

const ManageFunds = () => {
  const { classes } = useStyles();

  const manageFundCards = (imgSrc, imgAlt, mainTxt, description) => {
    return (
      <Grid.Col md={4}>
        <Card
          shadow='sm'
          padding='xl'
          withBorder
          className={classes.manageFundCard}
        >
          <Card.Section>
            <Image src={imgSrc} alt={imgAlt} height={350} />
            <div className='img-yellow-ribbon' />
          </Card.Section>
          <Flex direction='column' align='center' justify='center' p={16}>
            <Text className={classes.chooseUbsDescMainTxt} align='center'>
              {mainTxt}
            </Text>
            <Text className={classes.merchantText} align='center' mt={16}>
              {description}
            </Text>
          </Flex>
        </Card>
      </Grid.Col>
    );
  };

  return (
    <Flex
      direction='column'
      align='center'
      justify='center'
      className={classes.manageFundWrapper}
    >
      <Flex
        direction='column'
        align='center'
        className={classes.manageFundHeaderWrapper}
      >
        <Flex
          irection={{ base: 'column', sm: 'row' }}
          align='center'
          justify='center'
          px={10}
          gap={{ base: 16, sm: 32 }}
        >
          <Title order={3} className={classes.merchantH3} color='white'>
            MANAGE YOUR FUNDS WAY
          </Title>
          <Button className='red-btn' component={Link} to={RoutePaths.SignUp}>
            SIGN UP
          </Button>
        </Flex>
      </Flex>
      <Grid
        className={classes.manageFundCardWrapper}
        gutter={16}
        justify='center'
      >
        {manageFundCards(
          manageDemandBanner,
          'Manage Fund Bank Transfer Demand',
          'BANK TRANSFER ON DEMAND',
          'Set your bank transfers to be triggered on the next banking day.'
        )}
        {manageFundCards(
          manageScheduledBanner,
          'Manage Fund Scheduled Bank Transfers',
          'SCHEDULED BANK TRANSFERS',
          'Set a regular recurring bank transfer schedule for your convenience.'
        )}
        {manageFundCards(
          manageRegularBanner,
          'Manage Fund Regular Bank Transfers',
          'REGULAR BANK TRANSFERS',
          'Transfer your sales collections to your enrolled bank account through our Bank Transfer Service online anytime, anywhere.'
        )}
      </Grid>
    </Flex>
  );
};

export default ManageFunds;
