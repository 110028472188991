import { createStyles, rem } from '@mantine/core';

const useStyles = createStyles(() => ({
  generateReportForm: {
    marginTop: rem(16),
  },
  reportFileType: {
    border: '1px solid #ced4da',
    borderRadius: '5px',
    padding: rem(32),
    width: '100%',
  },
}));

export default useStyles;
