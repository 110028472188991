import { Input, Text } from '@mantine/core';
import { useId } from '@mantine/hooks';

export const IntProfileLabel = ({ name, value }) => {
  const id = useId();
  return (
    <Input.Wrapper id={id} label={name}>
      <Text fw={600} fz='sm'>
        {value}
      </Text>
    </Input.Wrapper>
  );
};
