export const initialSignupFormData = {
  firstName: '',
  middleName: '',
  lastName: '',
  birthDate: '',
  gender: '',
  province: '',
  city: '',
  barangay: '',
  zipCode: '',
  address: '',
  mobileNo: '',
  email: '',
  newPassword: '',
  confirmPassword: '',
  businessClass: '',
  businessType: '',
  isPhysicalStore: undefined,
  businessName: '',
  businessProvince: '',
  businessCity: '',
  businessBrgy: '',
  businessZipCode: '',
  businessAddress: '',
  isPersonalAddress: undefined,
  socmedLink: '',
  businessTIN: '',
};

export const signupProvinces = [
  { label: 'Test Province', value: 'Test Province' },
];

export const signupCities = [{ label: 'Test City', value: 'Test City' }];

export const signupBrgys = [{ label: 'Test Barangay', value: 'Test Barangay' }];
