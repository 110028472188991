import axios from 'axios';

export const authToken = localStorage.getItem('auth-token');

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Basic ${authToken}`,
    'x-api-key': 'IrjeqNw1EP3Gl5AycF2Pc63NrQwKNxNN2F3cZipp'
  },
});
