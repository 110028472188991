export const mockPaymentData = [
  {
    id: '1',
    reference_no: '9C7FBAF0C44A4',
    seller_id: '119',
    buyer_id: '0',
    first_name: 'John',
    middle_name: 'Seven',
    last_name: 'Doe',
    amount: '23000.00',
    fee: '690.00',
    email: 'johndoe@ymail.com',
    mobile_no: '09123456789',
    sku: '',
    remarks: 'test remarks',
    status: 'Pending',
    refund_flag: '',
    payment_date: '0000-00-00 00:00:00',
    cancel_date: '0000-00-00 00:00:00',
    for_refund_date: '0000-00-00 00:00:00',
    refund_date: '0000-00-00 00:00:00',
    create_date: '2017-09-26 10:44:22',
    update_date: '2017-09-26 10:44:22',
  },
  {
    id: '2',
    reference_no: 'B716A304D2FA6',
    seller_id: '119',
    buyer_id: '0',
    first_name: 'John',
    middle_name: 'Seven',
    last_name: 'Doe',
    amount: '23000.00',
    fee: '690.00',
    email: 'johndoe@ymail.com',
    mobile_no: '09123456789',
    sku: '',
    remarks: 'test remarks',
    status: 'Pending',
    refund_flag: '',
    payment_date: '0000-00-00 00:00:00',
    cancel_date: '0000-00-00 00:00:00',
    for_refund_date: '0000-00-00 00:00:00',
    refund_date: '0000-00-00 00:00:00',
    create_date: '2017-05-29 17:24:19',
    update_date: '2017-05-29 17:24:19',
  },
  {
    id: '3',
    reference_no: 'B716A304D2FA6',
    seller_id: '119',
    buyer_id: '0',
    first_name: 'John',
    middle_name: 'Seven',
    last_name: 'Doe',
    amount: '23000.00',
    fee: '690.00',
    email: 'johndoe@ymail.com',
    mobile_no: '09123456789',
    sku: '',
    remarks: 'test remarks',
    status: 'Pending',
    refund_flag: '',
    payment_date: '0000-00-00 00:00:00',
    cancel_date: '0000-00-00 00:00:00',
    for_refund_date: '0000-00-00 00:00:00',
    refund_date: '0000-00-00 00:00:00',
    create_date: '2017-05-29 17:24:19',
    update_date: '2017-05-29 17:24:19',
  },
  {
    id: '11',
    reference_no: 'B716A304D2FA56',
    seller_id: '119',
    buyer_id: '0',
    first_name: 'John',
    middle_name: 'Seven',
    last_name: 'Doe',
    amount: '23000.00',
    fee: '690.00',
    email: 'johndoe@ymail.com',
    mobile_no: '09123456789',
    sku: '',
    remarks: 'test remarks',
    status: 'Pending',
    refund_flag: '',
    payment_date: '0000-00-00 00:00:00',
    cancel_date: '0000-00-00 00:00:00',
    for_refund_date: '0000-00-00 00:00:00',
    refund_date: '0000-00-00 00:00:00',
    create_date: '2017-05-29 17:24:19',
    update_date: '2017-05-29 17:24:19',
  },
  {
    id: '4',
    reference_no: '9C7FBAF0C44A9',
    seller_id: '119',
    buyer_id: '0',
    first_name: 'Jane',
    middle_name: 'Seven',
    last_name: 'Doe',
    amount: '23000.00',
    fee: '690.00',
    email: 'janedoe@ymail.com',
    mobile_no: '09123456789',
    sku: '',
    remarks: 'test remarks',
    status: 'Paid',
    refund_flag: '',
    payment_date: '0000-00-00 00:00:00',
    cancel_date: '0000-00-00 00:00:00',
    for_refund_date: '0000-00-00 00:00:00',
    refund_date: '0000-00-00 00:00:00',
    create_date: '2017-09-26 10:44:22',
    update_date: '2017-09-26 10:44:22',
  },
  {
    id: '5',
    reference_no: 'B716A304D2FA7',
    seller_id: '119',
    buyer_id: '0',
    first_name: 'Jane',
    middle_name: 'Seven',
    last_name: 'Doe',
    amount: '23000.00',
    fee: '690.00',
    email: 'janedoe@ymail.com',
    mobile_no: '09123456789',
    sku: '',
    remarks: 'test remarks',
    status: 'Paid',
    refund_flag: '',
    payment_date: '0000-00-00 00:00:00',
    cancel_date: '0000-00-00 00:00:00',
    for_refund_date: '0000-00-00 00:00:00',
    refund_date: '0000-00-00 00:00:00',
    create_date: '2017-05-29 17:24:19',
    update_date: '2017-05-29 17:24:19',
  },
  {
    id: '7',
    reference_no: '9C7FBAF0C44A9',
    seller_id: '119',
    buyer_id: '0',
    first_name: 'Jane',
    middle_name: 'Seven',
    last_name: 'Doe',
    amount: '23000.00',
    fee: '690.00',
    email: 'janedoe@ymail.com',
    mobile_no: '09123456789',
    sku: '',
    remarks: 'test remarks',
    status: 'For Refund',
    refund_flag: '',
    payment_date: '0000-00-00 00:00:00',
    cancel_date: '0000-00-00 00:00:00',
    for_refund_date: '0000-00-00 00:00:00',
    refund_date: '0000-00-00 00:00:00',
    create_date: '2017-09-26 10:44:22',
    update_date: '2017-09-26 10:44:22',
  },
  {
    id: '8',
    reference_no: 'B716A304D2FA7',
    seller_id: '119',
    buyer_id: '0',
    first_name: 'Jane',
    middle_name: 'Seven',
    last_name: 'Doe',
    amount: '23000.00',
    fee: '690.00',
    email: 'janedoe@ymail.com',
    mobile_no: '09123456789',
    sku: '',
    remarks: 'test remarks',
    status: 'For Refund',
    refund_flag: '',
    payment_date: '0000-00-00 00:00:00',
    cancel_date: '0000-00-00 00:00:00',
    for_refund_date: '0000-00-00 00:00:00',
    refund_date: '0000-00-00 00:00:00',
    create_date: '2017-05-29 17:24:19',
    update_date: '2017-05-29 17:24:19',
  },
  {
    id: '9',
    reference_no: 'B716A304D2FA8',
    seller_id: '119',
    buyer_id: '0',
    first_name: 'Jane',
    middle_name: 'Seven',
    last_name: 'Doe',
    amount: '23000.00',
    fee: '690.00',
    email: 'janedoe@ymail.com',
    mobile_no: '09123456789',
    sku: '',
    remarks: 'test remarks',
    status: 'For Refund',
    refund_flag: '',
    payment_date: '0000-00-00 00:00:00',
    cancel_date: '0000-00-00 00:00:00',
    for_refund_date: '0000-00-00 00:00:00',
    refund_date: '0000-00-00 00:00:00',
    create_date: '2017-05-29 17:24:19',
    update_date: '2017-05-29 17:24:19',
  },
  {
    id: '10',
    reference_no: '9C7FBAF0C44A9',
    seller_id: '119',
    buyer_id: '0',
    first_name: 'Jane',
    middle_name: 'Seven',
    last_name: 'Doe',
    amount: '23000.00',
    fee: '690.00',
    email: 'janedoe@ymail.com',
    mobile_no: '09123456789',
    sku: '',
    remarks: 'test remarks',
    status: 'Refunded',
    refund_flag: '',
    payment_date: '0000-00-00 00:00:00',
    cancel_date: '0000-00-00 00:00:00',
    for_refund_date: '0000-00-00 00:00:00',
    refund_date: '0000-00-00 00:00:00',
    create_date: '2017-09-26 10:44:22',
    update_date: '2017-09-26 10:44:22',
  },
  {
    id: '12',
    reference_no: 'B716A304D2FA8',
    seller_id: '119',
    buyer_id: '0',
    first_name: 'Jane',
    middle_name: 'Seven',
    last_name: 'Doe',
    amount: '23000.00',
    fee: '690.00',
    email: 'janedoe@ymail.com',
    mobile_no: '09123456789',
    sku: '',
    remarks: 'test remarks',
    status: 'Refunded',
    refund_flag: '',
    payment_date: '0000-00-00 00:00:00',
    cancel_date: '0000-00-00 00:00:00',
    for_refund_date: '0000-00-00 00:00:00',
    refund_date: '0000-00-00 00:00:00',
    create_date: '2017-05-29 17:24:19',
    update_date: '2017-05-29 17:24:19',
  },
  {
    id: '13',
    reference_no: '9C7FBAF0C44A9',
    seller_id: '119',
    buyer_id: '0',
    first_name: 'Jane',
    middle_name: 'Seven',
    last_name: 'Doe',
    amount: '23000.00',
    fee: '690.00',
    email: 'janedoe@ymail.com',
    mobile_no: '09123456789',
    sku: '',
    remarks: 'test remarks',
    status: 'Cancelled',
    refund_flag: '',
    payment_date: '0000-00-00 00:00:00',
    cancel_date: '0000-00-00 00:00:00',
    for_refund_date: '0000-00-00 00:00:00',
    refund_date: '0000-00-00 00:00:00',
    create_date: '2017-09-26 10:44:22',
    update_date: '2017-09-26 10:44:22',
  },
  {
    id: '14',
    reference_no: 'B716A304D2FA7',
    seller_id: '119',
    buyer_id: '0',
    first_name: 'Jane',
    middle_name: 'Seven',
    last_name: 'Doe',
    amount: '23000.00',
    fee: '690.00',
    email: 'janedoe@ymail.com',
    mobile_no: '09123456789',
    sku: '',
    remarks: 'test remarks',
    status: 'Cancelled',
    refund_flag: '',
    payment_date: '0000-00-00 00:00:00',
    cancel_date: '0000-00-00 00:00:00',
    for_refund_date: '0000-00-00 00:00:00',
    refund_date: '0000-00-00 00:00:00',
    create_date: '2017-05-29 17:24:19',
    update_date: '2017-05-29 17:24:19',
  },
  {
    id: '15',
    reference_no: 'B716A304D2FA8',
    seller_id: '119',
    buyer_id: '0',
    first_name: 'Jane',
    middle_name: 'Seven',
    last_name: 'Doe',
    amount: '23000.00',
    fee: '690.00',
    email: 'janedoe@ymail.com',
    mobile_no: '09123456789',
    sku: '',
    remarks: 'test remarks',
    status: 'Cancelled',
    refund_flag: '',
    payment_date: '0000-00-00 00:00:00',
    cancel_date: '0000-00-00 00:00:00',
    for_refund_date: '0000-00-00 00:00:00',
    refund_date: '0000-00-00 00:00:00',
    create_date: '2017-05-29 17:24:19',
    update_date: '2017-05-29 17:24:19',
  },
  {
    id: '16',
    reference_no: 'B716A304D2FA12',
    seller_id: '119',
    buyer_id: '0',
    first_name: 'Jane',
    middle_name: 'Seven',
    last_name: 'Doe',
    amount: '23000.00',
    fee: '690.00',
    email: 'janedoe@ymail.com',
    mobile_no: '09123456789',
    sku: '',
    remarks: 'test remarks',
    status: 'Cancelled',
    refund_flag: '',
    payment_date: '0000-00-00 00:00:00',
    cancel_date: '0000-00-00 00:00:00',
    for_refund_date: '0000-00-00 00:00:00',
    refund_date: '0000-00-00 00:00:00',
    create_date: '2017-05-29 17:24:19',
    update_date: '2017-05-29 17:24:19',
  },
];
