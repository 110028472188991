import * as yup from 'yup';

export const SendMoneyIntlDetailsSchema = yup.object().shape({
  countryOrigin: yup.string().required('Country of origin is required'),
  countryCode: yup.string().required('Country code is required'),
  bank: yup.string().required('Bank is required'),
  iban: yup.string().required('Ban is required'),
  bankType: yup.string().required('Beneficiary type is required'),
  companyName: yup.string().required('Company name is required'),
  mobileNo: yup.string().required('Mobile number is required'),
  senderFullName: yup.string().required('Sender full name is required'),
  purpose: yup.string().required('Purpose of sending is required'),
  fundSource: yup.string().required('Source of fund is required'),
  relationship: yup.string().required('Relationship to sender is required'),
  uploadedFile: yup
    .array()
    .of(
      yup.object().shape({
        fileId: yup.number(),
        fileName: yup.string(),
        fileSize: yup.string(),
        data: yup.mixed(),
      })
    )
    .min(1, 'Please upload a file'),
});

export const SendMoneyIntlIndiDetailsSchema = yup.object().shape({
  countryOrigin: yup.string().required('Country of origin is required'),
  countryCode: yup.string().required('Country code is required'),
  bank: yup.string().required('Bank is required'),
  iban: yup.string().required('Ban is required'),
  bankType: yup.string().required('Beneficiary type is required'),
  firstName: yup.string().required('First name is required'),
  middleName: yup.string().required('Middle name is required'),
  lastName: yup.string().required('Last name is required'),
  mobileNo: yup.string().required('Mobile number is required'),
  senderFullName: yup.string().required('Sender full name is required'),
  purpose: yup.string().required('Purpose of sending is required'),
  fundSource: yup.string().required('Source of fund is required'),
  relationship: yup.string().required('Relationship to sender is required'),
  uploadedFile: yup
    .array()
    .of(
      yup.object().shape({
        fileId: yup.number(),
        fileName: yup.string(),
        fileSize: yup.string(),
        data: yup.mixed(),
      })
    )
    .min(1, 'Please upload a file'),
});

export const SendMoneyIntlTransAmountSchema = yup.object().shape({
  walletFund: yup.string().required('Please select a transaction wallet'),
  currency: yup.string().required('Currency is required'),
  sendMoney: yup.string().required('Send Money is required'),
});

export const SendMoneyDomDetailsSchema = yup.object().shape({
  bank: yup.string().required('Select Bank/E-wallet is required'),
  accountNo: yup.string().required('Account number is required'),
  accountName: yup.string().required('Account name is required'),
  amount: yup
    .number()
    .required('Amount is required')
    .min(1, 'Amount should be minimum of 1'),
});
