import { Flex, Grid, Title, TextInput, Button, Text } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { BuyForexDetailsSchema } from '../../../schema/forex';
import { formatCurrency } from '../../../utils/currency';
import { getDateTime } from '../../../utils/date';
import { currencyWalletFund, sendFee } from '../../../mocks/send-money';
import { mockWallet } from '../../../mocks/wallet';
import AtomFlagWallet from '../../atoms/flag-wallet/FlagWallet';
import AtomGridDetails from '../../atoms/grid-details/GridDetails';
import phFlag from '../../../assets/images/ph-flag.svg';

const BuyForexDetails = ({ formData, onNextStep }) => {
  const buyForexDetailsForm = useForm({
    validate: yupResolver(BuyForexDetailsSchema),
    initialValues: formData,
  });
  const { onSubmit, getInputProps, values } = buyForexDetailsForm;

  const calculateTransFees = () => {
    const { buyAmountPHP, receiveAmountUSD } = values;
    const fees = {
      amountReceived: receiveAmountUSD,
      total: buyAmountPHP ? parseFloat(buyAmountPHP) + sendFee.PHP : 0,
    };

    return fees;
  };

  const handleSubmit = (data) => {
    const calculatedFees = calculateTransFees();
    onNextStep({ ...data, ...calculatedFees });
  };

  return (
    <Flex direction='column' className='white-wrapper'>
      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid w='100%'>
          <Grid.Col sm={6}>
            <Title order={4} className='page-h4-title'>
              Buy Details
            </Title>
            <TextInput
              label='Amount to Buy (PHP)'
              type='number'
              mt={16}
              {...getInputProps('buyAmountPHP')}
            />
            <TextInput
              label='Amount to Receive (USD)'
              type='number'
              mt={16}
              {...getInputProps('receiveAmountUSD')}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <Title order={4} className='page-h4-title'>
              Transaction Wallet
            </Title>
            <span className='span-sub-text'>
              Funds for this transaction will be deducted from this wallet.
            </span>
            <Flex justify='space-between' className='purple-wrapper'>
              <AtomFlagWallet
                label='PHP Wallet'
                value={formatCurrency('PHP', currencyWalletFund.PHP)}
                flagImg={phFlag}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col>
            <Title order={4} className='page-h4-title'>
              Summary
            </Title>
            <Flex
              align='center'
              justify='space-between'
              className='purple-wrapper'
              sx={{ padding: '0 !important' }}
            >
              <Flex direction='column' p={16}>
                <Text>Exchange Rate</Text>
                <span className='span-sub-text'>
                  {getDateTime(mockWallet.create_date, 'LLL dd, yyyy - t')}
                </span>
              </Flex>
              <Title
                order={4}
                className='page-h4-title'
                sx={{
                  backgroundColor: '#D3CAE2',
                  borderRadius: '8px',
                  lineHeight: '2.6rem',
                  padding: '1rem',
                }}
              >
                PHP 1 = USD 0.018
              </Title>
            </Flex>
            <Flex
              direction='column'
              justify='space-between'
              className='purple-wrapper'
              sx={{ marginTop: 0 }}
            >
              <AtomGridDetails
                details={[
                  {
                    label: 'Amount to Receive',
                    value: formatCurrency(
                      'USD',
                      calculateTransFees().amountReceived
                    ),
                    isDark: true,
                  },
                  {
                    label: 'Total Amount to Pay',
                    value: formatCurrency('PHP', calculateTransFees().total),
                    isDark: true,
                    isBold: true,
                  },
                ]}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col
            mt={16}
            display='flex'
            sx={(theme) => ({
              alignItems: 'center',
              justifyContent: 'flex-end',
              [theme.fn.smallerThan('md')]: {
                justifyContent: 'flex-start',
              },
            })}
          >
            <Button type='submit' className='yellow-btn'>
              Next
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Flex>
  );
};

export default BuyForexDetails;
