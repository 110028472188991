import { Grid, Flex, Text, Button, Container, ActionIcon } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import {
  IconPlus,
  IconTransferOut,
  IconChevronLeft,
  IconChevronRight,
} from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { RoutePaths } from '../../../constants/routes';
import AtomTable from '../../atoms/table/Table';
import BankCard from './BankCard';
import { accountCards, accountTrans } from '../../../mocks/bank-account';

export default () => {
  const navigate = useNavigate();
  const [bankCard] = useState(accountCards);
  const [bankTrans] = useState(accountTrans);

  const rows = bankTrans.map((element) => {
    return [
      {
        content: <Text>{element.referenceNo}</Text>,
      },
      {
        content: <Text c='blue'>{element.type}</Text>,
      },
      {
        content: <Text>{element.bankName}</Text>,
      },
      {
        content: <Text c='red'>{element.amount}</Text>,
      },
      {
        content: <Text>{element.TransDate}</Text>,
      },
    ];
  });
  return (
    <Grid>
      <Grid.Col xs={12} md={4}>
        <Text>Total Accounts: {bankCard.length}</Text>
      </Grid.Col>
      <Grid.Col xs={12} md={8}>
        <Flex justify='flex-end' align='center' direction='row' gap='xs'>
          <Button
            className='yellow-btn'
            leftIcon={<IconTransferOut size='1.2rem' />}
            component={Link}
            onClick={() => navigate(`${RoutePaths.BankAccountTrans}`)}
          >
            Bank Transfer
          </Button>
          <Button
            className='yellow-btn'
            leftIcon={<IconPlus size='1.2rem' />}
            component={Link}
            onClick={() => navigate(`${RoutePaths.BankAccountAdd}`)}
          >
            Add Bank Account
          </Button>
        </Flex>
      </Grid.Col>
      <Grid.Col span={12}>
        <Carousel
          withIndicators
          slideSize='33.333333%'
          slideGap='xs'
          align='start'
          slidesToScroll='auto'
        >
          {bankCard.map((el) => (
            <Carousel.Slide>
              <BankCard
                logo={el.bankLogo}
                name={el.fullName}
                bankName={el.bankName}
                bankNo={el.bankNum}
              />
            </Carousel.Slide>
          ))}
        </Carousel>
      </Grid.Col>
      <Grid.Col span={12}>
        <Container className='page-wrapper'>
          <AtomTable
            headers={[
              'Reference No.',
              'Type',
              'Banck Account',
              'Amount',
              'Transaction Date',
            ]}
            rowData={rows}
          />
          <Grid pt='md' pb='sm'>
            <Grid.Col span={12}>
              <Flex justify='flex-end' align='center' direction='row' gap='xs'>
                <Text fz='sm'>
                  {/* {page + 1}-{page + limit} of {employee.total} */}
                </Text>
                <ActionIcon>
                  <IconChevronLeft size='1.3rem' />
                </ActionIcon>
                <ActionIcon>
                  <IconChevronRight size='1.3rem' />
                </ActionIcon>
              </Flex>
            </Grid.Col>
          </Grid>
        </Container>
      </Grid.Col>
    </Grid>
  );
};
