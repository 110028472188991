import { useState } from 'react';
import {
  Flex,
  Grid,
  Text,
  Image,
  Group,
  ActionIcon,
  Button,
  MediaQuery,
} from '@mantine/core';
import {
  IconSquareX,
  IconUserCircle,
  IconStar,
  IconCash,
} from '@tabler/icons-react';
import { formatCurrency } from '../../../utils/currency';
import ProcessedPayrollModal from './ProcessedPayrollModal';
import uploadedCSVFile from '../../../assets/images/uploaded-csv.svg';

const UploadedPayrollDetails = ({ fileName, fileSize, data, onReset }) => {
  const [showProcessedModal, setProcessedModalvisibility] = useState(false);

  const getPayrollSalarySum = () => {
    let sum = 0;
    data.forEach((payroll) => {
      sum += payroll.salary;
    });

    return sum;
  };

  return (
    <>
      <Grid w='100%' maw='700px'>
        <Grid.Col>
          <Flex
            align='center'
            justify='space-between'
            className='purple-wrapper'
            sx={{ marginTop: '0 !important' }}
          >
            <Grid w='100%'>
              <Grid.Col sm={6}>
                <Flex gap='md'>
                  <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
                    <Image
                      src={uploadedCSVFile}
                      width={50}
                      height={60}
                      alt='Uploaded CSV file'
                    />
                  </MediaQuery>
                  <Grid w='100%' gutter={5}>
                    <Grid.Col>
                      <Text truncate>{fileName}</Text>
                    </Grid.Col>
                    <Grid.Col>
                      <Text className='span-sub-text'>
                        Completed - {fileSize}
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Flex>
              </Grid.Col>
              <Grid.Col
                sm={6}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
                  <ActionIcon color='red' onClick={onReset}>
                    <IconSquareX />
                  </ActionIcon>
                </MediaQuery>
                <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
                  <Button
                    color='red'
                    variant='subtle'
                    fullWidth
                    onClick={onReset}
                  >
                    Remove
                  </Button>
                </MediaQuery>
              </Grid.Col>
            </Grid>
          </Flex>
        </Grid.Col>
        <Grid.Col sm={6}>
          <Flex
            direction='column'
            align='center'
            justify='center'
            className='purple-wrapper'
            sx={{ marginTop: '0 !important', height: '250px' }}
          >
            <Group sx={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <IconUserCircle size={48} />
              <Text>Total Employees</Text>
            </Group>
            <Text size={32} fw={700} mt={16}>
              {data.length}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col sm={6}>
          <Flex
            direction='column'
            align='center'
            justify='center'
            className='purple-wrapper'
            sx={{ marginTop: '0 !important', height: '250px' }}
          >
            <Group sx={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <IconStar size={48} />
              <Text>Total Salary</Text>
            </Group>
            <Text size={32} fw={700} mt={16}>
              {formatCurrency('PHP', getPayrollSalarySum())}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col
          sx={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}
        >
          <Button
            leftIcon={<IconCash />}
            className='yellow-btn'
            onClick={() => setProcessedModalvisibility(true)}
          >
            Process Payroll
          </Button>
        </Grid.Col>
      </Grid>
      <ProcessedPayrollModal
        visible={showProcessedModal}
        onClose={() => {
          setProcessedModalvisibility(false);
          onReset();
        }}
      />
    </>
  );
};

export default UploadedPayrollDetails;
