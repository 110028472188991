import React from 'react';
import ReactDOM from 'react-dom/client';
import { SWRConfig } from 'swr';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { httpErrorMsgHandler } from './hooks/errorHandler';
import configureMantineTheme from './assets/styles/mantineTheme';
import RouteProvider from './routes/RouteProvider';
import reportWebVitals from './reportWebVitals';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        use: [httpErrorMsgHandler],
        shouldRetryOnError: false,
      }}
    >
      <MantineProvider
        theme={configureMantineTheme}
        withGlobalStyles
        withNormalizeCSS
      >
        <Notifications position='top-right' zIndex={2000} />
        <RouteProvider />
      </MantineProvider>
    </SWRConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
