import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  addFundWalletWrapper: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.fn.smallerThan('sm')]: {
      justifyContent: 'center',
    },
  },
  addFundBtnWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.fn.smallerThan('sm')]: {
      justifyContent: 'center',
      button: {
        width: '100%',
      },
    },
  },
}));

export default useStyles;
