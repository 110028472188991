export const initialSendMoneyIntlFormData = {
  countryOrigin: '',
  countryCode: '',
  bank: '',
  iban: '',
  bankType: 'Individual',
  companyName: '',
  mobileNo: '',
  senderFullName: 'John Doe',
  purpose: '',
  fundSource: '',
  relationship: '',
  uploadedFile: [],
  walletFund: '',
  currency: '',
  sendMoney: '',
  currencyFee: 0,
  currencyWalletFund: 0,
  equivalent: 0,
  total: 0,
};

export const originCountryOptions = [
  { value: 'Singapore', label: 'Singapore' },
];

export const bankOptions = [
  { value: 'Bank of Singapore', label: 'Bank of Singapore' },
];

export const sendPurposeOptions = [
  { value: 'Fund Transfer', label: 'Fund Transfer' },
];

export const sendRelationshipOptions = [{ value: 'Family', label: 'Family' }];

export const currencyOptions = [{ value: 'SGD', label: 'SGD' }];

export const initialSendMoneyDomFormData = {
  bank: '',
  accountNo: '',
  accountName: '',
  amount: 0,
};
