import EmployeeList from '../../organisms/employee/EmployeeList';
import EmployeeInfo from '../../organisms/employee/EmployeeInfo';

export const EmployeeListPage = () => {
  return <EmployeeList />;
};

export const EmployeeInfoPage = () => {
  return <EmployeeInfo />;
};
