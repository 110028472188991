import { Flex, Grid, Title, Text, Button } from '@mantine/core';
import { formatCurrency } from '../../../utils/currency';
import { getDateTime } from '../../../utils/date';
import { currencyWalletFund } from '../../../mocks/send-money';
import { mockWallet } from '../../../mocks/wallet';
import AtomGridDetails from '../../atoms/grid-details/GridDetails';
import AtomFlagWallet from '../../atoms/flag-wallet/FlagWallet';
import usFlag from '../../../assets/images/us-flag.svg';

const SellForexConfirm = ({ formData, onPrevStep, onConfirm }) => {
  return (
    <Flex direction='column' className='white-wrapper'>
      <Grid w='100%'>
        <Grid.Col sm={6}>
          <Title order={4} className='page-h4-title'>
            Sell Details
          </Title>
          <Flex
            direction='column'
            justify='space-between'
            className='purple-wrapper'
          >
            <AtomGridDetails
              details={[
                {
                  label: 'Amount to Sell (USD)',
                  value: formatCurrency('USD', formData.sellAmountUSD),
                },
                {
                  label: 'Amount to Receive (PHP)',
                  value: formatCurrency('PHP', formData.receiveAmountPHP),
                },
              ]}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col sm={6}>
          <Title order={4} className='page-h4-title'>
            Transaction Wallet
          </Title>
          <Flex justify='space-between' className='purple-wrapper'>
            <AtomFlagWallet
              label='USD Wallet'
              value={formatCurrency('PHP', currencyWalletFund.USD)}
              flagImg={usFlag}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col>
          <Title order={4} className='page-h4-title'>
            Summary
          </Title>
          <Flex
            align='center'
            justify='space-between'
            className='purple-wrapper'
            sx={{ padding: '0 !important' }}
          >
            <Flex direction='column' p={16}>
              <Text>Exchange Rate</Text>
              <span className='span-sub-text'>
                {getDateTime(mockWallet.create_date, 'LLL dd, yyyy - t')}
              </span>
            </Flex>
            <Title
              order={4}
              className='page-h4-title'
              sx={{
                backgroundColor: '#D3CAE2',
                borderRadius: '8px',
                lineHeight: '2.6rem',
                padding: '1rem',
              }}
            >
              USD 1 = PHP 55.39
            </Title>
          </Flex>
          <Flex
            direction='column'
            justify='space-between'
            className='purple-wrapper'
            sx={{ marginTop: 0 }}
          >
            <AtomGridDetails
              details={[
                {
                  label: 'Amount to Receive',
                  value: formatCurrency('PHP', formData.amountReceived),
                  isDark: true,
                },
                {
                  label: 'Total Amount to Pay',
                  value: formatCurrency('USD', formData.total),
                  isDark: true,
                  isBold: true,
                },
              ]}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col
          mt={16}
          display='flex'
          sx={(theme) => ({
            alignItems: 'center',
            gap: '5px',
            justifyContent: 'flex-end',
            [theme.fn.smallerThan('md')]: {
              justifyContent: 'flex-start',
            },
          })}
        >
          <Button color='ubsBtnExitColor.3' c='black' onClick={onPrevStep}>
            Back
          </Button>
          <Button onClick={onConfirm} className='yellow-btn'>
            Confirm
          </Button>
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

export default SellForexConfirm;
