import { Flex, Grid, Title, Text, Image, Button } from '@mantine/core';
import { formatCurrency } from '../../../utils/currency';
import { currencyWalletFund } from '../../../mocks/send-money';
import AtomGridDetails from '../../atoms/grid-details/GridDetails';
import AtomFlagWallet from '../../atoms/flag-wallet/FlagWallet';
import phFlag from '../../../assets/images/ph-flag.svg';
import instaPayLogo from '../../../assets/images/instapay-logo.svg';

const SendMoneyDomConfirm = ({ formData, onPrevStep, onConfirm }) => {
  return (
    <Flex direction='column' className='white-wrapper'>
      <Grid w='100%'>
        <Grid.Col sm={6}>
          <Title order={4} className='page-h4-title'>
            Recipient Details
          </Title>
          <Flex
            direction='column'
            justify='space-between'
            className='purple-wrapper'
          >
            <AtomGridDetails
              details={[
                {
                  label: 'Bank/E-wallet',
                  value: formData.bank,
                },
                {
                  label: 'Account Number',
                  value: formData.accountNo,
                },
                {
                  label: 'Account Name',
                  value: formData.accountName,
                },
              ]}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col sm={6}>
          <Title order={4} className='page-h4-title'>
            Transaction Amount
          </Title>
          <Flex
            direction='column'
            justify='space-between'
            className='purple-wrapper'
            mt={16}
          >
            <AtomGridDetails
              details={[
                {
                  label: 'Amount Sent',
                  value: formatCurrency('PHP', formData.amount),
                },
                {
                  label: 'Send Fee',
                  value: formatCurrency('PHP', formData.fee),
                },
                {
                  label: 'Total',
                  value: formatCurrency('PHP', formData.total),
                  isBold: true,
                },
              ]}
            />
          </Flex>
          <Title order={4} className='page-h4-title' mt={16}>
            Transaction Wallet
          </Title>
          <Flex justify='space-between' className='purple-wrapper'>
            <AtomFlagWallet
              label='PHP Wallet'
              value={formatCurrency('PHP', currencyWalletFund.PHP)}
              flagImg={phFlag}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col
          mt={32}
          display='flex'
          sx={(theme) => ({
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.fn.smallerThan('md')]: {
              flexDirection: 'column',
              alignItems: 'flex-start',
            },
          })}
        >
          <Flex align='center'>
            <Text mr={10}>Sent via</Text>
            <Image src={instaPayLogo} width={150} height={39} />
          </Flex>
          <Flex gap={10}>
            <Button color='ubsBtnExitColor.3' c='black' onClick={onPrevStep}>
              Back
            </Button>
            <Button onClick={onConfirm} className='yellow-btn'>
              Confirm
            </Button>
          </Flex>
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

export default SendMoneyDomConfirm;
