import { useState } from 'react';
import { Flex, Title, rem } from '@mantine/core';
import { MIME_TYPES } from '@mantine/dropzone';
import * as XLSX from 'xlsx';
import { bytesToSize } from '../../../utils/file';
import AtomToast from '../../atoms/toast/Toast';
import AtomDropzone from '../../atoms/dropzone/Dropzone';
import UploadedPayrollDetails from './UploadedPayrollDetails';

const UploadPayrollDropzone = () => {
  const [errFileToastMsg, setErrFileToastVisibility] = useState('');
  const initialDropzoneState = {
    fileName: '',
    fileSize: 0,
    data: [],
  };
  const [extractedData, setExtractedData] = useState(initialDropzoneState);
  const hasDroppedFile = !!extractedData.data.length;

  const handleOnDropFile = (files) => {
    const fileName = files[0].path;
    const fileSize = bytesToSize(files[0].size);
    const reader = new FileReader();
    reader.readAsBinaryString(files[0]);

    reader.onload = (e) => {
      const data = e.target.result;
      const workBook = XLSX.read(data, { type: 'binary' });
      const sheetName = workBook.SheetNames[0];
      const sheet = workBook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      setExtractedData({ fileName, fileSize, data: parsedData });
    };
  };

  const handleOnRejectDropFile = () => {
    setErrFileToastVisibility(
      'File selected is invalid! Please select a valid excel file.'
    );
  };

  const handleResetDropdzone = () => {
    setExtractedData(initialDropzoneState);
  };

  return (
    <Flex direction='column'>
      <Title order={4} my={16} className='page-h4-title'>
        Upload Payroll File
      </Title>
      <AtomDropzone
        onDrop={handleOnDropFile}
        onReject={handleOnRejectDropFile}
        fileTypes={[MIME_TYPES.csv, MIME_TYPES.xlsx]}
        fileDescription='Select a CSV File to Upload'
        hasDroppedFile={hasDroppedFile}
        minHeight={rem(400)}
        onDropContent={
          <UploadedPayrollDetails
            {...extractedData}
            onReset={handleResetDropdzone}
          />
        }
      />
      <AtomToast
        message={errFileToastMsg}
        mode='error'
        onClose={() => setErrFileToastVisibility('')}
      />
    </Flex>
  );
};

export default UploadPayrollDropzone;
