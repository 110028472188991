import { createStyles, rem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  settingFormContainer: {
    backgroundColor: theme.colors.ubsDashboard[0],
    borderRadius: rem(8),
  },
}));

export default useStyles;
