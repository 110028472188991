import BankAccountMain from '../../organisms/bank-account/BankAccountMain';
import BankAccountAdd from '../../organisms/bank-account/BankAccountAdd';
import BankAccountTrans from '../../organisms/bank-account/BankAccountTrans';

export const BankAccountMainPage = () => {
  return <BankAccountMain />;
};

export const BankAccountAddPage = () => {
  return <BankAccountAdd />;
};

export const BankAccountTransPage = () => {
  return <BankAccountTrans />;
};
