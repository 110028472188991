import { Container, Title, Button, Grid } from '@mantine/core';
import { Link } from 'react-router-dom';
import { IconAlarm } from '@tabler/icons-react';
import { formatCurrency } from '../../../utils/currency';
import { getDateTime } from '../../../utils/date';
import { RoutePaths } from '../../../constants/routes';
import AtomTable from '../../atoms/table/Table';
import useStyles from '../../pages/dashboard/DashboardPage.styles';

const DashboardList = ({ transactions, setTransaction }) => {
  const { classes } = useStyles();
  const tableRowData = transactions.map((transaction) => [
    { content: transaction.referenceNo },
    {
      content: (
        <Button
          variant='subtle'
          className={classes.dashboardHistoryBtn}
          px={0}
          onClick={() => setTransaction(transaction)}
        >
          {transaction.type}
        </Button>
      ),
    },
    {
      className: transaction.increment ? 'plusTd' : 'minusTd',
      content: formatCurrency(transaction.currency, transaction.amount),
    },
    {
      content: getDateTime(transaction.create_date, 'yyyy-LL-dd t'),
    },
  ]);

  return (
    <Container className='page-wrapper'>
      <Grid justify='center' w='100%'>
        <Grid.Col sm={6}>
          <Title order={4} className='page-h4-title'>
            Latest Transactions
          </Title>
        </Grid.Col>
        <Grid.Col
          sm={6}
          display='flex'
          sx={(theme) => ({
            justifyContent: 'flex-end',
            [theme.fn.smallerThan('md')]: {
              justifyContent: 'flex-start',
            },
          })}
        >
          <Button
            leftIcon={<IconAlarm />}
            variant='subtle'
            component={Link}
            to={RoutePaths.Report}
            className={classes.dashboardHistoryBtn}
          >
            Request Transaction History
          </Button>
        </Grid.Col>
        <Grid.Col span={12}>
          <AtomTable
            headers={['Reference No.', 'Type', 'Amount', 'Transaction Date']}
            rowData={tableRowData}
          />
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default DashboardList;
