import AtomStepper from '../../atoms/stepper/Stepper';

const SignUpStepper = ({ activeStep }) => {
  return (
    <AtomStepper
      steps={['Personal Details', 'Business Details']}
      activeStep={activeStep}
      hasWrapper={false}
    />
  );
};

export default SignUpStepper;
