import { Flex, Grid, Title, TextInput, Button } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { CreateCustomerDetailSchema } from '../../../../schema/payment';

const CustomerDetails = ({ formData, onNextStep, onCancel }) => {
  const createCustomerDetailsForm = useForm({
    validate: yupResolver(CreateCustomerDetailSchema),
    initialValues: formData,
  });
  const { onSubmit, getInputProps } = createCustomerDetailsForm;

  const handleSubmit = (data) => {
    onNextStep(data);
  };

  return (
    <Flex direction='column' className='white-wrapper'>
      <Title order={4} className='page-h4-title' mb={16}>
        Customer Details
      </Title>
      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid w='100%' mb={16}>
          <Grid.Col sm={4}>
            <TextInput label='First Name' {...getInputProps('firstName')} />
          </Grid.Col>
          <Grid.Col sm={4}>
            <TextInput label='Middle Name' {...getInputProps('middleName')} />
          </Grid.Col>
          <Grid.Col sm={4}>
            <TextInput label='Last Name' {...getInputProps('lastName')} />
          </Grid.Col>
          <Grid.Col sm={4}>
            <TextInput label='Mobile Number' {...getInputProps('mobileNo')} />
          </Grid.Col>
          <Grid.Col sm={8}>
            <TextInput label='Email Address' {...getInputProps('email')} />
          </Grid.Col>
          <Grid.Col
            mt={16}
            display='flex'
            sx={(theme) => ({
              alignItems: 'center',
              gap: '5px',
              justifyContent: 'flex-end',
              [theme.fn.smallerThan('md')]: {
                justifyContent: 'flex-start',
              },
            })}
          >
            <Button color='ubsBtnExitColor.3' c='black' onClick={onCancel}>
              Cancel
            </Button>
            <Button type='submit' className='yellow-btn'>
              Next
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Flex>
  );
};

export default CustomerDetails;
