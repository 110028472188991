import { createStyles, rem } from '@mantine/core';

const textStyle = {
  fontWeight: 400,
  fontSize: '1em',
  lineHeight: '2em',
};

const commonWrapper = {
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  gap: rem(64),
  width: '100%',
  maxWidth: '75em',
  padding: rem(64),
};

const useStyles = createStyles((theme) => ({
  merchantServBanner: {
    backgroundSize: 'auto',
    backgroundRepeat: 'no-repeat',
    height: '800px',
  },
  merchantMainWrapper: {
    alignItems: 'flex-start',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    marginLeft: '-15rem',
    width: '100%',
    [theme.fn.smallerThan('lg')]: {
      marginLeft: 0,
    },
  },
  merchantFlexWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: rem(16),
    width: '100%',
    maxWidth: '37.5em',
    height: '100%',
    padding: '1em',

    [theme.fn.smallerThan('sm')]: {
      marginLeft: 0,
      padding: rem(16),
    },
  },
  merchantH5: {
    ...textStyle,
    textTransform: 'uppercase',
  },
  merchantH3: {
    fontWeight: 700,
    fontSize: '2.23em',
    lineHeight: '1em',
    [theme.fn.smallerThan('sm')]: {
      fontSize: '1.75em',
    },
  },
  merchantText: { ...textStyle },
  merchantTextBold: {
    ...textStyle,
    fontWeight: 600,
  },
  chooseFlexWrapper: {
    ...commonWrapper,
    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
      padding: rem(32),
      gap: rem(32),
    },
  },
  chooseUbsBanner: {
    width: '35.125em',
    height: '100%',
    [theme.fn.smallerThan('md')]: {
      width: '28.125em',
    },
  },
  chooseUbsTxtWrapper: {
    padding: '0.5rem 0',
  },
  chooseUbsDescMainTxt: {
    ...textStyle,
    fontWeight: 600,
    fontSize: '1.25em',
    lineHeight: '1.375em',
    marginBottom: '0.3125em',
  },
  chooseUbsBtnSubTxt: {
    fontWeight: 'bolder',
    margin: '0 0.25rem',
  },
  manageFundWrapper: {
    height: '100%',
    position: 'relative',
    width: '100%',
  },
  manageFundHeaderWrapper: {
    background: theme.colors.ubsPrimary[6],
    borderRadius: '0px 0px 211px 211px',
    height: '100%',
    left: 0,
    maxHeight: '32.1875em',
    paddingTop: '2rem',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
    [theme.fn.smallerThan('md')]: {
      borderRadius: 0,
      height: 'auto',
      padding: '1.25em 0',
      position: 'relative',
    },
  },
  manageFundCard: {
    height: '100%',
  },
  manageFundCardWrapper: {
    marginTop: '6em',
    maxWidth: '75em',
    position: 'relative',
    width: '100%',
    zIndex: 2,
    [theme.fn.smallerThan('md')]: {
      marginTop: 0,
    },
  },
  faqCollapseBtn: {
    background: theme.colors.ubsHighlight[6],
    color: '#000000',
    fontSize: '1em',
    fontVariant: 'all-small-caps',
    fontWeight: 600,
    height: 'auto',
    padding: '1.5rem',
    ':hover': {
      background: theme.colors.ubsHighlight[6],
    },
    div: {
      justifyContent: 'space-between',
    },
    span: {
      whiteSpace: 'pre-wrap',
    },
  },
  faqCollapseWrapper: {
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    marginBottom: '1rem',
  },
  faqCollapseContent: {
    padding: '1em 1.5em',
  },
  faqCollapseColTitle: {
    fontSize: '1.5em',
    fontVariant: 'all-small-caps',
    lineHeight: '1.75em',
    marginBottom: '0.5em',
  },
  faqContentIcon: {
    color: theme.colors.ubsPrimary[6],
    width: '3em',
    height: '2em',
    [theme.fn.smallerThan('xs')]: {
      width: '1em',
    },
  },
  footerWrapper: {
    width: '100%',
    background: theme.colors.ubsPrimary[6],
  },
  footerContentWrapper: {
    ...commonWrapper,
  },
  footerMainTxt: {
    color: '#FFFFFF',
    fontSize: '2.5em',
    fontVariant: 'all-small-caps',
  },
  footerTxt: {
    ...textStyle,
    color: '#FFFFFF',
  },
  footerLogo: {
    marginLeft: 0,
    [theme.fn.largerThan('md')]: {
      marginLeft: '25%',
      marginTop: '5.5em',
    },
  },
}));

export default useStyles;
