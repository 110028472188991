import { Grid, Title, Flex, Text } from '@mantine/core';
import { formatCurrency } from '../../../../utils/currency';
import { getDateTime } from '../../../../utils/date';
import { DownloadBtn } from '../DashboardListModal';
import AtomFlagWallet from '../../../atoms/flag-wallet/FlagWallet';
import AtomGridDetails from '../../../atoms/grid-details/GridDetails';
import phFlag from '../../../../assets/images/ph-flag.svg';
import useStyles from '../../../pages/dashboard/DashboardPage.styles';

const BuyForexContent = ({ transaction }) => {
  const { classes } = useStyles();

  return (
    <Flex direction='column' align='center' w='100%'>
      <Grid justify='space-between' w='100%'>
        <Grid.Col md={6}>
          <Title order={3} className={classes.modalContentTitle}>
            Buy Details
          </Title>
          <Flex
            direction='column'
            justify='space-between'
            className='purple-wrapper'
          >
            <AtomGridDetails
              details={[
                {
                  label: 'Amount to Buy (PHP)',
                  value: formatCurrency('PHP', transaction.domesticAmount),
                },
                {
                  label: 'Amount to Receive (USD)',
                  value: formatCurrency('USD', transaction.interAmount),
                },
              ]}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col md={6}>
          <Title order={3} className={classes.modalContentTitle}>
            Transaction Wallet
          </Title>
          <Flex justify='space-between' className='purple-wrapper'>
            <AtomFlagWallet
              label='PHP Wallet'
              value={formatCurrency('PHP', transaction.currentWallet)}
              flagImg={phFlag}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col span={12}>
          <Title order={3} className={classes.modalContentTitle}>
            Summary
          </Title>
          <Flex
            align='center'
            justify='space-between'
            className='purple-wrapper'
            sx={{ padding: '0 !important' }}
          >
            <Flex direction='column' p={16}>
              <Text>Exchange Rate</Text>
              <span className='span-sub-text'>
                {getDateTime(transaction.create_date, 'LLL dd, yyyy - t')}
              </span>
            </Flex>
            <Title
              order={4}
              className='page-h4-title'
              sx={{
                backgroundColor: '#D3CAE2',
                borderRadius: '8px',
                lineHeight: '2.6rem',
                padding: '1rem',
              }}
            >
              PHP 1 = USD 0.018
            </Title>
          </Flex>
        </Grid.Col>
        <Grid.Col span={12}>
          <Flex
            direction='column'
            justify='space-between'
            className='purple-wrapper'
            sx={{ marginTop: 0 }}
          >
            <AtomGridDetails
              details={[
                {
                  label: 'Amount Received',
                  value: formatCurrency('USD', transaction.interAmount),
                  isDark: true,
                },
                {
                  label: 'Total Amount Paid',
                  value: formatCurrency('PHP', transaction.domesticAmount),
                  isDark: true,
                  isBold: true,
                },
              ]}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col
          mt={16}
          display='flex'
          sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <DownloadBtn />
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

export default BuyForexContent;
