import { useState } from 'react';
import { Flex, Title, Text, Button, Collapse, Grid } from '@mantine/core';
import {
  IconCircleChevronDown,
  IconCircleChevronRight,
  IconChevronsRight,
} from '@tabler/icons-react';
import { RoutePaths } from '../../../constants/routes';
import useStyles from '../../pages/landing/LandingPage.styles';

const FAQ = () => {
  const [activeCollapse, setActiveCollapse] = useState({
    visible: false,
    queue: 0,
  });
  const { classes } = useStyles();

  const renderFaqMainContent = (header, description) => {
    return (
      <Flex direction='column' align='center' justify='center'>
        <Text
          className={classes.chooseUbsDescMainTxt}
          align='center'
          tt='uppercase'
          mb='sm'
        >
          {header}
        </Text>
        <Text className={classes.merchantText} align='center'>
          {description}
        </Text>
      </Flex>
    );
  };

  const renderFaqCollapse = (collapseQueue, header, contents) => {
    const showCollapseContent =
      activeCollapse.visible && activeCollapse.queue === collapseQueue;
    return (
      <Flex w='100%' direction='column' className={classes.faqCollapseWrapper}>
        <Button
          fullWidth
          tt='uppercase'
          className={classes.faqCollapseBtn}
          rightIcon={
            showCollapseContent ? (
              <IconCircleChevronRight />
            ) : (
              <IconCircleChevronDown />
            )
          }
          onClick={() =>
            setActiveCollapse({
              visible: !activeCollapse.visible,
              queue: collapseQueue,
            })
          }
        >
          {header}
        </Button>
        <Collapse
          in={showCollapseContent}
          className={classes.faqCollapseContent}
        >
          {contents.map((content, index) => (
            <Grid key={index} gutter={16}>
              <Grid.Col span={1}>
                <IconChevronsRight className={classes.faqContentIcon} />
              </Grid.Col>
              <Grid.Col span={11}>
                <Text className={classes.merchantText}>{content}</Text>
              </Grid.Col>
            </Grid>
          ))}
        </Collapse>
      </Flex>
    );
  };

  return (
    <Flex
      id={RoutePaths.FAQHash.replace('/#', '')}
      direction='column'
      align='center'
      justify='center'
      className={classes.chooseFlexWrapper}
    >
      <Title order={3} className={classes.merchantH3}>
        FAQS
      </Title>
      {renderFaqMainContent(
        'How do I ensure that my account is safe upon cash out?',
        'Once a merchant requests for a branch Cash Out using their account, a one-time password (OTP) will be sent to them through SMS (the mobile number will be provided by the Merchant Owner) that will be valid for 24 hours. The password must then be presented to the FLA upon cash out. Once a cash out is successful, the FLA will give a proof of the cash out to the merchant. Signatures must be affixed thereafter.'
      )}
      {renderFaqMainContent(
        'What are the operating hours of a U Store/USSC branch?',
        'U Stores situated inside malls are open from 10:00 am to 9:00 pm from Monday to Sunday, while other U Store branches and kiosks are open from 8:00 am to 5:00 pm.'
      )}
      <Grid w='100%' gutter={0}>
        <Grid.Col md={6}>
          <Flex mx={5} direction='column' align='flex-start' justify='center'>
            <Title
              order={4}
              tt='uppercase'
              fw={400}
              className={classes.faqCollapseColTitle}
            >
              For Customers
            </Title>
            {renderFaqCollapse(1, 'How can I pay merchants using Pay1st?', [
              'You can pay an enrolled merchant at any of the USSC Super Service 850+ stores nationwide',
              'You can create a Pay1st account and make payments using the portal',
            ])}
            {renderFaqCollapse(2, 'How do I cash in/out?', [
              'You can pay an enrolled merchant at any of the USSC Super Service 850+ stores nationwide',
            ])}
          </Flex>
        </Grid.Col>
        <Grid.Col md={6}>
          <Flex mx={5} direction='column' align='flex-start' justify='center'>
            <Title
              order={4}
              tt='uppercase'
              fw={400}
              className={classes.faqCollapseColTitle}
            >
              For Merchants
            </Title>
            {renderFaqCollapse(3, 'How do I sign up?', [
              'Click on “Register” in the upper right menu or go to the nearest U Store branch near you. You will need a valid ID (required) and a business permit/DTI permit to register.',
            ])}
          </Flex>
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

export default FAQ;
