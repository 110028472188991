import {
  Grid,
  Flex,
  Input,
  Button,
  ActionIcon,
  Table,
  Text,
  Box,
  LoadingOverlay,
  FileButton,
  Container,
} from '@mantine/core';
import {
  IconPencil,
  IconCircleX,
  IconUserPlus,
  IconUsersPlus,
  IconSearch,
  IconChevronRight,
  IconChevronLeft,
  IconChevronDown,
  IconChecks,
} from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { RoutePaths } from '../../../constants/routes';
import userService from '../../../api/employee';
import { customLoader } from '../../atoms/loader/loader';
import Banner from '../../atoms/banner/Banner';
import useStyles from './EmployeeOrg.styles';
import mapperAPI from '../../../mapper';
import mapperType from '../../../utils/mapperType';

const fetcher = (url, data) =>
  userService.getEmpolyees(url, { data }).then((res) => res.data);

const EmployeeList = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [showProcessedModal, setProcessedModalvisibility] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [file, setFile] = useState(null);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState('A');
  const [search, setSearch] = useState(null);

  const redirect = (id) => {
    if (!id) return;
    navigate(`${RoutePaths.EmployeeList}/${id}`);
  };

  useEffect(() => {
    if (file) {
      setProcessedModalvisibility(true);
    }
  }, [file]);

  useEffect(() => {
    fetcher('/employee/get', { limit, page, status, search }).then((res) => {
      const mapAPI = res.data.map((el) => {
        return mapperAPI(el, mapperType.employeeMapper);
      });
      setEmployee({ data: mapAPI });
    });
  }, [limit, page, status, search]);

  if (!employee) {
    return (
      <LoadingOverlay
        loader={customLoader}
        overlayOpacity={0.3}
        overlayColor='#c5c5c5'
        visible
      />
    );
  }

  const rows = employee.data.map((element) => (
    <tr key={element.id}>
      <td>{element.id}</td>
      <td>{element.firstName}</td>
      <td>{element.middleName}</td>
      <td>{element.lastName}</td>
      <td>{element.birthday}</td>
      <td>{element.mobileNumber}</td>
      <td>
        <Text color={element.status === 'A' ? 'green' : 'red'}>
          {element.status === 'A' ? 'Active' : 'Inactive'}
        </Text>
      </td>
      <td>
        <Box hidden={element.status !== 'A'}>
          <Flex>
            <ActionIcon
              key={element.id}
              component={Link}
              onClick={() => redirect(element.id)}
            >
              <IconPencil size='1.3rem' />
            </ActionIcon>
            <ActionIcon color='red.4'>
              <IconCircleX size='1.3rem' />
            </ActionIcon>
          </Flex>
        </Box>
        <Box hidden={element.status !== 'I'}>
          <Flex>
            <ActionIcon color='green'>
              <IconChecks size='1.3rem' />
            </ActionIcon>
          </Flex>
        </Box>
      </td>
    </tr>
  ));
  return (
    <Grid>
      <Banner
        visible={showProcessedModal}
        onClose={() => {
          setProcessedModalvisibility(false);
        }}
        title='Information Save'
        message='Employee information has been successfully saved.'
      />
      <Grid.Col md={4}>
        <Input
          onChange={(e) => {
            setSearch(e.target.value);
            setStatus('All');
            setPage(0);
            setLimit(20);
          }}
          icon={<IconSearch size='1rem' />}
          placeholder='Search PK Number or Name'
        />
      </Grid.Col>
      <Grid.Col md={2}>
        <Input
          onChange={(e) => {
            setStatus(e.target.value);
            setPage(0);
            setLimit(20);
          }}
          defaultValue='A'
          component='select'
          rightSection={<IconChevronDown size={14} stroke={1.5} />}
        >
          <option value='All'>All</option>
          <option value='A'>Active</option>
          <option value='I'>Inactive</option>
        </Input>
      </Grid.Col>
      <Grid.Col md={6}>
        <Flex justify='flex-end' align='center' direction='row' gap='xs'>
          <Button
            className='yellow-btn'
            leftIcon={<IconUserPlus color='black' size='1rem' />}
            component={Link}
            onClick={() => redirect('new')}
          >
            Enroll Employee
          </Button>
          <FileButton onChange={setFile} accept='.csv, text/csv'>
            {(props) => (
              <Button
                className='yellow-btn'
                leftIcon={<IconUsersPlus color='black' size='1rem' />}
                {...props}
              >
                Batch Upload
              </Button>
            )}
          </FileButton>
        </Flex>
      </Grid.Col>
      <Grid.Col span={12}>
        <Container className={classes.tableWrapper}>
          <div className={classes.atomTableWrapper}>
            <Table
              striped
              highlightOnHover
              fontSize='xs'
              className={classes.atomTable}
            >
              <thead>
                <tr>
                  <th>PK Number</th>
                  <th>First Name</th>
                  <th>Middle Name</th>
                  <th>Last Name</th>
                  <th>Date of Birth</th>
                  <th>Mobile Number</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
          <Grid pt='md' pb='sm'>
            <Grid.Col span={12}>
              <Flex justify='flex-end' align='center' direction='row' gap='xs'>
                <Text fz='sm'>
                  {page + 1}-{page + limit} of {employee.total}
                </Text>
                <ActionIcon onClick={() => setPage(page - limit)}>
                  <IconChevronLeft size='1.3rem' />
                </ActionIcon>
                <ActionIcon onClick={() => setPage(page + limit)}>
                  <IconChevronRight size='1.3rem' />
                </ActionIcon>
              </Flex>
            </Grid.Col>
          </Grid>
        </Container>
      </Grid.Col>
    </Grid>
  );
};

export default EmployeeList;
