import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Flex, MediaQuery, BackgroundImage } from '@mantine/core';
import { initialSignupFormData } from '../../../constants/sign-up';
import { RoutePaths } from '../../../constants/routes';
import SignUpStepper from '../../organisms/sign-up/SignUpStepper';
import SignUpPersonalDetails from '../../organisms/sign-up/SignUpPersonalDetails';
import SignUpBusinessDetails from '../../organisms/sign-up/SignUpBusinessDetails';
import SignUpActivateCode from '../../organisms/sign-up/modals/SignUpActivateCode';
import SignUpDualAuth from '../../organisms/sign-up/modals/SignUpDualAuth';
import SignUpOTP from '../../organisms/sign-up/modals/SignUpOTP';
import SignupFileUpload from '../../organisms/sign-up/modals/SignupFileUpload';
import SignUpUnverified from '../../organisms/sign-up/modals/SignupUnverified';
import signupBanner from '../../../assets/images/signup-banner.svg';
import useStyles from '../login/LoginPage.styles';
import signupStyles from './SignUpPage.styles';

const SignUpPage = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [activeDialogStep, setActiveDialogStep] = useState(0);
  const [formData, setFormData] = useState(initialSignupFormData);
  const { classes } = useStyles();
  const { classes: signupClasses } = signupStyles();
  const navigate = useNavigate();

  const handleNextStep = (currFormData) => {
    setFormData({ ...formData, ...currFormData });
    setActiveStep(activeStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSignup = (data) => {
    setFormData(data);
    setActiveDialogStep(1);
  };

  const handleNextDialog = () => {
    const newDialoagStep = activeDialogStep + 1;
    setActiveDialogStep(newDialoagStep);
  };

  const handleResetActiveDialog = () => {
    setActiveDialogStep(0);
  };

  const handleFinishVerify = () => {
    setActiveDialogStep(0);
    setActiveStep(1);
    setFormData(initialSignupFormData);
    navigate(RoutePaths.Login);
  };

  const signUpDialogMapper = [
    {
      step: 1,
      component: (
        <SignUpActivateCode
          visible={activeDialogStep === 1}
          onClose={handleResetActiveDialog}
          onNextStep={handleNextDialog}
        />
      ),
    },
    {
      step: 2,
      component: (
        <SignUpDualAuth
          visible={activeDialogStep === 2}
          onClose={handleResetActiveDialog}
          onNextStep={handleNextDialog}
        />
      ),
    },
    {
      step: 3,
      component: (
        <SignUpOTP
          visible={activeDialogStep === 3}
          onClose={handleResetActiveDialog}
          onNextStep={handleNextDialog}
        />
      ),
    },
    {
      step: 4,
      component: (
        <SignupFileUpload
          visible={activeDialogStep === 4}
          onClose={handleResetActiveDialog}
          onNextStep={handleNextDialog}
        />
      ),
    },
    {
      step: 5,
      component: (
        <SignUpUnverified
          visible={activeDialogStep === 5}
          onNextStep={handleFinishVerify}
        />
      ),
    },
  ];

  const signUpContentMapper = [
    {
      step: 1,
      component: (
        <SignUpPersonalDetails
          formData={formData}
          onNextStep={handleNextStep}
        />
      ),
    },
    {
      step: 2,
      component: (
        <SignUpBusinessDetails
          formData={formData}
          onPrevStep={handlePrevStep}
          onSignup={handleSignup}
        />
      ),
    },
  ];
  const findCurrSignupContent = signUpContentMapper.find(
    ({ step }) => step === activeStep
  );
  const activeSignupComponent = findCurrSignupContent
    ? findCurrSignupContent.component
    : null;
  const findCurrSignupDialog = signUpDialogMapper.find(
    ({ step }) => step === activeDialogStep
  );
  const activeSignupDialog = findCurrSignupDialog
    ? findCurrSignupDialog.component
    : null;

  return (
    <>
      <Grid gutter={0} w='100%'>
        <Grid.Col xs={12} md={6} sx={{ height: '100%', overflowY: 'auto' }}>
          <Flex
            direction='column'
            align='center'
            justify='center'
            className={classes.loginImgFormWrapper}
          >
            <MediaQuery largerThan='md' styles={{ display: 'none' }}>
              <BackgroundImage
                src={signupBanner}
                alt='UBS Signup Banner'
                className={classes.loginMdFormBanner}
              />
            </MediaQuery>
            <Flex
              direction='column'
              align='center'
              justify='flex-start'
              h='100%'
              className={signupClasses.signupFormWrapper}
            >
              <SignUpStepper activeStep={activeStep} />
              {activeSignupComponent}
            </Flex>
          </Flex>
        </Grid.Col>
        <MediaQuery smallerThan='md' styles={{ display: 'none' }}>
          <Grid.Col xs={12} md={6}>
            <BackgroundImage
              src={signupBanner}
              alt='UBS Signup Banner'
              sx={{ height: '100vh' }}
            />
          </Grid.Col>
        </MediaQuery>
      </Grid>
      {activeSignupDialog}
    </>
  );
};

export default SignUpPage;
