import { rem } from '@mantine/core';

const configureMantineTheme = {
  colors: {
    ubsPrimary: [
      '#80538E',
      '#774588',
      '#703783',
      '#6B2980',
      '#661C7E',
      '#620E7E',
      '#60007F',
      '#510B67',
      '#441354',
      '#3A1746',
      '#32193B',
      '#2C1932',
      '#26192B',
    ],
    ubsBtnColor: [
      '#DAC2C4',
      '#D0A7A9',
      '#CB8A8E',
      '#CB6B71',
      '#D24851',
      '#E0212C',
      '#E20714',
      '#B81A23',
      '#97262C',
      '#7E2C31',
    ],
    ubsBtnExitColor: [
      '#9f8cc0',
      '#ad9cc9',
      '#baadd2',
      '#c8bddb',
      '#d6cee4',
      '#e4deed',
      '#f1eff6',
    ],
    ubsBtnSaveColor: [
      '#ea9100',
      '#ffa004',
      '#ffa91e',
      '#ffb337',
      '#ffbd51',
      '#ffc66a',
      '#ffd084',
    ],
    ubsHighlight: [
      '#E9E6D9',
      '#DDD7BC',
      '#D6CC9E',
      '#D5C47D',
      '#DBC259',
      '#E8C52F',
      '#FECE00',
      '#E8BF46',
      '#A99025',
      '#8C7A2D',
      '#756931',
      '#645A32',
      '#554E32',
    ],
    ubsDashboard: [
      '#FBFBFD',
      '#E4DDF1',
      '#CEC3E1',
      '#BAAED0',
      '#A99DBF',
      '#998EAF',
      '#92779F',
      '#7F7691',
      '#541468',
      '#3E114C',
      '#F1F2F8',
    ],
  },
  primaryColor: 'ubsPrimary',
  primaryShade: 6,
  loader: 'dots',
  components: {
    Title: {
      defaultProps: {
        color: 'ubsPrimary',
      },
    },
  },
  globalStyles: (theme) => ({
    '.navLink': {
      textDecoration: 'none',
      textAlign: 'right',
      '&:hover': {
        textDecoration: 'none',
        color: theme.colors.ubsHighlight[7],
      },
    },
    '.defaultBtn': {
      '&:hover': {
        backgroundColor: theme.colors.ubsPrimary[6],
        color: theme.colors.ubsHighlight[7],
      },
    },
    '.page-wrapper': {
      background: '#FFFF',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
      border: '0.0625rem solid #dee2e6',
      borderRadius: '15px',
      maxWidth: 'none',
      padding: rem(16),
      width: '100%',
    },
    '.page-h4-title': {
      color: '#2A2A2A',
      fontSize: rem(20),
      fontWeight: 750,
    },
    '.span-sub-text': {
      color: '#2A2A2A',
      fontSize: rem(12),
      fontWeight: 400,
    },
    '.yellow-btn': {
      backgroundColor: '#E8BF46',
      color: '#2A2A2A',
      ':hover': {
        backgroundColor: '#FFDB71',
      },
    },
    '.purple-wrapper': {
      backgroundColor: '#F8F6FC',
      borderRadius: rem(8),
      marginTop: rem(16),
      padding: rem(16),
    },
    '.white-wrapper': {
      backgroundColor: '#FFFFFF',
      borderRadius: rem(8),
      marginTop: rem(16),
      padding: rem(16),
    },
    '.purple-modal': {
      '.mantine-Modal-header': {
        backgroundColor: theme.colors.ubsPrimary[6],
        padding: '1rem 2rem',
        button: {
          color: '#FFFFFF',
          ':hover': {
            background: 'none',
          },
        },
      },
    },
    '.plusTd': {
      color: '#529F05',
    },
    '.minusTd': {
      color: '#ED1007',
    },
    '.red-btn': {
      backgroundColor: theme.colors.ubsBtnColor[6],
      '&:hover': {
        backgroundColor: theme.colors.ubsBtnColor[6],
      },
    },
    '.img-yellow-ribbon': {
      background: theme.colors.ubsHighlight[6],
      height: '1.25em',
    },
    '.codeInputWrapper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
      padding: '1rem',
      paddingBottom: 0,
      overflowX: 'auto',
      width: '100%',
    },
    '.codeInput': {
      width: '60px',
      height: '60px',
      padding: 0,
      fontSize: '24px',
      textAlign: 'center',
      textTransform: 'uppercase',
      color: '#494949',
      border: '1px solid #d6d6d6',
      borderRadius: '4px',
      background: '#fff',
      backgroundClip: 'padding-box',
    },
    '.codeInputErr': {
      color: '#f00000',
      fontSize: '0.75rem',
      fontWeight: 400,
    },
  }),
};

export default configureMantineTheme;
