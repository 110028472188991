import {
  Flex,
  Title,
  Text,
  Textarea,
  Grid,
  Button,
  TextInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { formatCurrency } from '../../../../utils/currency';
import { CreatePaymentAmountSchema } from '../../../../schema/payment';
import { sendFee, currencyWalletFund } from '../../../../mocks/send-money';
import AtomFlagWallet from '../../../atoms/flag-wallet/FlagWallet';
import AtomGridDetails from '../../../atoms/grid-details/GridDetails';
import phFlag from '../../../../assets/images/ph-flag.svg';

export const CreatePaymentAmountBreakdown = ({ paymentAmount, fee, total }) => {
  return (
    <>
      <Flex
        align='center'
        justify='space-between'
        className='purple-wrapper'
        sx={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <AtomGridDetails
          details={[
            {
              label: 'Payment Amount',
              value: paymentAmount,
            },
            {
              label: 'Fee',
              value: fee,
            },
            {
              label: 'Total Amount to Pay',
              value: total,
              isBold: true,
            },
          ]}
        />
      </Flex>
      <Flex
        align='center'
        justify='space-between'
        p={16}
        sx={(theme) => ({
          backgroundColor: theme.colors.ubsPrimary[6],
          color: '#FFFF',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
        })}
      >
        <Text>Estimated Service Time</Text>
        <Text>20 minutes</Text>
      </Flex>
    </>
  );
};

const PaymentAmount = ({ formData, onPrevStep, onNextStep }) => {
  const createPaymentAmountForm = useForm({
    validate: yupResolver(CreatePaymentAmountSchema),
    initialValues: formData,
  });
  const { onSubmit, getInputProps, values } = createPaymentAmountForm;

  const calculateTranFees = () => {
    const { amount } = values;
    const fees = {
      paymentAmount: amount,
      fee: sendFee.PHP,
      total: parseFloat(amount) + sendFee.PHP,
    };

    return fees;
  };

  const handleSubmit = (data) => {
    const calculatedFees = calculateTranFees();
    onNextStep({ ...data, ...calculatedFees });
  };

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <Flex direction='column' className='white-wrapper'>
        <Grid w='100%'>
          <Grid.Col sm={6}>
            <TextInput label='Order Number' {...getInputProps('orderNo')} />
            <TextInput
              label='Amount (PHP)'
              type='number'
              mt={16}
              {...getInputProps('amount')}
            />
            <Textarea
              label='Remarks'
              autosize
              minRows={2}
              mt={16}
              {...getInputProps('remarks')}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <Title order={4} className='page-h4-title'>
              Transaction Wallet
            </Title>
            <Flex justify='space-between' className='purple-wrapper'>
              <AtomFlagWallet
                label='PHP Wallet'
                value={formatCurrency('PHP', currencyWalletFund.PHP)}
                flagImg={phFlag}
              />
            </Flex>
            <Title order={4} className='page-h4-title' mt={16}>
              Summary
            </Title>
            <CreatePaymentAmountBreakdown
              paymentAmount={formatCurrency(
                'PHP',
                calculateTranFees().paymentAmount
              )}
              fee={formatCurrency('PHP', calculateTranFees().fee)}
              total={formatCurrency('PHP', calculateTranFees().total)}
            />
          </Grid.Col>
          <Grid.Col
            mt={16}
            display='flex'
            sx={(theme) => ({
              alignItems: 'center',
              gap: '5px',
              justifyContent: 'flex-end',
              [theme.fn.smallerThan('md')]: {
                justifyContent: 'flex-start',
              },
            })}
          >
            <Button color='ubsBtnExitColor.3' c='black' onClick={onPrevStep}>
              Back
            </Button>
            <Button type='submit' className='yellow-btn'>
              Next
            </Button>
          </Grid.Col>
        </Grid>
      </Flex>
    </form>
  );
};

export default PaymentAmount;
