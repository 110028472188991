import {
  Grid,
  Flex,
  Input,
  Button,
  Container,
  Box,
  Text,
  LoadingOverlay,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { RoutePaths } from '../../../constants/routes';
import useStyles from './EmployeeOrg.styles';
import userService from '../../../api/employee';
import { customLoader } from '../../atoms/loader/loader';
import Banner from '../../atoms/banner/Banner';
import mapperAPI from '../../../mapper';
import mapperType from '../../../utils/mapperType';

const fetcher = (url, data) =>
  userService.getEmpolyees(url, { data }).then((res) => res.data);

const EmployeeInfo = () => {
  const { classes } = useStyles();
  const [showProcessedModal, setProcessedModalvisibility] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const [employee, setEmployee] = useState(null);

  useEffect(() => {
    if (params.id === 'new') {
      setEmployee({ birthday: new Date() });
    } else {
      fetcher('/employee/get', { search: params.id }).then((res) => {
        const mapAPI = mapperAPI(res.data[0], mapperType.employeeMapper);
        setEmployee(mapAPI);
      });
    }
  }, []);

  if (!employee) return <LoadingOverlay loader={customLoader} visible />;

  const {
    firstName,
    middleName,
    lastName,
    birthday,
    mobileNumber,
    id,
  } = employee || {};

  return (
    <Container p='2rem' fluid className={classes.empInfoWrapper}>
      <Banner
        visible={showProcessedModal}
        onClose={() => {
          setProcessedModalvisibility(false);
          navigate(`${RoutePaths.EmployeeList}`);
        }}
        title='Information Save'
        message='Employee information has been successfully saved.'
      />
      <Grid>
        <Grid.Col sm={12}>
          <Text fw={600}>Employee Details</Text>
        </Grid.Col>
        <Grid.Col sm={4}>
          <Input.Wrapper withAsterisk label='First Name'>
            <Input defaultValue={firstName} placeholder='First Name' />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={4}>
          <Input.Wrapper label='Middle Name'>
            <Input defaultValue={middleName} placeholder='Middle Name' />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={4}>
          <Input.Wrapper withAsterisk label='Last Name'>
            <Input defaultValue={lastName} placeholder='Last Name' />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={6} md={2}>
          <Input.Wrapper label='Sufix'>
            <Input placeholder='Sufix' />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={6} md={4}>
          <DateInput
            placeholder='Date of Birth'
            label='Date of Birth'
            withAsterisk
            defaultValue={new Date(birthday || new Date())}
            icon={<IconCalendar />}
          />
        </Grid.Col>
        <Grid.Col sm={6} md={4}>
          <Input.Wrapper withAsterisk label='Mobile Number'>
            <Input defaultValue={mobileNumber} placeholder='Mobile Number' />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={6} md={2}>
          <Input.Wrapper withAsterisk label='PK Number'>
            <Input defaultValue={id} readOnly placeholder='PK Number' />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} pt='2rem'>
          <Flex justify='flex-end' align='center' direction='row' gap='xs'>
            <Box w={140}>
              <Button
                fullWidth
                color='ubsBtnExitColor.3'
                c='black'
                component={Link}
                to={`${RoutePaths.EmployeeList}`}
              >
                Cancel
              </Button>
            </Box>
            <Box w={140}>
              <Button
                className='yellow-btn'
                fullWidth
                onClick={() => setProcessedModalvisibility(true)}
              >
                Save
              </Button>
            </Box>
          </Flex>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default EmployeeInfo;
