import { Flex, Button, Text, Modal } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';

const Banner = ({ visible, onClose, title, message }) => {
  return (
    <Modal
      radius='xl'
      opened={visible}
      onClose={onClose}
      withCloseButton={false}
      centered
    >
      <Flex justify='center' align='center' direction='column' gap='xl' p={16}>
        <Text c='teal.4'>
          <IconCircleCheck size='7rem' />
        </Text>
        <Text c='teal.4' fz='1.5rem' fw={700}>
          {title}
        </Text>
        <Text fz='sm' ta='center'>
          {message}
        </Text>
        <Button className='yellow-btn' onClick={onClose}>
          Okay
        </Button>
      </Flex>
    </Modal>
  );
};

export default Banner;
