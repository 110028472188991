import { Flex, Grid, Space, Image, Text } from '@mantine/core';
import { RoutePaths } from '../../../constants/routes';
import ubsPlainLogo from '../../../assets/images/ubs-logo-plain.svg';
import useStyles from '../../pages/landing/LandingPage.styles';

const Footer = () => {
  const { classes } = useStyles();

  return (
    <Flex
      id={RoutePaths.ContactHash.replace('/#', '')}
      justify='center'
      className={classes.footerWrapper}
    >
      <Flex
        direction='column'
        align='center'
        justify='center'
        className={classes.footerContentWrapper}
      >
        <Grid>
          <Grid.Col>
            <Text tt='uppercase' className={classes.footerMainTxt}>
              Need help?{' '}
              <span className={classes.chooseUbsBtnSubTxt}>Tawag Lang!</span>
            </Text>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3}>
            <Text fw='600' className={classes.footerTxt}>
              Customer Service Hotline:
            </Text>
            <Text fw='300' className={classes.footerTxt}>
              (02) 8928-USSC (8772)
            </Text>
            <Space h='sm' />
            <Text fw='300' className={classes.footerTxt}>
              Monday to Sunday
            </Text>
            <Text fw='300' className={classes.footerTxt}>
              9:00 AM - 6:00 PM
            </Text>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={3}>
            <Text fw='600' className={classes.footerTxt}>
              You may also reach us online:
            </Text>
            <Text fw='300' className={classes.footerTxt}>
              customer_service@ussc.com.ph
            </Text>
            <Text fw='300' className={classes.footerTxt}>
              m.facebook.com/ussc.philippines
            </Text>
            <Space h='sm' />
            <Text fw='600' className={classes.footerTxt}>
              www.ussc.com.ph
            </Text>
          </Grid.Col>
          <Grid.Col sm={12} md={3} className={classes.footerLogo}>
            <Image
              width={200}
              height={80}
              src={ubsPlainLogo}
              fit='contain'
              alt='UBS plain logo'
            />
          </Grid.Col>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default Footer;
