import { Table } from '@mantine/core';
import useStyles from './Table.styles';

const AtomTable = ({ headers, rowData }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.atomTableWrapper}>
      <Table
        verticalSpacing='md'
        horizontalSpacing='xl'
        highlightOnHover
        className={classes.atomTable}
      >
        <thead>
          <tr>
            {headers.map((headerLabel, headerIndex) => (
              <th key={`${headerLabel}-${headerIndex}`}>{headerLabel}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowData.map((row, rowIndex) => (
            <tr key={`atom-table-tbody-tr-${rowIndex}`}>
              {row.map(({ content, className }, rowContentIndex) => (
                <td
                  key={`atom-table-tbody-tr-${rowIndex}-${rowContentIndex}`}
                  className={className || ''}
                >
                  {content}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AtomTable;
