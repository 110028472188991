import { Grid, Title, Flex, Text, Divider } from '@mantine/core';
import { IconPhoto } from '@tabler/icons-react';
import { formatCurrency } from '../../../../utils/currency';
import { getDateTime } from '../../../../utils/date';
import { DownloadBtn } from '../DashboardListModal';
import AtomFlagWallet from '../../../atoms/flag-wallet/FlagWallet';
import AtomGridDetails from '../../../atoms/grid-details/GridDetails';
import usFlag from '../../../../assets/images/us-flag.svg';
import useStyles from '../../../pages/dashboard/DashboardPage.styles';

const SendMoneyIntlContent = ({ transaction }) => {
  const { classes } = useStyles();
  const usdEquivalent = transaction.amountSent * 0.75;
  const totalPay = usdEquivalent + transaction.fee;

  return (
    <Flex direction='column' align='center' w='100%'>
      <Grid justify='space-between' w='100%'>
        <Grid.Col md={6}>
          <Title order={3} className={classes.modalContentTitle}>
            Beneficiary Details
          </Title>
          <Flex
            direction='column'
            justify='space-between'
            className='purple-wrapper'
          >
            <AtomGridDetails
              details={[
                {
                  label: 'Country of Origin',
                  value: transaction.countryOrigin,
                },
                {
                  label: 'Country Code',
                  value: transaction.countryCode,
                },
                {
                  label: 'Selected Bank',
                  value: transaction.bank,
                },
                {
                  label: 'IBAN',
                  value: transaction.iban,
                },
                {
                  label: 'Type',
                  value: transaction.bankType,
                },
              ]}
            />
            <Divider my={32} />
            <AtomGridDetails
              details={[
                {
                  label: 'First Name',
                  value: transaction.beneficiaryFName,
                },
                {
                  label: 'Middle Name',
                  value: transaction.beneficiaryMName,
                },
                {
                  label: 'Last Name',
                  value: transaction.beneficiaryLName,
                },
                {
                  label: 'Mobile Number',
                  value: transaction.mobileNo,
                },
                {
                  label: 'Full Name of Sender',
                  value: transaction.senderFullName,
                },
              ]}
            />
            <Divider my={32} />
            <AtomGridDetails
              details={[
                {
                  label: 'Purpose of Sending',
                  value: transaction.purpose,
                },
                {
                  label: 'Source of Funds',
                  value: transaction.fundSource,
                },
                {
                  label: 'Relationship to Sender',
                  value: transaction.relationship,
                },
              ]}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col md={6}>
          <Title order={3} className={classes.modalContentTitle}>
            Transaction Wallet
          </Title>
          <Flex justify='space-between' className='purple-wrapper'>
            <AtomFlagWallet
              label='USD Wallet'
              value={formatCurrency('USD', transaction.wallet)}
              flagImg={usFlag}
            />
          </Flex>
          <Title order={3} mt={16} className={classes.modalContentTitle}>
            Transaction Amount
          </Title>
          <Flex
            align='center'
            justify='space-between'
            className='purple-wrapper'
          >
            <Flex direction='column'>
              <Text>Exchange Rate</Text>
              <span className='span-sub-text'>
                {getDateTime(transaction.create_date, 'LLL dd, yyyy - t')}
              </span>
            </Flex>
            <Text>SGD 1 = USD 0.75</Text>
          </Flex>
          <Flex
            align='center'
            justify='space-between'
            className='purple-wrapper'
            sx={{
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <AtomGridDetails
              details={[
                {
                  label: 'Send Amount (SGD)',
                  value: formatCurrency('SGD', transaction.amountSent),
                },
                {
                  label: 'Send Fee',
                  value: formatCurrency('USD', transaction.fee),
                },
                {
                  label: 'USD Equivalent',
                  value: formatCurrency('USD', usdEquivalent),
                },
                {
                  label: 'Total Amount to Pay',
                  value: formatCurrency('USD', totalPay),
                  isBold: true,
                },
              ]}
            />
          </Flex>
          <Flex
            align='center'
            justify='space-between'
            p={16}
            sx={(theme) => ({
              backgroundColor: theme.colors.ubsPrimary[6],
              color: '#FFFF',
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px',
            })}
          >
            <Text>Estimated Service Time</Text>
            <Text>{transaction.serviceTime}</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col sm={6} display='flex' sx={{ justifyContent: 'flex-start' }}>
          <Flex
            justify='space-between'
            w='100%'
            className='purple-wrapper'
            mt={0}
          >
            <Grid align='center' w='100%'>
              <Grid.Col sm={6} className={classes.modalContentInfoLabel}>
                <Text>Uploaded File</Text>
              </Grid.Col>
              <Grid.Col sm={6} className={classes.modalContentInfoValue}>
                <IconPhoto size='2rem' />
                <Flex ml={10} direction='column'>
                  <Title order={6} color='dark' truncate>
                    {transaction.fileName}
                  </Title>
                  <Text className='span-sub-text' truncate>
                    Completed {transaction.fileSize}
                  </Text>
                </Flex>
              </Grid.Col>
            </Grid>
          </Flex>
        </Grid.Col>
        <Grid.Col
          sm={6}
          display='flex'
          sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <DownloadBtn />
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

export default SendMoneyIntlContent;
