import { useState } from 'react';
import { Container, Title } from '@mantine/core';
import GenerateReportForm from '../../organisms/report/GenerateReportForm';
import AtomToast from '../../atoms/toast/Toast';

const ReportPage = () => {
  const [generateToastMsg, setGenerateToastVisibility] = useState('');
  const handleGenerateReport = () => {
    setGenerateToastVisibility('Successfully generated report!');
  };

  return (
    <Container className='page-wrapper'>
      <Title order={4} className='page-h4-title'>
        Generate Reports
      </Title>
      <GenerateReportForm onGenerate={handleGenerateReport} />
      <AtomToast
        message={generateToastMsg}
        mode='success'
        onClose={() => setGenerateToastVisibility('')}
      />
    </Container>
  );
};

export default ReportPage;
