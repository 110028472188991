import {
  Grid,
  Text,
  Button,
  Container,
  Select,
  TextInput,
} from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, yupResolver } from '@mantine/form';
import { useState } from 'react';

import { RoutePaths } from '../../../constants/routes';
import useStyles from '../../pages/bank-account/BankAccount.styles';
import { bankAvailable } from '../../../mocks/bank-account';
import { AddBankAccountSchema } from '../../../schema/mybank';
import Banner from '../../atoms/banner/Banner';

export default () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [showProcessedModal, setProcessedModalvisibility] = useState(false);

  const changePswdForm = useForm({
    validate: yupResolver(AddBankAccountSchema),
    initialValues: {
      accountNickname: '',
      accountType: '',
      acountName: '',
      accountNumber: '',
    },
  });

  const { onSubmit, getInputProps } = changePswdForm;

  const handleAddAccount = () => {
    setProcessedModalvisibility(true);
  };

  return (
    <Container p='2rem' fluid className={classes.bankInfoWrapper}>
      <Banner
        visible={showProcessedModal}
        onClose={() => {
          setProcessedModalvisibility(false);
          navigate(`${RoutePaths.BankAccountMain}`);
        }}
        title='Success'
        message='Bank account has been successfully added.'
      />
      <form onSubmit={onSubmit(handleAddAccount)}>
        <Grid>
          <Grid.Col span={12}>
            <Text fz='lg' fw={600}>
              New Bank Account
            </Text>
          </Grid.Col>
          <Grid.Col md={12}>
            <TextInput
              label='Set Account Nickname'
              withAsterisk
              placeholder='Set Account Nickname'
              {...getInputProps('accountNickname')}
            />
          </Grid.Col>
          <Grid.Col md={12}>
            <Select
              label='Choose Bank'
              placeholder='Choose Bank'
              searchable
              withAsterisk
              clearable
              nothingFound='Nothing found'
              data={bankAvailable}
              {...getInputProps('accountType')}
            />
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <TextInput
              placeholder='Account Name'
              label='Account Name'
              withAsterisk
              {...getInputProps('acountName')}
            />
          </Grid.Col>
          <Grid.Col xs={12} md={6}>
            <TextInput
              placeholder='Account Number'
              label='Account Number'
              withAsterisk
              {...getInputProps('accountNumber')}
            />
          </Grid.Col>
          <Grid.Col
            mt={16}
            display='flex'
            sx={(theme) => ({
              alignItems: 'center',
              gap: '5px',
              justifyContent: 'flex-end',
              [theme.fn.smallerThan('md')]: {
                justifyContent: 'flex-start',
              },
            })}
          >
            <Button
              color='ubsBtnExitColor.3'
              c='black'
              component={Link}
              to={`${RoutePaths.BankAccountMain}`}
            >
              Cancel
            </Button>
            <Button className='yellow-btn' type='submit'>
              Save
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Container>
  );
};
