import {
  Flex,
  Grid,
  Select,
  Title,
  TextInput,
  Button,
  Image,
  Text,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { SendMoneyDomDetailsSchema } from '../../../schema/send-money';
import { formatCurrency } from '../../../utils/currency';
import { currencyWalletFund, sendFee } from '../../../mocks/send-money';
import { accountCards } from '../../../mocks/bank-account';
import AtomFlagWallet from '../../atoms/flag-wallet/FlagWallet';
import phFlag from '../../../assets/images/ph-flag.svg';
import instaPayLogo from '../../../assets/images/instapay-logo.svg';

const SendMoneyDomDetails = ({ formData, onNextStep }) => {
  const sendMoneyDomDetailsForm = useForm({
    validate: yupResolver(SendMoneyDomDetailsSchema),
    initialValues: formData,
  });
  const { onSubmit, getInputProps, setFieldValue } = sendMoneyDomDetailsForm;
  const bankOptions = accountCards.map((account) => ({
    label: account.bankName,
    value: account.bankName,
  }));

  const handleSubmit = (data) => {
    const sendMoneyDomFee = sendFee.PHP;
    const total = parseFloat(data.amount) + sendFee.PHP;
    onNextStep({ ...data, fee: sendMoneyDomFee, total });
  };

  const handleSetValuesByBank = (bankValue) => {
    let bankDetailValues = { accountNo: '', accountName: '' };

    if (bankValue) {
      const findBank = accountCards.find(
        (account) => account.bankName === bankValue
      );
      bankDetailValues = {
        accountNo: findBank.bankNum,
        accountName: findBank.fullName,
      };
    }
    setFieldValue('accountNo', bankDetailValues.accountNo);
    setFieldValue('accountName', bankDetailValues.accountName);
  };

  const handleOnSelectBank = (bankValue) => {
    setFieldValue('bank', bankValue);
    handleSetValuesByBank(bankValue);
  };

  return (
    <Flex direction='column' className='white-wrapper'>
      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid w='100%'>
          <Grid.Col sm={6}>
            <Title order={4} className='page-h4-title'>
              Recipient Details
            </Title>
            <Select
              label='Select Bank'
              data={bankOptions}
              clearable
              mt={16}
              {...getInputProps('bank')}
              onChange={handleOnSelectBank}
            />
            <TextInput
              label='Account Number'
              mt={16}
              {...getInputProps('accountNo')}
            />
            <TextInput
              label='Account Name'
              mt={16}
              {...getInputProps('accountName')}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <Title order={4} className='page-h4-title'>
              Transaction Amount
            </Title>
            <TextInput
              label='Amount (PHP)'
              type='number'
              mt={16}
              {...getInputProps('amount')}
            />
            <Title order={4} className='page-h4-title' mt={16}>
              Transaction Wallet
            </Title>
            <Flex justify='space-between' className='purple-wrapper'>
              <AtomFlagWallet
                label='PHP Wallet'
                value={formatCurrency('PHP', currencyWalletFund.PHP)}
                flagImg={phFlag}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col
            mt={32}
            display='flex'
            sx={(theme) => ({
              alignItems: 'center',
              justifyContent: 'space-between',
              [theme.fn.smallerThan('md')]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
              },
            })}
          >
            <Flex align='center'>
              <Text mr={10}>Sent via</Text>
              <Image src={instaPayLogo} width={150} height={39} />
            </Flex>
            <Button type='submit' className='yellow-btn'>
              Next
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Flex>
  );
};

export default SendMoneyDomDetails;
