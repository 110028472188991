import { Flex, Grid, Title, Divider, Button } from '@mantine/core';
import { formatCurrency } from '../../../../utils/currency';
import { currencyWalletFund } from '../../../../mocks/send-money';
import AtomFlagWallet from '../../../atoms/flag-wallet/FlagWallet';
import AtomGridDetails from '../../../atoms/grid-details/GridDetails';
import phFlag from '../../../../assets/images/ph-flag.svg';

const ConfirmCreatePayment = ({ formData, onPrevStep, onConfirm }) => {
  return (
    <Flex direction='column' className='white-wrapper'>
      <Grid w='100%'>
        <Grid.Col sm={6}>
          <Title order={4} className='page-h4-title' mb={16}>
            Transaction Details
          </Title>
          <Flex
            direction='column'
            justify='space-between'
            className='purple-wrapper'
          >
            <AtomGridDetails
              details={[
                {
                  label: 'First Name',
                  value: formData.firstName,
                },
                {
                  label: 'Middle Name',
                  value: formData.middleName,
                },
                {
                  label: 'Last Name',
                  value: formData.lastName,
                },
                {
                  label: 'Mobile Number',
                  value: formData.mobileNo,
                },
                {
                  label: 'Email',
                  value: formData.email,
                },
              ]}
            />
            <Divider my={32} />
            <AtomGridDetails
              details={[
                {
                  label: 'Order Number',
                  value: formData.orderNo,
                },
                {
                  label: 'Remarks',
                  value: formData.remarks,
                },
              ]}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col sm={6}>
          <Title order={4} className='page-h4-title' mb={16}>
            Transaction Wallet
          </Title>
          <Flex justify='space-between' className='purple-wrapper'>
            <AtomFlagWallet
              label='PHP Wallet'
              value={formatCurrency('PHP', currencyWalletFund.PHP)}
              flagImg={phFlag}
            />
          </Flex>
          <Title order={4} className='page-h4-title' mt={16}>
            Summary
          </Title>
          <Flex
            align='center'
            justify='space-between'
            className='purple-wrapper'
          >
            <AtomGridDetails
              details={[
                {
                  label: 'Payment Amount',
                  value: formatCurrency('PHP', formData.paymentAmount),
                },
                {
                  label: 'Fee',
                  value: formatCurrency('PHP', formData.fee),
                },
                {
                  label: 'Total Amount to Pay',
                  value: formatCurrency('PHP', formData.total),
                  isBold: true,
                },
              ]}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col
          mt={16}
          display='flex'
          sx={(theme) => ({
            alignItems: 'center',
            gap: '5px',
            justifyContent: 'flex-end',
            [theme.fn.smallerThan('md')]: {
              justifyContent: 'flex-start',
            },
          })}
        >
          <Button color='ubsBtnExitColor.3' c='black' onClick={onPrevStep}>
            Back
          </Button>
          <Button onClick={onConfirm} className='yellow-btn'>
            Confirm
          </Button>
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

export default ConfirmCreatePayment;
