import { useState } from 'react';
import { Popover } from '@mantine/core';

const AtomPopover = ({ target, content }) => {
  const [opened, setOpened] = useState(false);

  return (
    <Popover position='left' shadow='md' withArrow opened={opened}>
      <Popover.Target onClick={() => setOpened(!opened)}>
        {target}
      </Popover.Target>
      <Popover.Dropdown onClick={() => setOpened(false)}>
        {content}
      </Popover.Dropdown>
    </Popover>
  );
};

export default AtomPopover;
