import { Modal, Flex, Text, Title, Button, Input, Image } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import qrCodeSample from '../../../assets/images/qr-code-sample.svg';

const GenerateQRModal = ({ visible, onClose }) => {
  return (
    <Modal
      opened={visible}
      centered
      size={400}
      onClose={onClose}
      withCloseButton={false}
    >
      <Flex direction='column' align='center' justify='center' p={16}>
        <Input.Wrapper label='Requested Amount' w='100%'>
          <Input />
        </Input.Wrapper>
        <Title order={2} mt={32}>
          Scan QR Code
        </Title>
        <Text className='span-sub-text'>
          Scan this QR code to receive money.
        </Text>
        <Image
          src={qrCodeSample}
          width={258}
          height={258}
          mt={10}
          alt='Sample QR code'
        />
        <Button
          className='yellow-btn'
          leftIcon={<IconDownload />}
          mt={32}
          w={200}
        >
          Download QR
        </Button>
        <Button
          variant='subtle'
          mt={16}
          onClick={onClose}
          sx={{ ':hover': { backgroundColor: '#FFFFFF' } }}
        >
          Close
        </Button>
      </Flex>
    </Modal>
  );
};

export default GenerateQRModal;
