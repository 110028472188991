import { createStyles, rem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  loginMdFormBanner: {
    height: '900px',
    position: 'absolute',
    top: 60,
    zIndex: 1,
  },
  loginImgFormWrapper: {
    height: '100%',
    maxHeight: '1000px',
    [theme.fn.smallerThan('xl')]: {
      maxHeight: '700px',
    },
    [theme.fn.smallerThan('md')]: {
      padding: rem(16),
    },
  },
  loginFormWrapper: {
    padding: rem(160),
    width: '100%',
    [theme.fn.smallerThan('xl')]: {
      paddingLeft: rem(80),
      paddingRight: rem(80),
    },
    [theme.fn.smallerThan('md')]: {
      backgroundColor: 'white',
      border: '1px solid #e8e7e7',
      borderRadius: '5px',
      margin: rem(16),
      maxWidth: '500px',
      padding: rem(32),
      position: 'relative',
      width: '100%',
      zIndex: 2,
    },
  },
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(16),
    justifyContent: 'center',
  },
  loginWelcomeTitle: {
    color: theme.colors.ubsHighlight[7],
  },
  loginInput: {
    input: {
      borderBottom: `2px solid ${theme.colors.ubsPrimary[6]}`,
      width: '100%',
      ':focus': {
        borderBottom: `2px solid ${theme.colors.ubsPrimary[6]}`,
        width: '100%',
      },
    },
  },
}));

export default useStyles;
