import { Flex, Grid, Button, Image, Group, Title } from '@mantine/core';
import { IconSquarePlus } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../../constants/routes';
import phLogo from '../../../assets/images/ph-flag.svg';
import useStyles from '../../pages/upload-payroll/UploadPayrollPage.styles';

const AddFunds = () => {
  const { classes } = useStyles();

  return (
    <Flex className='white-wrapper'>
      <Grid w='100%'>
        <Grid.Col sm={6}>
          <Flex gap={16} className={classes.addFundWalletWrapper}>
            <Image src={phLogo} width={60} height={60} />
            <Group
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 0,
              }}
            >
              <Title order={4} fw={400} mb={5}>
                PHP Wallet
              </Title>
              <span className='span-sub-text'>Total Balance</span>
              <Title order={4} className='page-h4-title'>
                Php 78,576.00
              </Title>
            </Group>
          </Flex>
        </Grid.Col>
        <Grid.Col sm={6} className={classes.addFundBtnWrapper}>
          <Button
            component={Link}
            to={RoutePaths.AddFund}
            leftIcon={<IconSquarePlus />}
            className='yellow-btn'
          >
            Add Funds
          </Button>
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

export default AddFunds;
