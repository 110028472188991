import { Grid, Title, Flex, Divider } from '@mantine/core';
import { formatCurrency } from '../../../../utils/currency';
import { DownloadBtn } from '../DashboardListModal';
import AtomFlagWallet from '../../../atoms/flag-wallet/FlagWallet';
import AtomGridDetails from '../../../atoms/grid-details/GridDetails';
import phFlag from '../../../../assets/images/ph-flag.svg';
import useStyles from '../../../pages/dashboard/DashboardPage.styles';

const CustomerPaymentContent = ({ transaction }) => {
  const { classes } = useStyles();

  return (
    <Flex direction='column' align='center' w='100%'>
      <Grid justify='space-between' w='100%'>
        <Grid.Col md={6}>
          <Title order={3} className={classes.modalContentTitle}>
            Transaction Details
          </Title>
          <Flex
            direction='column'
            justify='space-between'
            className='purple-wrapper'
          >
            <AtomGridDetails
              details={[
                { label: 'First Name', value: transaction.first_name },
                { label: 'Middle Name', value: transaction.middle_name },
                { label: 'Last Name', value: transaction.last_name },
                { label: 'Mobile Number', value: transaction.mobile_no },
                { label: 'Email', value: transaction.email },
              ]}
            />
            <Divider my={32} />
            <AtomGridDetails
              details={[
                { label: 'Order Number', value: transaction.sku },
                { label: 'Remarks', value: transaction.remarks },
              ]}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col md={6}>
          <Title order={3} className={classes.modalContentTitle}>
            Transaction Details
          </Title>
          <Flex justify='space-between' className='purple-wrapper' py='1.95rem'>
            <AtomFlagWallet
              label='PHP Wallet'
              value={formatCurrency('PHP', transaction.amount)}
              flagImg={phFlag}
            />
          </Flex>
          <Title order={3} mt={16} className={classes.modalContentTitle}>
            Summary
          </Title>
          <Flex
            direction='column'
            justify='space-between'
            className='purple-wrapper'
          >
            <AtomGridDetails
              details={[
                {
                  label: 'Payment Amount',
                  value: formatCurrency('PHP', transaction.amount),
                },
                {
                  label: 'Fee',
                  value: formatCurrency('PHP', transaction.fee),
                },
                {
                  label: 'Total Amount',
                  value: formatCurrency('PHP', transaction.amount),
                  isBold: true,
                },
              ]}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col
          mt={16}
          display='flex'
          sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <DownloadBtn />
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

export default CustomerPaymentContent;
