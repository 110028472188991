import { Link } from 'react-router-dom';
import { Modal, Button, Text, Flex } from '@mantine/core';
import { IconWorld, IconMapPin } from '@tabler/icons-react';
import { RoutePaths } from '../../../constants/routes';
import useStyles from '../../pages/dashboard/DashboardPage.styles';

const DashboardSendMoneyModal = ({ visible, onClose }) => {
  const { classes } = useStyles();

  return (
    <Modal
      opened={visible}
      onClose={onClose}
      centered
      size={600}
      withCloseButton={false}
    >
      <Flex direction='column' align='flex-start' gap={16} p={16}>
        <Text fw={500}>Transaction Type</Text>
        <Button
          leftIcon={<IconWorld />}
          component={Link}
          to={RoutePaths.SendMoneyIntl}
          fullWidth
          className={classes.dashboardSendMoneyBtn}
        >
          International
        </Button>
        <Button
          leftIcon={<IconMapPin />}
          component={Link}
          to={RoutePaths.SendMoneyDomestic}
          fullWidth
          className={classes.dashboardSendMoneyBtn}
        >
          Domestic
        </Button>
      </Flex>
    </Modal>
  );
};

export default DashboardSendMoneyModal;
