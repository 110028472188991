import * as yup from 'yup';

export const SignupPersonalFormSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  middleName: yup.string().required('Middle name is required'),
  lastName: yup.string().required('Last name is required'),
  birthDate: yup.string().required('Date of birth is required'),
  gender: yup.string().required('Please select a gender'),
  province: yup.string().required('Please select a province'),
  city: yup.string().required('Please select a city'),
  barangay: yup.string().required('Please select a barangay'),
  zipCode: yup.string().required('Zip code is required'),
  address: yup.string().required('House no. and Street address is required'),
  mobileNo: yup.string().required('Mobile number is required'),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
  newPassword: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export const SignupBusinessFormSchema = yup.object().shape({
  businessClass: yup.string().required('Business classifiication is required'),
  businessType: yup.string().required('Business type is required'),
  isPhysicalStore: yup
    .boolean()
    .required('Please confirm if physical store or not'),
  businessName: yup.string().required('Business name is required'),
  businessProvince: yup.string().required('Please select a business province'),
  businessCity: yup.string().required('Please select a business city'),
  businessBrgy: yup.string().required('Please select a business barangay'),
  businessZipCode: yup.string().required('Business zip code is required'),
  businessAddress: yup
    .string()
    .required('Business House no. and Street address is required'),
  socmedLink: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Please provide a valid URL'
    )
    .required('Business website/Facebook page is required'),
  businessTIN: yup.string().required('Business TIN is required'),
});

export const SignupActivateCodeFormSchema = yup.object().shape({
  codes: yup
    .string()
    .min(8, 'Please provide all activation code')
    .required('Activation code is required'),
});

export const SignupDualAuthFormSchema = yup.object().shape({
  authType: yup.string().required('Please select an authentication type'),
});

export const SignupOTPFormSchema = yup.object().shape({
  pins: yup
    .string()
    .min(6, 'Please provide all authentication code')
    .required('Authentication code is required'),
});
