import { createStyles, rem } from '@mantine/core';

const defaultNavLinkStyles = {
  borderRadius: rem(4),
  fontWeight: 500,
  margin: '16px 0',
};

const useStyles = createStyles((theme) => ({
  privateAppShell: {
    nav: {
      backgroundColor: theme.colors.ubsDashboard[1],
      overflow: 'auto',
    },
    main: {
      backgroundColor: theme.colors.ubsDashboard[10],
    },
  },
  privateIcon: {
    color: theme.colors.ubsDashboard[9],
  },
  navBarLogo: {
    height: rem(32),
    width: '18.75em',
  },
  navbarLink: {
    ...defaultNavLinkStyles,
    background: 'none',
    color: theme.colors.ubsDashboard[9],
    ':hover': {
      background: 'none',
      color: theme.colors.ubsDashboard[6],
    },
  },
  navbarLinkActive: {
    ...defaultNavLinkStyles,
    color: '#FFFFFF',
    background: theme.colors.ubsPrimary[6],
    ':hover': {
      background: theme.colors.ubsPrimary[7],
    },
  },
  menuTarget: {
    cursor: 'pointer',
  },
  generateQrMenu: {
    backgroundColor: theme.colors.ubsDashboard[9],
    color: '#FFFFFF',
    ':hover': {
      backgroundColor: theme.colors.ubsDashboard[9],
    },
  },
  markAllReadBtn: {
    color: theme.colors.ubsDashboard[9],
    ':hover': {
      backgroundColor: theme.colors.ubsDashboard[1],
    },
  },
  notifPopover: {
    width: rem(19),
    [theme.fn.smallerThan('sm')]: {
      width: rem(15),
    },
  },
  navbarContactWrapper: {
    paddingTop: theme.spacing.sm,
    borderTop: `${rem(1)} solid ${theme.colors.ubsDashboard[9]}`,
  },
  appbarRootLinkTxt: {
    color: theme.colors.ubsDashboard[1],
  },
}));

export default useStyles;
