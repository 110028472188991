import { Modal, BackgroundImage, Card, Text, Button } from '@mantine/core';
import signupUnverifiedBanner from '../../../../assets/images/signup-unverified-banner.png';

const SignUpUnverified = ({ visible, onClose, onNextStep }) => {
  const verifyText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae tempus quam pellentesque nec nam. Tellus in hac habitasse platea dictumst vestibulum. Sed lectus vestibulum mattis ullamcorper velit sed. Enim praesent elementum facilisis leo vel fringilla est ullamcorper eget.';
  return (
    <Modal
      opened={visible}
      onClose={onClose}
      withCloseButton={false}
      padding={0}
      size='lg'
      styles={{
        content: {
          borderRadius: '15px',
        },
      }}
    >
      <Card p={0}>
        <Card.Section>
          <BackgroundImage src={signupUnverifiedBanner} h={500}>
            <div style={{ padding: 32, display: 'flex' }}>
              <div style={{ flex: 1 }} />
              <div style={{ flex: 1, marginTop: 32 }}>
                <Text ta="justify">{verifyText}</Text>
                <div style={{ padding: 16, textAlign: 'center', marginTop: 16 }}>
                  <Button
                    className='defaultBtn'
                    onClick={onNextStep}
                  >
                    Proceed to Login
                  </Button>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </Card.Section>
      </Card>
    </Modal>
  );
};

export default SignUpUnverified;
