import { createStyles, rem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  genQRInfoWrapper: {
    width: '100%',
    height: '100%',
  },
  genQRCodeWrapper: {
    backgroundColor: theme.colors.ubsDashboard[0],
    borderRadius: rem(8),
  }
}));

export default useStyles;
