import { Flex, Title, Modal, Button } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import { getDateTime } from '../../../utils/date';
import { mockTransactionByType } from '../../../mocks/transaction';
import CustomerPaymentContent from './modalContent/CustomerPaymentContent';
import SendMoneyDomesticContent from './modalContent/SendMoneyDomesticContent';
import BuyForexContent from './modalContent/BuyForexContent';
import BankTransferContent from './modalContent/BankTransferContent';
import SendMoneyIntlContent from './modalContent/SendMoneyIntlContent';
import useStyles from '../../pages/dashboard/DashboardPage.styles';

export const DownloadBtn = () => (
  <Button leftIcon={<IconDownload />} className='yellow-btn'>
    Download
  </Button>
);

const DashboardListModal = ({ visible, transaction, onClose }) => {
  const { classes } = useStyles();

  const ModalHeader = (
    <Flex direction='column' className={classes.modalHeaderTxtWrapper}>
      <Flex mb={10}>
        <Title order={4}>Reference No.</Title>
        <Title order={5} ml={10}>
          {transaction.referenceNo}
        </Title>
      </Flex>
      <span>{getDateTime(transaction.create_date, 'LLL dd, yyyy - t')}</span>
    </Flex>
  );

  const ModalContent = () => {
    const transactionDetails = mockTransactionByType[transaction.type];
    let content = null;

    switch (transaction.type) {
      case 'Customer Payment':
        content = <CustomerPaymentContent transaction={transactionDetails} />;
        break;
      case 'Send Money - Domestic':
        content = <SendMoneyDomesticContent transaction={transactionDetails} />;
        break;
      case 'Buy Forex':
        content = <BuyForexContent transaction={transactionDetails} />;
        break;
      case 'Bank Transfer':
        content = <BankTransferContent transaction={transactionDetails} />;
        break;
      case 'Send Money - International':
        content = <SendMoneyIntlContent transaction={transactionDetails} />;
        break;
      default:
        break;
    }

    return content;
  };

  return (
    <Modal
      opened={visible}
      onClose={onClose}
      title={ModalHeader}
      centered
      size={1000}
      lockScroll={false}
      className='purple-modal'
    >
      <Flex p={16}>
        <ModalContent />
      </Flex>
    </Modal>
  );
};

export default DashboardListModal;
