import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  atomTableWrapper: {
    display: 'block',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },
  atomTable: {
    [theme.fn.smallerThan('lg')]: {
      width: '1000px',
    },
    thead: {
      backgroundColor: '#F4F5F9',
      display: 'table',
      tableLayout: 'fixed',
      width: '100%',
      th: {
        color: '#2A2A2A',
      },
    },
    tbody: {
      display: 'block',
      height: '500px',
      overflowY: 'auto',
      overflowX: 'hidden',
      tr: {
        display: 'table',
        tableLayout: 'fixed',
        width: '100%',
      },
    },
  },
}));

export default useStyles;
