import * as yup from 'yup';

export const AddBankAccountSchema = yup.object().shape({
  accountNickname: yup.string().required('Account nickname is required'),
  accountType: yup.string().required('Account type is required'),
  acountName: yup.string().required('Account name is required'),
  accountNumber: yup.number().required('Account number is required'),
});

export const TransBankAccountSchema = yup.object().shape({
  bankAccount: yup.string().required('Bank account is required'),
  amount: yup.string().required('Amount is required'),
});
