import { useState, useEffect } from 'react';
import { Grid, Button, Input, Select } from '@mantine/core';
import { IconPlus, IconSearch } from '@tabler/icons-react';
import { PaymentStatuses } from '../../../constants/payment';

const PaymentsTableFilter = ({ filters, onSetFilters, onCreatePayment }) => {
  const [searchValue, setSearchValue] = useState(filters.search);

  useEffect(() => {
    const timer = setTimeout(() => {
      onSetFilters({ ...filters, search: searchValue });
    }, 500);

    return () => clearTimeout(timer);
  }, [searchValue]);

  return (
    <Grid w='100%'>
      <Grid.Col sm={8}>
        <Grid w='100%'>
          <Grid.Col sm={6}>
            <Input
              defaultValue={searchValue}
              icon={<IconSearch />}
              placeholder='Search reference or name...'
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <Select
              data={PaymentStatuses}
              defaultValue={filters.status}
              onChange={(status) => onSetFilters({ ...filters, status })}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col
        sm={4}
        display='flex'
        sx={(theme) => ({
          alignItems: 'center',
          justifyContent: 'flex-end',
          [theme.fn.smallerThan('md')]: {
            justifyContent: 'flex-start',
          },
        })}
      >
        <Button
          className='yellow-btn'
          leftIcon={<IconPlus />}
          onClick={onCreatePayment}
        >
          Make Payment
        </Button>
      </Grid.Col>
    </Grid>
  );
};

export default PaymentsTableFilter;
