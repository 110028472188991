import { Navigate, Outlet, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import {
  Header,
  Container,
  Group,
  Burger,
  Paper,
  Transition,
  Button,
  Image,
  Anchor,
  Flex,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { verifyAuth } from '../../../utils/auth';
import { RoutePaths } from '../../../constants/routes';
import ubsColoredLogo from '../../../assets/images/ubs-colored-logo.svg';
import useStyles, { HEADER_HEIGHT } from './PublicLayout.styles';

export function HeaderMenu({ links }) {
  const [opened, { toggle, close }] = useDisclosure(false);
  const { classes } = useStyles();

  const itemLinks = links
    .filter((link) => !link.isBtn)
    .map((link, linkIndex) => {
      let renderLink = <div />;
      if (link.isHash) {
        renderLink = (
          <HashLink
            key={linkIndex}
            smooth
            to={link.to}
            className={`navLink ${classes.link}`}
            onClick={() => close()}
          >
            {link.label}
          </HashLink>
        );
      } else {
        renderLink = (
          <Anchor
            key={linkIndex}
            component={Link}
            to={link.to}
            className={`navLink ${classes.link}`}
            onClick={() => close()}
          >
            {link.label}
          </Anchor>
        );
      }

      return renderLink;
    });

  const itemLinkBtns = links
    .filter((link) => link.isBtn)
    .map((link, linkIndex) => (
      <Button
        key={linkIndex}
        component={Link}
        to={link.to}
        variant={link.isOutline ? 'outline' : 'filled'}
        className={`${link.isOutline ? 'defaultBtn' : 'red-btn'} ${
          classes.btnLink
        }`}
        onClick={() => close()}
      >
        {link.label}
      </Button>
    ));

  return (
    <Header height={HEADER_HEIGHT} className={classes.root}>
      <Container fluid className={classes.header}>
        <Image
          width={200}
          height={80}
          src={ubsColoredLogo}
          fit='contain'
          alt='UBS colored logo'
          component={Link}
          to={RoutePaths.Root}
        />
        <>
          <Group spacing={50} className={classes.links}>
            {itemLinks}
            <Flex gap={10}>{itemLinkBtns}</Flex>
          </Group>
          <Burger
            opened={opened}
            onClick={toggle}
            className={classes.burger}
            size='sm'
          />
          <Transition
            transition='pop-top-right'
            duration={200}
            mounted={opened}
          >
            {(styles) => (
              <Paper className={classes.dropdown} withBorder style={styles}>
                {itemLinks}
                {itemLinkBtns}
              </Paper>
            )}
          </Transition>
        </>
      </Container>
    </Header>
  );
}

const PublicLayout = () => {
  const isAuth = verifyAuth();
  const headerMenu = [
    { to: RoutePaths.RootHash, isHash: true, isBtn: false, label: 'HOME' },
    { to: RoutePaths.FAQHash, isHash: true, isBtn: false, label: 'FAQs' },
    {
      to: RoutePaths.ContactHash,
      isHash: true,
      isBtn: false,
      label: 'CONTACT US',
    },
    { to: RoutePaths.Login, isBtn: true, isOutline: true, label: 'LOG IN' },
    { to: RoutePaths.SignUp, isBtn: true, isOutline: false, label: 'SIGN UP' },
  ];
  const layout = (
    <Container p={0} fluid>
      <HeaderMenu links={headerMenu} />
      <Flex>
        <Outlet />
      </Flex>
    </Container>
  );

  return isAuth ? <Navigate to={RoutePaths.Dashboard} /> : layout;
};

export default PublicLayout;
