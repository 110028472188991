import { Card, Modal, Image, Text, Title, Button, Flex } from '@mantine/core';
import AuthCode from 'react-auth-code-input';
import { Link } from 'react-router-dom';
import { useForm, yupResolver } from '@mantine/form';
import { SignupOTPFormSchema } from '../../../../schema/sign-up';
import { RoutePaths } from '../../../../constants/routes';
import signupOTPBanner from '../../../../assets/images/signup-otp-banner.svg';

const SignUpOTP = ({ visible, onClose, onNextStep }) => {
  const otpForm = useForm({
    validate: yupResolver(SignupOTPFormSchema),
    initialValues: {
      pins: '',
    },
  });
  const { onSubmit, errors, setFieldValue } = otpForm;

  const handleOnChange = (pin) => {
    setFieldValue('pins', pin);
  };

  const handleSubmitAuthCode = () => {
    onNextStep();
  };

  return (
    <Modal
      opened={visible}
      onClose={onClose}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      padding={0}
      size='lg'
    >
      <Card>
        <Card.Section>
          <Image src={signupOTPBanner} height={200} alt='Signup OTP banner' />
          <div className='img-yellow-ribbon' />
        </Card.Section>
        <form onSubmit={onSubmit(handleSubmitAuthCode)}>
          <Flex direction='column' align='center' justify='center' my={16}>
            <Title order={2} color='black' mb={10}>
              Code Sent!
            </Title>
            <Text size='sm' color='dimmed' ta='center'>
              Dual authentication is enabled in your account.
            </Text>
            <Text size='sm' color='dimmed' ta='center'>
              Please enter your authentication code to login.
            </Text>
            <AuthCode
              length={6}
              allowedCharacters='alphanumeric'
              onChange={handleOnChange}
              containerClassName='codeInputWrapper'
              inputClassName='codeInput'
            />
            {errors.pins && (
              <p className='codeInputErr'>
                Please provide all authentication code
              </p>
            )}
            <Button className='defaultBtn' w={100} mt={32} type='submit'>
              Validate
            </Button>
            <Button
              variant='subtle'
              color='ubsHighlight.9'
              size='xs'
              mt={16}
              component={Link}
              to={RoutePaths.Login}
            >
              Login With Different Account
            </Button>
          </Flex>
        </form>
      </Card>
    </Modal>
  );
};

export default SignUpOTP;
