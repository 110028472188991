export const RoutePaths = {
  Root: '/',
  RootHash: '/#home',
  Login: '/login',
  Register: '/registration',
  FAQHash: '/#faq',
  Contact: '/contact',
  ContactHash: '/#contact',
  Dashboard: '/dashboard',
  Payment: '/payment',
  BankAccountMain: '/bank-accounts',
  BankAccountAdd: '/bank-accounts/add-bank-account',
  BankAccountTrans: '/bank-accounts/bank-transfer',
  SendMoneyIntl: '/send-money/international',
  SendMoneyDomestic: '/send-money/domestic',
  Report: '/reports',
  EmployeeList: '/payroll/employee',
  EmployeeInfo: '/payroll/employee/:id',
  UploadPayroll: '/payroll/upload-payroll',
  Profile: '/profile',
  Disbursement: '/cash-disbursement',
  GenerateQR: '/generate-QR',
  Settings: '/settings',
  AddFund: '/add-funds',
  BuyForex: '/buy-forex',
  SellForex: '/sell-forex',
  SignUp: '/sign-up',
};
